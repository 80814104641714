import { Component, ViewChild, TemplateRef, OnInit } from "@angular/core";
import { isSameDay, isSameMonth } from "date-fns";
import { Observable, Subject, takeUntil } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
} from "angular-calendar";
import { EventColor } from "calendar-utils";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BaseComponent } from "src/app/base.component";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  loadingAdvertisingCampaignSelector,
  resultAdsByPublisherSelector,
  resultAllAdsByStatusAndPublisherSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeAllAdsByStatusAndPublisher,
  resultAllAdsByStatusAndPublisher,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import {
  CampaignStatusEnum,
  CampaignStatusEnumMapping,
} from "src/app/shared/enum/campaign-status";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { SelectedLps } from "src/app/shared/models/location-partners/selectedLps";
import { saveSelectedLPsSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { selectedAdvertiserSelector } from "src/app/store/advertiser/advertiser.selectors";
import { selectedAdvertiser } from "src/app/store/advertiser/advertiser.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import {
  calendarFilter,
  FilterInterface,
} from "src/app/shared/data/filter-data";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { FilterCalendar } from "src/app/shared/models/advertising-campaigns/filter-calendar.interface";
import { selectedLPs } from "src/app/store/locationPartner/location-partner.action";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import {
  catchForbiddenError,
  catchServerError,
} from "src/app/store/user/user.actions";
import { catchForbiddenErrorSelector, catchServerErrorSelector } from "src/app/store/user/user.selectors";

const colors: Record<string, EventColor> = {
  FINISHED: {
    primary: "#7dc006",
    secondary: "#7dc006",
  },
  ONGOING: {
    primary: "#1d97ff",
    secondary: "#1d97ff",
  },
  WAITING: {
    primary: "#ff8819",
    secondary: "#ff8819",
  },
  DRAFT: {
    primary: "#3E5463",
    secondary: "#3E5463",
  },
  AWAITING_PAYMENT: {
    primary: "#ff69b4",
    secondary: "#ff69b4",
  },
  AWAITING_APPROVAL: {
    primary: "#0E4FA6",
    secondary: "#0E4FA6",
  },
};

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
})
export class CalendarComponent extends BaseComponent implements OnInit {
  @ViewChild("modalContent", { static: true })
  modalContent!: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  modalData!: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: "Edit",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent("Edited", event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: "Delete",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent("Deleted", event);
      },
    },
  ];
  refresh = new Subject<void>();
  events: any[] = [];

  activeDayIsOpen: boolean = true;
  color = colors;

  /**  */
  formFilter: FormGroup;
  allAdsByAdsStatusAndPublisherRole: AdvertisingCampaignInterface[] = [];
  resultAllAdsByStatusAndPublisherRole$: Observable<
    AdvertisingCampaignInterface[] | null
  >;
  status: string[];
  checked: boolean = true;
  formSelectedStatus: FormGroup;
  details: AdvertisingCampaignInterface;
  selectedLps: SelectedLps = {
    companyNames: [],
    isCalculator: false,
    count: 0,
  };
  showSelectedLPs$: Observable<SelectedLps | null>;
  showSelectedAdvertisers$: Observable<string[] | null>;
  selectedAdvertisers: string[] = [];
  adByPublisher$: Observable<AdvertisingCampaignInterface[] | null>;
  adByPublisher: AdvertisingCampaignInterface[][] | null;

  filterCalendar: FilterCalendar = {
    ...initialState.filterCalendar,
  };
  options: CampaignStatusEnum[] = [];

  filter: FilterInterface = calendarFilter;
  loading$: Observable<boolean | null>;
  isFiltred: boolean = false;

  catchServerError$: Observable<boolean | null>;
  forbidden: boolean | null = false;
  loading: boolean | null;
  catchForbiddenError$: Observable<boolean | null>;
  constructor(
    private modal: NgbModal,
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.catchServerError$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultAllAdsByStatusAndPublisherRole$ = this.store
      .pipe(select(resultAllAdsByStatusAndPublisherSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.showSelectedLPs$ = this.store
      .pipe(select(saveSelectedLPsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.showSelectedAdvertisers$ = this.store
      .pipe(select(selectedAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adByPublisher$ = this.store
      .pipe(select(resultAdsByPublisherSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.formFilter = this.fb.group({
      date: [[null, null]],
      adTitle: [""],
    });
  }
  ngOnInit(): void {
    this.catchServerError$.subscribe((result) => {
      if (result) {
        this.forbidden = true;
        this.loading = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.forbidden = true;
        this.loading = false;
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.viewDate = new Date();
    this.loading$.subscribe((data) => {
      this.loading = data;
    });
    this.filter.name.map(
      (e) =>
        (e.data = Object.keys(CampaignStatusEnum)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              CampaignStatusEnumMapping[key as keyof typeof CampaignStatusEnum]
          ))
    );
    this.store.dispatch(selectedLPs({ selectedLps: null }));
    this.store.dispatch(
      resultAllAdsByStatusAndPublisher({ advertisingCampaignDto: null })
    );
    this.showSelectedLPs$.subscribe((result: SelectedLps | null) => {
      if (result) {
        this.selectedLps.companyNames = result.companyNames;
        this.saveOption(this.options);
        if (this.selectedLps.companyNames.length == 0) {
          this.viewDate = new Date();
        }
      }
    });
    this.showSelectedAdvertisers$.subscribe((result: string[] | null) => {
      if (result) {
        this.selectedAdvertisers = result;
        this.saveOption(this.options);
      }
    });
    this.formFilter.valueChanges.subscribe((changes) => {
      if (changes.adTitle != "" || changes.date[0] != null) {
        this.isFiltred = true;
      } else {
        this.isFiltred = false;
      }
    });
    this.resultAllAdsByStatusAndPublisherRole$.subscribe(
      (result: AdvertisingCampaignInterface[] | null) => {
        if (result) {
          this.saveHistory.saveUserHistory(
            UserHistoryActionsEnum.GET,
            UserHistoryServicesEnum.CALENDAR
          );
          this.allAdsByAdsStatusAndPublisherRole = result;
          for (let i = 0; i < result.length; i++) {
            this.events.push({
              start: new Date(result[i].startingDate),
              end: new Date(result[i].endingDate),
              title: result[i].title,
              color: { ...colors[result[i].status] },
              advertisingCampaign: result[i],
              allDay: true,
              resizable: {
                beforeStart: true,
                afterEnd: true,
              },
              draggable: true,
            });
            this.refresh.next();
            if (this.isFiltred) {
              this.viewDate = new Date(result[i].startingDate);
            } else {
              this.viewDate = new Date();
            }
          }
        } else {
          this.store.dispatch(
            invokeAllAdsByStatusAndPublisher({
              filterCalendar: { ...this.filterCalendar, status: [] },
            })
          );
        }
      }
    );
  }
  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  handleEvent(action: string, event: any): void {
    this.modalData = { event, action };
    this.details = event.advertisingCampaign;
    this.modal.open(this.modalContent, { centered: true, size: "lg" });
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.events = [];
    this.store.dispatch(
      invokeAllAdsByStatusAndPublisher({
        filterCalendar: {
          ...this.filterCalendar,
          title: this.formFilter.get("adTitle")?.value,
          locationsPartners: this.selectedLps.companyNames,
          status: this.options,
          roleName: RoleEnum.ROLE_ADVERTISER,
          email: this.selectedAdvertisers,
          startingDate: this.formFilter.get("date")?.value[0],
          endingDate: this.formFilter.get("date")?.value[1],
        },
      })
    );
  }
  selectDate() {
    this.store.dispatch(invokeApiDistroyed());
    this.events = [];
    this.store.dispatch(
      invokeAllAdsByStatusAndPublisher({
        filterCalendar: {
          ...this.filterCalendar,
          status: this.options,
          title: this.formFilter.get("adTitle")?.value,
          locationsPartners: this.selectedLps.companyNames,
          email: this.selectedAdvertisers,
          roleName: RoleEnum.ROLE_ADVERTISER,
          startingDate: this.formFilter.get("date")?.value[0],
          endingDate: this.formFilter.get("date")?.value[1],
        },
      })
    );
    this.viewDate = new Date(this.formFilter.get("date")?.value[0]);
  }
  saveOption(option: CampaignStatusEnum[]) {
    this.events = [];
    let listFounds: CampaignStatusEnum[] = [];
    option?.map((option) => {
      listFounds.push(
        Object.keys(CampaignStatusEnumMapping).find(
          (key) =>
            CampaignStatusEnumMapping[key as CampaignStatusEnum] === option
        ) as CampaignStatusEnum
      );
    });
    if (listFounds.length > 0) {
      this.store.dispatch(invokeApiDistroyed());
      this.isFiltred = false;
    } else {
      this.isFiltred = true;
    }
    this.options = listFounds;
    this.store.dispatch(
      invokeAllAdsByStatusAndPublisher({
        filterCalendar: {
          ...this.filterCalendar,
          status: listFounds,
          title: this.formFilter.get("adTitle")?.value,
          locationsPartners: this.selectedLps.companyNames,
          email: this.selectedAdvertisers,
          roleName: RoleEnum.ROLE_ADVERTISER,
          startingDate: this.formFilter.get("date")?.value[0],
          endingDate: this.formFilter.get("date")?.value[1],
        },
      })
    );
  }
  removeFilter() {
    this.events = [];
    this.formFilter.reset({
      adTitle: "",
      date: [null, null],
    });
    this.store.dispatch(
      invokeAllAdsByStatusAndPublisher({
        filterCalendar: { ...this.filterCalendar, status: [] },
      })
    );
    this.viewDate = new Date();
    this.store.dispatch(selectedAdvertiser({ selectedAdvertiser: null }));
    this.store.dispatch(selectedLPs({ selectedLps: null }));
  }
}
