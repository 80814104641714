<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="icon relead"
              />
            </div>
          </div>
          <form class="theme-form login-form">
            <div class="d-flex justify-content-end px-2">
              <ul class="nav-menus">
                <li class="onhover-dropdown">
                  <app-language [isAuth] = "true"></app-language>
                </li>
              </ul>
            </div>
            <h4 class="greeting-auth">{{ "register.title" | translate }}</h4>
            <h6 style="text-align: center">
              {{ "register.greeting" | translate }}
            </h6>

            <div class="card-body">
              <ng-container class="flex" *ngIf="result == enumeration.LOADING">
                <div class="loader-box">
                  <div class="loader-34"></div>
                </div>
                <h6
                  class="col align-self-center f-w-600"
                  style="text-align: center"
                >
                  {{ "fetching" | translate }}
                </h6>
              </ng-container>
              <ng-container *ngIf="result == enumeration.VALIDATED">
                <div class="d-flex justify-content-center pb-4">
                  <img
                    src="assets/identity/get-started.png"
                    alt=""
                    class="img-130"
                  />
                </div>

                <h5 style="text-align: center">
                  {{ "register.confirmEmail.success" | translate }}
                </h5>
              </ng-container>
              <ng-container *ngIf="result == enumeration.NOT_VALIDATED">
                <div class="d-flex justify-content-center pb-4">
                  <img src="assets/identity/oops.png" alt="" class="img-100" />
                </div>

                <h5 style="text-align: center">
                  {{ "register.confirmEmail.invalidKey" | translate }}
                </h5>
                <div class="d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="regenerateToken()"
                  >
                    {{ "register.confirmEmail.regenerate" | translate }}
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="result == enumeration.NOT_FOUND">
                <div class="d-flex justify-content-center pb-4">
                  <img src="assets/identity/oops.png" alt="" class="img-100" />
                </div>

                <h5 style="text-align: center">
                  {{ "register.confirmEmail.inexistanceKey" | translate }}
                </h5>
              </ng-container>
              <ng-container *ngIf="result == enumeration.REGENERATED">
                <div class="d-flex justify-content-center pb-4">
                  <img
                    src="assets/identity/contact-us.png"
                    alt=""
                    class="img-110"
                  />
                </div>

                <h5 style="text-align: center">
                  {{ "register.confirmEmail.consultEmail" | translate }}
                </h5>
              </ng-container>
              <ng-container *ngIf="result == enumeration.CANT_REGENERATE">
                <div class="d-flex justify-content-center pb-4">
                  <img src="assets/identity/oops.png" alt="" class="img-100" />
                </div>

                <h5 style="text-align: center">
                  {{ "register.confirmEmail.cantRegenerate" | translate }}
                </h5>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
