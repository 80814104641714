<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="icon relead"
              />
            </div>
          </div>

          <!--Forgot password-->
          <div class="theme-form login-form needs-validation">
            <form
              *ngIf="step == 1"
              novalidate
              [ngClass]="{ 'was-validated': validate }"
              [formGroup]="formForgotPasswordStep1"
              (ngSubmit)="forgotPasswordStep1($event)"
            >
              <div class="d-flex justify-content-end px-2">
                <ul class="nav-menus">
                  <li class="onhover-dropdown">
                    <app-language [isAuth] = "true"></app-language>
                  </li>
                </ul>
              </div>
              <h4 class="greeting-auth">
                {{ "forgotPassword.title" | translate }}
              </h4>

              <div class="form-group mt-4">
                <label *ngIf="!mailSended" style="color: black">
                  {{ "forgotPassword.email" | translate }} *</label
                >
                <div class="input-group">
                  <span class="input-group-text">
                    <img src="assets/identity/email.png" alt="icone email" />
                  </span>
                  <input
                    formControlName="email"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        !formForgotPasswordStep1.get('email')?.valid &&
                        formForgotPasswordStep1.get('email')?.touched
                    }"
                    type="email"
                    [readOnly]="mailSended"
                    required=""
                    [placeholder]="
                      'authentication.placeholder.email' | translate
                    "
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      !formForgotPasswordStep1.get('email')?.valid &&
                      formForgotPasswordStep1.get('email')?.touched
                    "
                  >
                    {{ "authentication.errorMessage.emailInvalid" | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button
                  *ngIf="!mailSended"
                  [disabled]="sendEmailLoader$ | async"
                  class="btn btn-primary btn-block"
                  type="submit"
                >
                  <loading-button
                    [loading]="sendEmailLoader$ | async"
                    [value]="'buttons.send' | translate"
                    [icon]="null"
                  ></loading-button>
                </button>
              </div>
            </form>

            <!--Update password-->
            <form
              novalidate
              [ngClass]="{ 'was-validated': validate }"
              [formGroup]="formResetPasswordStep2"
              (ngSubmit)="resetPasswordStep2($event)"
            >
              <div *ngIf="step == 1 && mailSended">
                <p style="text-align: left; margin-bottom: 20px">
                  {{ "forgotPassword.noEmailReceived" | translate }}
                  <a
                    class="color-primary underline-link"
                    (click)="forgotPasswordStep1($event)"
                    >{{ "forgotPassword.resend" | translate }}</a
                  >
                </p>
                <div class="form-group m-t-4">
                  <label>{{ "forgotPassword.enterCode" | translate }} *</label>
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="fa fa-key"></i
                    ></span>
                    <input
                      formControlName="token"
                      class="form-control"
                      type="text"
                      name="token"
                      required=""
                      [placeholder]="
                        'forgotPassword.placeholder.codeInput' | translate
                      "
                      [ngClass]="{
                        'is-invalid':
                          !formResetPasswordStep2.get('token')?.valid &&
                          formResetPasswordStep2.get('token')?.touched
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        !formResetPasswordStep2.get('token')?.valid &&
                        formResetPasswordStep2.get('token')?.touched
                      "
                    >
                      {{
                        "forgotPassword.errorMessage.codeRequired" | translate
                      }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    (click)="nextStep()"
                    [disabled]="
                      (sendEmailLoader$ | async) ||
                      formResetPasswordStep2.get('token')?.invalid
                    "
                    class="btn btn-primary btn-block"
                    type="button"
                  >
                    <loading-button
                      [loading]="
                        (sendEmailLoader$ | async) &&
                        formResetPasswordStep2.get('token')?.valid
                      "
                      [value]="'buttons.next' | translate"
                      [icon]="null"
                    ></loading-button>
                  </button>
                </div>
              </div>
              <div *ngIf="step == 2">
                <div class="d-flex justify-content-end px-2">
                  <ul class="nav-menus">
                    <li class="onhover-dropdown">
                      <app-language [isAuth] = "true"></app-language>
                    </li>
                  </ul>
                </div>
                <h4 class="greeting-auth">
                  {{ "forgotPassword.title" | translate }}
                </h4>
                <div class="form-group">
                  <label
                    >{{
                      "profile.updatePassword.newPassword" | translate
                    }}
                    *</label
                  >
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="icon-lock"></i
                    ></span>
                    <input
                      formControlName="password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && password?.errors
                      }"
                      type="password"
                      name="login[password]"
                      [type]="showPassword ? 'text' : 'password'"
                      required=""
                      placeholder="*********"
                      [ngClass]="{
                        'is-invalid':
                          !formResetPasswordStep2.get('password')?.valid &&
                          formResetPasswordStep2.get('password')?.touched
                      }"
                    />
                    <div
                      class="show-hide"
                      (click)="clickShowPassword()"
                      *ngIf="!showPassword"
                    >
                      <span> {{ "password.show" | translate }} </span>
                    </div>
                    <div
                      class="show-hide"
                      (click)="clickShowPassword()"
                      *ngIf="showPassword"
                    >
                      <span>{{ "password.cash" | translate }}</span>
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        !formResetPasswordStep2.get('password')?.valid &&
                        formResetPasswordStep2.get('password')?.touched
                      "
                    >
                      {{
                        "authentication.errorMessage.passwordInvalid"
                          | translate
                      }}
                      <ul style="padding-left: 10px">
                        <li>
                          - {{ "password.pattern.validator1" | translate }}
                        </li>
                        <li>
                          - {{ "password.pattern.validator2" | translate }}
                        </li>
                        <li>
                          - {{ "password.pattern.validator3" | translate }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>
                    {{
                      "profile.updatePassword.confirmNewPassword" | translate
                    }}
                    *</label
                  >
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="icon-lock"></i
                    ></span>
                    <input
                      formControlName="confirmPassword"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && confirmPassword?.errors
                      }"
                      [type]="showConfirmPassword ? 'text' : 'password'"
                      required=""
                      placeholder="*********"
                      [ngClass]="{
                        'is-invalid':
                          !formResetPasswordStep2.get('confirmPassword')
                            ?.valid &&
                          formResetPasswordStep2.get('confirmPassword')?.touched
                      }"
                    />
                    <div
                      class="show-hide"
                      (click)="clickShowConfirmPassword()"
                      *ngIf="!showConfirmPassword"
                    >
                      <span> {{ "password.show" | translate }} </span>
                    </div>
                    <div
                      class="show-hide"
                      (click)="clickShowConfirmPassword()"
                      *ngIf="showConfirmPassword"
                    >
                      <span>{{ "password.cash" | translate }}</span>
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        !formResetPasswordStep2.get('confirmPassword')?.valid &&
                        formResetPasswordStep2.get('confirmPassword')?.touched
                      "
                    >
                      {{
                        "authentication.errorMessage.passwordInvalid"
                          | translate
                      }}
                      <ul style="padding-left: 10px">
                        <li>
                          - {{ "password.pattern.validator1" | translate }}
                        </li>
                        <li>
                          - {{ "password.pattern.validator2" | translate }}
                        </li>
                        <li>
                          - {{ "password.pattern.validator3" | translate }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    [disabled]="
                      (sendEmailLoader$ | async) ||
                      !formResetPasswordStep2.valid ||
                      submitted
                    "
                    class="btn btn-primary btn-block"
                    type="submit"
                  >
                    <loading-button
                      [loading]="submitted"
                      [value]="'buttons.send' | translate"
                      [icon]="null"
                    ></loading-button>
                  </button>
                </div>
              </div>
              <hr />
              <p>
                {{ "forgotPassword.backToLogin" | translate }}
                <a class="ms-2" href="" routerLink="../login">
                  {{ "login.signInButton" | translate }}</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
