<div class="card">
  <div class="card-header pb-0">
    <div class="media-space-between">
      <h4>{{ "admin.locations.repository.title" | translate }}</h4>
      <div class="media-body">
        <form class="media-space-between" [formGroup]="formFilter">
          <div class="faq-form m-r-10" style="color: darkgrey; display: flex">
            <i class="search-icon" data-feather="search"></i>
            <div>
              <div class="input-group">
                <input
                  style="width: 14rem"
                  id="table-complete-search"
                  type="text"
                  class="form-control"
                  [placeholder]="'placeholder.searchByCompanyName' | translate"
                  formControlName="companyName"
                  (ngModelChange)="searchTerm()"
                />
                <span
                  class="input-group-text p-0"
                  style="background-color: transparent"
                >
                  <app-general-filter
                    [filter]="filter"
                    (optionSelected)="saveOptionFilter($event)"
                  ></app-general-filter>
                </span>
              </div>
            </div>
          </div>
          <div class="m-r-10">
            <button
              class="btn btn-primary"
              type="button"
              style="width: max-content"
              data-bs-toggle="modal"
              data-original-title="test"
              (click)="onClickShowModal()"
              [disabled]="!isAuthorised"
            >
              {{ "buttons.addLocations" | translate }}
            </button>
          </div>
          <div>
            <button
              class="btn btn-outline-primary"
              type="button"
              style="width: max-content"
              data-bs-target="#exportFile"
              (click)="exportCSV(exportFile)"
              [disabled]="forbidden"
            >
              {{ "export.title" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive data-table">
      <form class="table_wrapper custom-scrollbar" style="overflow-x: scroll">
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <th>LP</th>
              <th *ngIf="columnVisibility[0]" colspan="5">
                {{ "admin.locations.repository.demographicData" | translate }}
                <a (click)="toggleColumn(0)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th *ngIf="!columnVisibility[0]" colspan="1">
                {{ "admin.locations.repository.demographicData" | translate }}
                <a (click)="toggleColumn(0)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th *ngIf="columnVisibility[1]" colspan="2">
                {{ "admin.locations.repository.informations" | translate }}
                <a (click)="toggleColumn(1)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th *ngIf="!columnVisibility[1]" colspan="1">
                {{ "admin.locations.repository.informations" | translate }}
                <a (click)="toggleColumn(1)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th colspan="4" *ngIf="columnVisibility[2]">
                {{ "admin.locations.repository.coordinates" | translate }}
                <a (click)="toggleColumn(2)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th *ngIf="!columnVisibility[2]" colspan="1">
                {{ "admin.locations.repository.coordinates" | translate }}
                <a (click)="toggleColumn(2)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th colspan="3" *ngIf="columnVisibility[3]">
                {{ "admin.locations.repository.discount" | translate }}
                <a (click)="toggleColumn(3)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th *ngIf="!columnVisibility[3]" colspan="1">
                {{ "admin.locations.repository.discount" | translate }}
                <a (click)="toggleColumn(3)" type="button">
                  <img src="assets/identity/collabs.svg" alt="collabs"
                /></a>
              </th>
              <th>Actions</th>
            </tr>
            <tr>
              <th></th>
              <ng-container *ngFor="let demographic of demographic">
                <th *ngIf="columnVisibility[0]">
                  {{ demographic.label | translate }}
                </th>
              </ng-container>
              <th *ngIf="!columnVisibility[0]"></th>
              <ng-container *ngFor="let demographic of info">
                <th *ngIf="columnVisibility[1]">
                  {{ demographic.label | translate }}
                </th>
              </ng-container>

              <th *ngIf="!columnVisibility[1]"></th>
              <ng-container *ngFor="let demographic of coordinates">
                <th *ngIf="columnVisibility[2]">
                  {{ demographic.label | translate }}
                </th>
              </ng-container>

              <th *ngIf="!columnVisibility[2]"></th>
              <ng-container *ngFor="let demographic of discount">
                <th *ngIf="columnVisibility[3]">
                  {{ demographic.label | translate }}
                </th>
              </ng-container>

              <th *ngIf="!columnVisibility[3]"></th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="!loading && !forbidden">
            <tr
              *ngFor="
                let item of locationPartnersResult?.locationPartners;
                let i = index
              "
            >
              <td>
                <ng-template #none>
                  {{ "undefined" | translate }}
                </ng-template>
                <a
                  type="button"
                  [routerLink]="['statistics/']"
                  style="color: black"
                  [queryParams]="{
                    companyName: item.companyName
                  }"
                >
                  {{ item.companyName }}
                </a>
              </td>
              <td *ngIf="columnVisibility[0]">
                <p *ngIf="item.region != null; else none">{{ item.region }}</p>
              </td>
              <td *ngIf="columnVisibility[0]">
                <ngb-highlight
                  [result]="item.zoneName"
                  *ngIf="item.zoneName != null; else none"
                ></ngb-highlight>
              </td>
              <td *ngIf="columnVisibility[0]">
                <ngb-highlight
                  [result]="item.category"
                  *ngIf="item.category != null; else none"
                ></ngb-highlight>
              </td>
              <td *ngIf="columnVisibility[0]">
                <div *ngIf="item.subCategories.length != 0; else none">
                  <div *ngFor="let subCateory of item.subCategories">
                    {{ subCateory }}
                  </div>
                </div>
              </td>
              <td *ngIf="columnVisibility[0]">
                <div *ngIf="item.tags.length != 0; else none">
                  <div *ngFor="let tag of item.tags">
                    {{ tag }}
                  </div>
                </div>
              </td>
              <td *ngIf="!columnVisibility[0]"></td>
              <td *ngIf="columnVisibility[1]">
                <span
                  [ngClass]="
                    'badge ' +
                    badgeService.getBadgeByLocationPartnerStatus(
                      item.partnerStatus
                    )
                  "
                >
                  {{
                    "enumMapping.partnerStatus." + item.partnerStatus
                      | translate
                  }}
                </span>
              </td>
              <td *ngIf="columnVisibility[1]">
                <p *ngIf="item.pilotPhaseEndDate != null; else none">
                  {{ item.pilotPhaseEndDate | localizedDate }}
                </p>
              </td>
              <td *ngIf="!columnVisibility[1]"></td>
              <td *ngIf="columnVisibility[2]">
                <p
                  *ngIf="
                    item.firstName != null || item.lastName != null;
                    else none
                  "
                >
                  {{ item.firstName }} {{ item.lastName }}
                </p>
              </td>
              <td *ngIf="columnVisibility[2]">
                <ngb-highlight
                  [result]="item.email"
                  *ngIf="item.email != null; else none"
                ></ngb-highlight>
              </td>
              <td *ngIf="columnVisibility[2]">
                <p *ngIf="item.phone != null; else none">
                  {{ item.phone }}
                </p>
              </td>
              <td *ngIf="columnVisibility[2]">
                <ng-container
                  *ngFor="let chunk of item.companyAddress | newline : 20"
                >
                  {{ chunk }}<br />
                </ng-container>
              </td>
              <td *ngIf="!columnVisibility[2]"></td>
              <td *ngIf="columnVisibility[3]">
                <div *ngIf="item.pricingMethod != null; else none">
                  <span
                    *ngIf="item.pricingMethod != pricingMethode.NONE; else none"
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeByLocationPartnerPricingMethod(
                        item.pricingMethod
                      )
                    "
                  >
                    {{
                      "enumMapping.paymentMethode." + item.pricingMethod
                        | translate
                    }}
                  </span>
                </div>
                <ng-template #none>
                  <span
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeByLocationPartnerPricingMethod(
                        pricingMethode.NONE
                      )
                    "
                  >
                    {{ "UNDEFINED" | translate }}
                  </span>
                </ng-template>
              </td>
              <td *ngIf="columnVisibility[3]">
                <p *ngIf="item.price != null; else none">
                  {{ item.price }}
                </p>
              </td>
              <td *ngIf="columnVisibility[3]">
                <p *ngIf="item.paymentFrequency; else paymentNone">
                  <span
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeByLocationPartnerPaymentFrequency(
                        item.paymentFrequency
                      )
                    "
                  >
                    {{
                      "enumMapping.paymentFrequency." + item.paymentFrequency
                        | translate
                    }}
                  </span>
                </p>
                <ng-template #paymentNone>
                  <span
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeByLocationPartnerPaymentFrequency(
                        paymentFrequencyToShow.NONE
                      )
                    "
                  >
                    {{ "undefined" | translate }}
                  </span>
                </ng-template>
              </td>
              <td *ngIf="!columnVisibility[3]"></td>
              <td>
                <app-drob-zone-options
                  [options]="options"
                  (optionSelected)="saveOption($event, item)"
                ></app-drob-zone-options>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="forbidden" class="d-flex justify-content-center m-t-20">
          {{ "response.error.FORBIDDEN_RESPONSE" | translate }}
        </div>  
        <div
          *ngIf="locationPartnersResult.count == 0 && !loading && !forbidden"
          class="d-flex justify-content-center m-t-20"
        >
          {{ "noData" | translate }}
        </div>
        <div *ngIf="loading">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
        <div class="d-flex">
          <div class="col-3"></div>
          <div class="col-6">
            <div class="d-flex justify-content-center p-3">
              <ngb-pagination
                [collectionSize]="locationPartnersResult.count!"
                [(page)]="locationPartnerFilter.pager.pageNumber"
                [pageSize]="locationPartnerFilter.pager.pageSize"
                [rotate]="true"
                [boundaryLinks]="true"
                (pageChange)="changePage($event)"
              >
              </ngb-pagination>
            </div>
          </div>
          <div class="col-3">
            <div class="d-flex justify-content-end p-3">
              <select
                class="form-select"
                style="width: auto"
                name="pageSize"
                [(ngModel)]="changePageSize"
              >
                <option [ngValue]="5">
                  5 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="10">
                  10 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="15">
                  15 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="locationPartnersResult.count">
                  {{ "pagination.allItems" | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #exportFile let-modal id="exportFile">
  <div class="modal-header">
    <h4 class="modal-title" id="exportFile">
      {{ "exportLP.title" | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      {{ "exportLP.body" | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      data-bs-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "buttons.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="exportToCsv()">
      <span
        *ngIf="deletedIsLoading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      {{ "buttons.confirm" | translate }}
    </button>
  </div>
</ng-template>
