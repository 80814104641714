<!-- [style.left.px]="popoverPosition.x"
    [style.top.px]="popoverPosition.y" -->
<div class="row" style="min-height: 500px">
  <div class="col-6">
    <div class="p-l-50 p-r-50'">
      <div class="card">
        <div class="card-body">
          <h5 class="fw-bold">{{ "maps.description1" | translate }}</h5>
          <div class="row">
            <div class="m-b-5">
              <div *ngIf="!showPopover; else showDetails">
                <div
                  class="d-flex justify-content-center m-t-30"
                  style="text-align: center"
                >
                  <h6 class="f-w-500">{{ "maps.description2" | translate }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showPopover">
        <div class="col-12">
          <div id="chart-widget4">
            <apx-chart
              #chartApex
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [labels]="chartOptions.labels"
              [legend]="chartOptions.legend"
              [yaxis]="chartOptions.yaxis"
              [xaxis]="chartOptions.xaxis"
              [dataLabels]="chartOptions.dataLabels"
              [stroke]="chartOptions.stroke"
              [title]="chartOptions.title"
              *ngIf="!isLoadingChart && length != 0"
            >
            </apx-chart>
            <div class="text-center" *ngIf="isLoadingChart">
              <div class="loader-box">
                <div class="loader-34"></div>
              </div>
              <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="map-container">
      <div
        #mapElement
        id="map"
        style="height: 500px; width: 100%; border-radius: 20px"
      ></div>
    </div>
  </div>
</div>

<ng-template #showDetails style="text-align: center; max-height: 400px">
  <div class="d-flex align-items-center m-t-10 m-b-10">
    <img src="assets/identity/marker.svg" alt="marker" />
    <h6 class="f-w-700 mb-0 m-l-5">{{ selectedLocation.companyName }}</h6>
  </div>
  <div class="row">
    <div class="m-b-10">
      <span class="f-w-700 small">{{ "maps.description3" | translate }}: </span>
      <span
        *ngFor="let subCategorie of selectedLocation.subCategories"
        class="badge badge-light text-dark m-b-5 f-w-500 m-l-0"
        style="margin-right: 5px"
        >{{ subCategorie }}</span
      >
    </div>
    <div class="col-12">
      <span class="f-w-700 small">{{ "maps.description4" | translate }} : </span
      ><span
        *ngFor="let tag of selectedLocation.tags"
        class="badge badge-light text-dark m-b-5 f-w-500 m-l-0"
        style="margin-right: 5px"
        >{{ tag }}</span
      >
    </div>
  </div>
</ng-template>
