<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="icon relead"
              />
            </div>
          </div>
          <form
            class="theme-form login-form"
            novalidate
            [formGroup]="registrationForm"
            (ngSubmit)="register()"
          >
            <div class="d-flex justify-content-end px-2">
              <ul class="nav-menus">
                <li class="onhover-dropdown">
                  <app-language [isAuth] = "true" [reloadScreen]="true"></app-language>
                </li>
              </ul>
            </div>
            <h4 class="greeting-auth">{{ "register.title" | translate }}</h4>
            <h6 style="text-align: center">
              {{ "register.greeting" | translate }}
            </h6>

            <div class="card-body">
              <aw-wizard #wizard [disableNavigationBar]="true">
                <aw-wizard-step>
                  <h5 class="mt-4">
                    {{ "register.advertiserType.title" | translate }}
                  </h5>
                  <div class="btn-showcase">
                    <button class="btn btn-annonceur" type="button">
                      {{ "register.advertiserType.advertiser" | translate }}
                    </button>
                    <button class="btn btn-agence" type="button" disabled>
                      {{
                        "register.advertiserType.Communication-agency"
                          | translate
                      }}
                    </button>
                  </div>
                  <button
                    class="btn btn-primary pull-right"
                    type="submit"
                    awNextStep
                  >
                    {{ "buttons.next" | translate }}
                  </button>
                </aw-wizard-step>
                <aw-wizard-step formGroupName="personalInformation">
                  <app-personal-information
                    [form]="
                      registrationForm.get('personalInformation')! | asFormGroup
                    "
                    [personalInformationSubmitted]="
                      personalInformationSubmitted
                    "
                  ></app-personal-information>
                  <button
                    class="btn btn-primary pull-right"
                    type="button"
                    [disabled]="
                      !registrationForm.get('personalInformation')?.valid
                    "
                    (click)="
                      personalInformationSubmitted.emit(true); nextStep()
                    "
                  >
                    {{ "buttons.next" | translate }}
                  </button>
                  <button
                    class="btn btn-outline-primary pull-left me-1"
                    type="button"
                    awPreviousStep
                  >
                    {{ "buttons.return" | translate }}
                  </button>
                </aw-wizard-step>
                <aw-wizard-step formGroupName="businessInformation">
                  <div class="tab">
                    <app-business-information
                      [CompanyActivity]="companyActivity"
                      [businessInformationSubmitted]="
                        businessInformationSubmitted
                      "
                      [form]="
                        registrationForm.get('businessInformation')!
                          | asFormGroup
                      "
                    ></app-business-information>
                  </div>
                  <div class="form-group d-flex justify-content-center">
                    <div id="recaptcha-container"></div>
                  </div>
                  <button
                    class="btn btn-primary pull-right"
                    type="submit"
                    (click)="sendConfirmEmail()"
                    [disabled]="
                      !registrationForm.get('businessInformation')?.valid ||
                      !isValidateCaptcha
                    "
                    awNextStep
                  >
                    {{ "buttons.confirm" | translate }}
                  </button>
                  <button
                    class="btn btn-outline-primary pull-left me-1"
                    type="button"
                    awPreviousStep
                  >
                    {{ "buttons.return" | translate }}
                  </button>
                </aw-wizard-step>
                <aw-wizard-completion-step awEnableBackLinks>
                  <div class="tab">
                    <div class="form-group">
                      <div class="d-flex justify-content-center pb-4">
                        <img
                          src="assets/identity/contact-us.png"
                          alt=""
                          class="img-110"
                        />
                      </div>

                      <h5 style="text-align: center">
                        {{ "register.checkEmail" | translate }}
                      </h5>
                      <h6
                        class="color-primary"
                        style="text-align: center; margin-bottom: 20px"
                      ></h6>
                    </div>
                  </div>
                </aw-wizard-completion-step>
              </aw-wizard>
            </div>
            <hr />
            <p>
              {{ "authentication.alreadyRegistered" | translate
              }}<a class="ms-2 color-primary" href="" routerLink="../login">{{
                "authentication.signIn" | translate
              }}</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
