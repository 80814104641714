import { createAction, props } from "@ngrx/store";
import { DashboardSettingsInterface } from "src/app/shared/models/dashboard-settings.interface";
import { Pager } from "src/app/shared/models/pagination";
import { SubRoleInterface } from "src/app/shared/models/sub-role/subRole.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ChangePassword } from "src/app/shared/models/user/change-password.interface";
import {
  TokenHistoryInterface,
  TokenHistoryResultInterface,
} from "src/app/shared/models/user/token.interface";
import { ApiStateInterface } from "../apiState.interface";

export const userSuccessMessage = createAction(
  "[ User Success Message ] User Success Message ",
  props<{ successMessage: SuccessDto | null }>()
);

export const invokeForgotPassword = createAction(
  "[ Invoke Forgot Password ] Invoke User Forgot password",
  props<{ email: string | null }>()
);
export const setForgotPasswordSuccess = createAction(
  "[ Set Forgot Password ] Set User Forgot password",
  props<{ successMessage: SuccessDto | null }>()
);
export const setForgotPasswordFailed = createAction(
  "[ Set Forgot Password ] Set User Forgot password",
  props<{ error: ApiStateInterface | null }>()
);
export const invokeCheckTokenForResetPassword = createAction(
  "[ Invoke check token Forgot Password ] Invoke check token User Forgot password",
  props<{ token: string | null; email: string | null }>()
);
export const checkTokenForResetPasswordSuccess = createAction(
  "[ Invoke check token Forgot Password Success ] Invoke check token User Forgot password Success",
  props<{ successMessage: SuccessDto | null }>()
);

export const invokeResetPassword = createAction(
  "[ Invoke Reset Password ] Invoke reset password ",
  props<{ token: string; password: string }>()
);

export const setResetPassword = createAction(
  "[ Reset Password ] Reset password",
  props<{ successMessage: SuccessDto | null }>()
);

export const invokeUserChangePassword = createAction(
  "[ Invoke User Change Password ] User Change Password",
  props<{ changePassword: ChangePassword }>()
);

export const invokeCheckIfExistByEmail = createAction(
  "[ Invoke User Exists By Email ] invoke ckeck user exists",
  props<{ email: string }>()
);
export const setCheckUserExistsByEmail = createAction(
  "[ Set Check User Exists ] Set Check User Exists",
  props<{ isExist: boolean | null }>()
);
export const invokeCheckIfExistByCompanyName = createAction(
  "[ Invoke User Exists By companyName] invoke ckeck user exists by companyName",
  props<{ companyName: string }>()
);
export const setCheckUserExistsByCompanyName = createAction(
  "[ Set Check User Exists By companyName ] Set Check User Exists By companyName",
  props<{ isExistByCompanyName: boolean | null }>()
);
export const invokeDashboardSettings = createAction(
  "[ Invoke Dashboard Settings ] invoke Dashboard Settings",
  props<{ dashboardSettings: DashboardSettingsInterface }>()
);

export const getDashboardSettings = createAction(
  "[ Get Dashboard Settings ] Get Dashboard Settings"
);

export const setDashboardSettings = createAction(
  "[ Set Dashboard Settings ] Set Dashboard Settings",
  props<{ dashboardSettings: DashboardSettingsInterface | null }>()
);

export const invokeUserHistoryTokens = createAction(
  "[ Invoke User Tokens ] invoke User Tokens",
  props<{ email: string; pager: Pager }>()
);

export const setUserHistoryTokens = createAction(
  "[ Set User Tokens ] Set User Tokens",
  props<{ result: TokenHistoryResultInterface }>()
);
export const invokeSubRoleByUser = createAction(
  "[ Invoke Sub Role By User ] Invoke sub role by user ",
  props<{ email: string }>()
);
export const resultSubRoleByUser = createAction(
  "[ Result Sub Role By User ] Result sub role by user",
  props<{ subRole: SubRoleInterface | null }>()
);
export const catchServerError = createAction(
  "[Invoke loading to false due to server error ] Invoke loading to false due to server error ",
  props<{serverError :  boolean | null}>()
)

export const catchForbiddenError = createAction(
  "[Invoke loading to false due to server error ] Invoke loading to false due to server error ",
  props<{forbiddenError :  boolean | null}>()
)
