<form class="custom-scrollbar form p-b-25">
  <table class="table table-striped table-border-vertical">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th
            sortable
            class="sortable"
            sortable="{{ column.key }}"
            (sort)="onSort($event)"
          >
            {{ column.label | translate }}
          </th>
        </ng-container>
        <ng-container *ngIf="releadRouters; else fsi">
          <ng-container *ngFor="let column of columnsRelead">
            <th
              class="h-w-{{ column.width }}"
              sortable="{{ column.key }}"
              (sort)="onSort($event)"
            >
              {{ column.label | translate }}
            </th>
          </ng-container>
        </ng-container>
        <ng-template #fsi>
          <ng-container *ngFor="let column of columnsFsi">
            <th
              sortable
              class="sortable"
              sortable="{{ column.key }}"
              (sort)="onSort($event)"
            >
              {{ column.label | translate }}
            </th>
          </ng-container>
        </ng-template>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody *ngIf="!(loading$ | async)">
      <tr *ngFor="let item of routersResult.routers">
        <ng-template #none>
          <td>
            {{ "undefined" | translate }}
          </td>
        </ng-template>
        <td *ngIf="item.mac; else none">
          <ngb-highlight [result]="item.mac"></ngb-highlight>
        </td>
        <td *ngIf="item.locationPartner; else none">
          <ngb-highlight [result]="item.locationPartner"></ngb-highlight>
        </td>
        <td *ngIf="item.productionDate; else none">
          {{ item.productionDate | localizedDate }}
        </td>
        <td *ngIf="item.dateOfPurchase; else none">
          {{ item.dateOfPurchase | localizedDate }}
        </td>
        <ng-container *ngIf="releadRouters; else FSI">
          <td *ngIf="item.ip; else none">
            <ngb-highlight
              highlightClass="middle"
              [result]="item.ip"
            ></ngb-highlight>
          </td>
          <td *ngIf="item.sessionTimeOut; else none">
            {{ sessionTimeOut(item.sessionTimeOut)}}
          </td>
          <td *ngIf="item.ssid; else none">
            {{ item.ssid }}
          </td>
          <td *ngIf="item.reference; else none">{{ item.reference }}</td>
        </ng-container>
        <ng-template #FSI>
          <td *ngIf="item.acquisitionPrice; else none">
            {{ item.acquisitionPrice }}
          </td>
          <td *ngIf="item.internetProvider; else none">{{ item.internetProvider }}</td>
        </ng-template>
        <td *ngIf="item.status; else none">
          <span
            [ngClass]="
              'badge ' + badgeService.getBadgeByRouterStatus(item.status)
            "
          >
            {{ "enumMapping.router." + item.status | translate }}
          </span>
        </td>
        <td>
          <app-drob-zone-options
            [options]="options"
            (optionSelected)="saveOption($event, item)"
          ></app-drob-zone-options>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="forbidden" class="d-flex justify-content-center m-t-20">
    {{ "response.error.FORBIDDEN_RESPONSE" | translate }}
  </div>
  <div *ngIf="routersResult.count == 0 && !(loading$ | async) && !forbidden;" class="d-flex justify-content-center m-t-20">
    {{"noData" | translate}}
  </div>
  <div *ngIf="(loading$ | async) && !forbidden">
    <ng-container style="float: right" class="flex">
      <div class="loader-box">
        <div class="loader-34"></div>
      </div>
      <h6 class="col align-self-center f-w-600" style="text-align: center">
        {{ "fetching" | translate }}
      </h6>
    </ng-container>
  </div>
  <div class="d-flex">
    <div class="col-3"></div>
    <div class="col-6">
      <div class="d-flex justify-content-center p-3">
        <ngb-pagination
          [collectionSize]="routersResult.count!"
          [(page)]="routersFilter.pager.pageNumber"
          [pageSize]="routersFilter.pager.pageSize"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="changePage($event)"
        >
        </ngb-pagination>
      </div>
    </div>
    <div class="col-3">
      <div class="d-flex justify-content-end p-3">
        <select
          class="form-select"
          style="width: auto"
          name="pageSize"
          [(ngModel)]="changePageSize"
        >
          <option [ngValue]="5">
            5 {{ "pagination.itemsPerPage" | translate }}
          </option>
          <option [ngValue]="10">
            10 {{ "pagination.itemsPerPage" | translate }}
          </option>
          <option [ngValue]="15">
            15 {{ "pagination.itemsPerPage" | translate }}
          </option>
          <option [ngValue]="count">
            {{ "pagination.allItems" | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>