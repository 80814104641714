import { Component, Input, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/base.component";
import { Observable, takeUntil } from "rxjs";
import { AppStateInterface } from "src/app/store/appState.interface";
import { select, Store } from "@ngrx/store";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import {
  advertiserProfileDetailsSelector,
  advertiserLoadingSelector,
  resultUpdateAdvertiserSlector,
} from "src/app/store/advertiser/advertiser.selectors";
import {
  invokeUpdateAdvertiser,
  invokeProfileDetails,
  resultUpdateAdvertiser,
} from "src/app/store/advertiser/advertiser.actions";

import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { uploadFileSelector } from "src/app/store/file/file.selectors";
import { setFileName } from "src/app/store/file/file.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { billingSelector } from "src/app/store/billing/billing..selectors";
import { BillingInterface } from "src/app/shared/models/billing/billing.interface";
import { JwtService } from "src/app/shared/services/jwt.service";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import {
  resultLPInfoSelector,
  updateLocationPartnerAccountSuccessSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { TranslateService } from "@ngx-translate/core";
import { invokeLPInfo } from "src/app/store/locationPartner/actions/get-locations.actions";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import {
  invokeAdminProfile,
  invokeUpdateAdminProfile,
  setCrudCollaboratorSuccess,
} from "src/app/store/admin/admin.actions";
import {
  adminProfileSelector,
  crudCollaboratorSuccessSelector,
} from "src/app/store/admin/admin.selectors";
import { AdminProfileInterface } from "src/app/shared/models/admin/admin-profile.interface";
import { environment } from "src/environments/environment";
import {
  invokeUpdateAccount,
  updateLocationPartnerSuccess,
} from "src/app/store/locationPartner/actions/update.actions";
import { invokeBillingInfo } from "src/app/store/billing/billing.actions";
import { UpdatePictureComponent } from "../update-picture/update-picture.component";
@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent extends BaseComponent implements OnInit {
  @Input() companyActivities: IberisActivities[];
  previewImage: any = "assets/images/user/profile-picture.png";
  uploadFile: string | null = null;

  billingInfo$: Observable<BillingInterface | null>;
  billingMissingInfo: number = 0;
  loading$: Observable<boolean | null>;

  profileDetails$: Observable<AdvertiserDetailsInterface | null>;
  active = 1;
  overview: any = {
    email: null,
    phone: 0,
    companyActivity: null,
    logo: null,
    companyName: null,
    companyAddress: null,
    firstName: null,
    lastName: null,
    webSite: null,
    activated: false,
    creationDate: null,
    role: null,
  };
  newOverview: any = {
    companyName: "",
    companyActivity: "",
    companyAddress: "",
    phone: "",
    email: "",
    role: "",
    premiumEndDate: null,
  };
  uploadedFile$: Observable<string | null>;
  advertiserEditProfileSuccess$: Observable<SuccessDto | null>;
  adminUpdateProfileSuccess$: Observable<string | null>;
  lpEditProfileSuccess$: Observable<SuccessDto | null>;

  profileMissingInfo: number = 0;

  totalPercentage: number = 0;
  profileMissingFields: string[] = [];
  billingMissingFields: string[] = [];
  profilePower: string;

  totalFields: number = 0;
  role: string;
  columns: IColumn[] = [
    {
      key: "companyName",
      label: "profile.profileDetails.companyName",
      sorter: false,
    },
    {
      key: "fullName",
      label: "profile.profileDetails.fullName",
      sorter: false,
    },
    {
      key: "companyActivity",
      label: "profile.profileDetails.activity",
      sorter: false,
    },
    {
      key: "companyAddress",
      label: "profile.profileDetails.companyAddress",
      sorter: false,
    },
    {
      key: "phone",
      label: "profile.profileDetails.tel",
      sorter: false,
    },
    {
      key: "email",
      label: "profile.profileDetails.email",
      sorter: false,
    },
    {
      key: "role",
      label: "profile.profileDetails.role",
      sorter: false,
    },
  ];
  columnsAdvertiser: IColumn[] = [
    {
      key: "birthday",
      label: "profile.profileDetails.birthday",
      sorter: false,
    },
    {
      key: "webSite",
      label: "profile.profileDetails.webSite",
      sorter: false,
    },
  ];
  /** LOCATION PARTNER  */
  profileLocationPartner$: Observable<LPInfo | null>;
  profileLocationPartner: LPInfo | null;
  advertiser: boolean;
  location: boolean;
  admin: boolean;
  showPictureUpdateModal: NgbModalRef;
  /** sub role */
  roleEnum = RoleEnum;
  adminProfile$: Observable<AdminProfileInterface | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private jwtService: JwtService,
    protected translate: TranslateService
  ) {
    super(store);
    this.profileDetails$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.billingInfo$ = this.store
      .pipe(select(billingSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.loading$ = this.store
      .pipe(select(advertiserLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.uploadedFile$ = this.store
      .pipe(select(uploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.advertiserEditProfileSuccess$ = this.store
      .pipe(select(resultUpdateAdvertiserSlector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adminUpdateProfileSuccess$ = this.store
      .pipe(select(crudCollaboratorSuccessSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.lpEditProfileSuccess$ = this.store
      .pipe(select(updateLocationPartnerAccountSuccessSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.profileLocationPartner$ = this.store
      .pipe(select(resultLPInfoSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.adminProfile$ = this.store
      .pipe(select(adminProfileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());
    switch (this.role) {
      case RoleEnum.ROLE_ADVERTISER:
        this.advertiser = true;
        break;
      case RoleEnum.ROLE_LOCATION:
        this.location = true;
        break;
      case RoleEnum.ROLE_ADMIN:
        this.admin = true;
        break;
    }
    if (this.role == RoleEnum.ROLE_ADMIN) {
      this.adminUpdateProfileSuccess$.subscribe((message: string | null) => {
        if (message != null)
          this.toastr.success(
            this.translate.instant("response.success." + message),
            this.translate.instant("response.successTitle")
          );
        this.store.dispatch(setCrudCollaboratorSuccess({ success: null }));
        this.store.dispatch(invokeAdminProfile());
      });
      this.adminProfile$.subscribe((profile) => {
        if (profile != null) {
          this.overview = {
            email: profile.email,
            phone: profile.phone,
            companyActivity: this.companyActivities.find(
              (value) => profile.companyActivity == value.hashed_id
            )?.title,
            logo: profile.logo,
            companyName: profile.companyName,
            companyAddress: profile.companyAddress,
            firstName: profile.firstName,
            lastName: profile.lastName,
            companyRole : profile.companyRole
          };
          if(profile.logo && profile.logo !="" ){
            this.uploadFile = environment.CDN_RELEAD + profile.logo;
          }
          this.totalFields += Object.keys(this.overview).length;
          this.profileMissingFields = [];
          Object.values(this.overview).filter((value, index) => {
            if (value == null || value == "") {
              this.profileMissingInfo += 1;
              this.profileMissingFields.push(Object.keys(this.overview)[index]);
              return true;
            } else return false;
          }).length;
          this.getBillingPercentage(this.profileMissingInfo, 0);
        } else this.store.dispatch(invokeAdminProfile());
      });
    }
    if (this.role == RoleEnum.ROLE_ADVERTISER) {
      this.advertiserEditProfileSuccess$.subscribe(
        (message: SuccessDto | null) => {
          if (message != null) {
            this.toastr.success(
              this.translate.instant("response.success." + message.message),
              this.translate.instant("response.successTitle")
            );
            this.store.dispatch(
              resultUpdateAdvertiser({ successMessage: null })
            );
            this.store.dispatch(invokeProfileDetails());
            this.store.dispatch(
              invokeBillingInfo({ email: this.jwtService.getEmail() })
            );
          }
        }
      );
      this.profileDetails$.subscribe(
        (result: AdvertiserDetailsInterface | null) => {
          if (result?.email != null && result?.email != "") {
            if(result.logo && result.logo !=""){
              
              this.uploadFile = environment.CDN_RELEAD + result.logo;
              
            }
            this.overview = {
              ...result,
              companyActivity: this.companyActivities.find(
                (value) => result.companyActivity == value.hashed_id
              )?.title,
            };
            
            //this.newOverview.push(this.overview);
            this.totalFields += Object.keys(this.overview).length;
            this.profileMissingFields = [];
            Object.values(this.overview)
              //.filter((value) => value != result.iberis)
              .filter((value, index) => {
                if (value == null || value == "") {
                  this.profileMissingInfo += 1;
                  this.profileMissingFields.push(
                    Object.keys(this.overview)[index]
                  );
                  return true;
                } else return false;
              }).length;
            //calculate total percentage
            this.profileMissingFields.filter((value)=> value == "iberis")
            this.getBillingPercentage(
              this.profileMissingInfo,
              this.billingMissingInfo
            );
          } else {
            environment.CDN_RELEAD +
              this.store.dispatch(invokeProfileDetails());
          }
        }
      );
    } else if (this.role == RoleEnum.ROLE_LOCATION) {
      this.lpEditProfileSuccess$.subscribe((message: SuccessDto | null) => {
        if (message != null) {
          this.toastr.success(
            this.translate.instant("response.success." + message.message),
            this.translate.instant("response.successTitle")
          );
          this.store.dispatch(
            updateLocationPartnerSuccess({ successMessage: null })
          );
          this.store.dispatch(invokeLPInfo());
          this.store.dispatch(
            invokeBillingInfo({ email: this.jwtService.getEmail() })
          );
        }
      });
      this.profileLocationPartner$.subscribe((result) => {
        if (result) {
          if(result.logo && result.logo !=""){
            this.uploadFile = environment.CDN_RELEAD + result.logo;
          }
          this.overview = {
            ...result,
            companyActivity: this.companyActivities.find(
              (value) => result.companyActivity == value.hashed_id
            )?.title,
          };
          this.newOverview.companyName = result.companyName;
          this.newOverview.companyAddress = result.companyAddress;
          this.newOverview.premiumEndDate = result.premiumEndDate;
          this.newOverview.companyActivity = this.companyActivities.find(
            (value) => result.companyActivity == value.hashed_id
          )?.title;
          this.newOverview.email = result.email;
          this.newOverview.phone = result.phone;
          this.newOverview.role = result.companyRole;
          this.totalFields += Object.keys(this.newOverview).length;

          this.profileMissingFields = [];
          Object.values(this.newOverview).filter((value, index) => {
            if (value == null || value == "") {
              this.profileMissingInfo += 1;
              this.profileMissingFields.push(
                Object.keys(this.newOverview)[index]
              );
              return true;
            } else return false;
          }).length;
          //calculate total percentage
          this.getBillingPercentage(
            this.profileMissingInfo,
            this.billingMissingInfo
          );
        } else {
          this.store.dispatch(invokeLPInfo());
        }
      });
    }
    this.billingInfo$.subscribe((billing) => {
      if (billing) {
        this.totalFields += Object.keys(billing).length;
        this.billingMissingFields = [];
        Object.values(billing).filter((value, index) => {
          if (value == null || value == "") {
            this.billingMissingInfo += 1;
            this.billingMissingFields.push(Object.keys(billing)[index]);
            return true;
          } else return false;
        }).length;
        //calculate total percentage
        this.getBillingPercentage(
          this.profileMissingInfo,
          this.billingMissingInfo
        );
      }
    });
    this.uploadedFile$.subscribe((fileName: string | null) => {
      if (fileName != null && fileName != "") {
        this.modalService.dismissAll();

        this.onSubmitEditLogo(fileName);
        this.store.dispatch(setFileName({ filename: null }));
      }
    });
  }
  showPictureUpdate() {
    this.showPictureUpdateModal = this.modalService.open(
      UpdatePictureComponent,
      {
        centered: true,
      }
    );

    this.showPictureUpdateModal.componentInstance.closeTheModal.subscribe(
      (value: boolean) => this.showPictureUpdateModal.close()
    );
  }
  getBillingPercentage(profile: number, billing: number) {
    this.totalPercentage = 100 - ((profile + billing) / this.totalFields) * 100;
    if (this.totalPercentage < 40) {
      this.profilePower = "profile.profilePower.weak";
    } else if (this.totalPercentage > 70) {
      this.profilePower = "profile.profilePower.strong";
    } else {
      this.profilePower = "profile.profilePower.average";
    }
  }

  onSubmitEditLogo(newLogo: string) {
    if (this.role == RoleEnum.ROLE_ADVERTISER) {
      this.store.dispatch(
        invokeUpdateAdvertiser({
          profileDetails: {
            ...this.overview,
            logo: newLogo,
          },
          email: this.overview.email,
        })
      );
    }
    if (this.role == RoleEnum.ROLE_LOCATION) {
      this.store.dispatch(
        invokeUpdateAccount({
          Authorization: this.jwtService.getToken(),
          locationPartnerDto: {
            ...this.overview,
            logo: newLogo,
          },
        })
      );
    }

    if (this.role == RoleEnum.ROLE_ADMIN) {
      this.store.dispatch(
        invokeUpdateAdminProfile({
          profile: {
            ...this.overview,
            logo: newLogo,
          },
        })
      );
    }
  }
}
