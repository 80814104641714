import { Component, OnInit, Output, ViewChild } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  getAdvertisingCampaignByHashedId,
  invokeAddAdvertisingCampaign,
  invokeAdDetails,
  invokeAdvertisingCompaignByHashedId,
  invokeChangeAdStatus,
  invokeUpadateAdPaymentDetails,
  invokeUpdateAdCampaignDetails,
  SetAddAdvertisingCampaign,
  updateAdCampaignDetails,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { uploadFileSelector } from "src/app/store/file/file.selectors";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import {
  HashedObjectiveEnum,
  ObjectiveTypeEnum,
} from "src/app/shared/enum/objectives";
import {
  addCampaignSelector,
  adDetailsSelector,
  getAdCompaignByHashedIdSelector,
  updateAdCampaignDetailsSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "src/app/shared/models/calcul/totalCost";
import { calculateTotalLPCostByCampanyNamesSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { ToastrService } from "ngx-toastr";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { SuccessDto } from "src/app/shared/models/success-dto";
import {
  invokeCreateEstimate,
  invokeSynthesisEstimate,
  invokeUpdateEstimate,
  resultCreateEstimate,
} from "src/app/store/commande/commande.actions";
import { Constants } from "src/app/shared/data/constants";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import {
  resultCreateEstimateSelector,
  resultSynthesisEstimateSelector,
} from "src/app/store/commande/commande.selectors";
import { DatePipe } from "@angular/common";
import { AdvertisingPaymentMethodEnum } from "src/app/shared/enum/paymentMethod";
import { PaymeeService } from "src/app/shared/services/payment/paymee.service";
import { KonnectService } from "src/app/shared/services/payment/konnect.service";
import {
  invokeIdIberis,
  invokeProfileDetails,
  resultIdIberis,
  saveFieldsLeadGeneration,
} from "src/app/store/advertiser/advertiser.actions";
import {
  advertiserProfileDetailsSelector,
  resultIdIberisSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  invokeNotifyAdminWhenAdvertisingCampaignCreated,
  invokeSendLeadGenerationForm,
  resultSindingMail,
} from "src/app/store/mailing/mailing.actions";
import { LeadGeneration } from "src/app/shared/models/leadGeneration/leadGeneration";
import { sendMailSelector } from "src/app/store/mailing/mailing.selectors";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { CalculateCost } from "src/app/shared/models/calcul/calculateCost";
import { LocationAvailabilityEnum } from "src/app/shared/enum/location-availability";
import {
  AdvertisingCampaignInterface,
  CreateAdvertisingCampaignInterface,
} from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { environment } from "src/environments/environment";
import { Data } from "src/app/shared/models/iberis/commande/data";
import { calculateTotalLPCostByCampanyNames } from "src/app/store/locationPartner/location-partner.action";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";
import { defaultFieldsData } from "src/app/shared/data/newFieldsFormData";
import { AdPaymentDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-payment-details.interface";
import {
  ErrorPostKonnectInterface,
  PostKonnectInterface,
  ResultKonnectInterface,
} from "src/app/shared/models/payment/konnect.interface";
import { TypeFieldsLeadGeneration } from "src/app/shared/enum/typeFieldsLeadGeneration";
import { DiscountTypeEnum } from "src/app/shared/enum/discount-type.enum";
import { CompanyIberisService } from "src/app/shared/services/iberis/company-iberis.service";
import { WizardComponent } from "angular-archwizard";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";

@Component({
  selector: "app-new-campaign",
  templateUrl: "./new-campaign.component.html",
  styleUrls: ["./new-campaign.component.scss"],
})
export class NewCampaignComponent extends BaseComponent implements OnInit {
  objectiveName: any;
  submitted: boolean = false;
  objectiveTypeEnum = ObjectiveTypeEnum;

  adDetails$: Observable<AdDetailsInterface>;
  estimateId: string = "";
  adDetails: AdDetailsInterface = { ...initialState.adDetails };
  //
  calculateTotalLPCostByCompanyNames$: Observable<TotalCostWithDiscount | null>;
  calculateTotalLPCostByCompanyNames: TotalCost[] = [];
  selectedLocationsNames: string[];

  @Output() form: FormGroup;

  //upload file
  filename: string;
  uploadFile$: Observable<string | null>;

  /** create estimate */
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  createEstimate: createEstimate;
  createEstimates$: Observable<ResultCreateEstimate | null>;
  estimateNumber: string = "";
  nextEstimatedNumber: string;
  profile$: Observable<AdvertiserDetailsInterface | null>;
  profile: AdvertiserDetailsInterface;
  idIberis$: Observable<string | null>;
  idIberis: string;
  formLeadGeneration: LeadGeneration = {
    title: "",
    description: "",
    fields: [],
    buttonName: "",
    adTitle: "",
    owner: "",
    creationDate: null,
  };
  resultSendMailLeadGeneration$: Observable<SuccessDto | null>;
  resultLeadGeneration: string;

  isPublish: boolean = false;
  campaignHashedID: string;
  adByHashedId$: Observable<AdvertisingCampaignInterface | null>;
  adByHashedId: AdvertisingCampaignInterface;
  startDate: Date | null;
  endDate: Date | null;
  calculateCost: CalculateCost = {
    calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
    names: [],
    objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
    startDate: null,
    endDate: null,
  };
  totalCostPerDay: number = 0;
  impressionsPerDay: number = 0;
  resultSynthesisEstimate$: Observable<ResultSynthesisEstimate | null>;
  resultSynthesisEstimate: Data;
  createAdCampaign: CreateAdvertisingCampaignInterface;

  formStep2: FormGroup;
  paymentMethod: any;
  totalCost: number = 0;

  defaultItems = defaultFieldsData;
  loading: boolean;

  condition: string = "";

  isCreated: boolean = false;
  isCreated$: Observable<SuccessDto | null>;
  updateAdvertisingCampaign$: Observable<SuccessDto | null>;
  webSite: string | null = null;
  @ViewChild("wizard") wizard: WizardComponent;
  adStatus: CampaignStatusEnum = CampaignStatusEnum.DRAFT;
  loadingVideo: boolean;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private paymeeService: PaymeeService,
    private konnectService: KonnectService,
    private router: Router,
    private newCampaignCalculatorService: NewCampaignCalculatorService,
    private companyService: CompanyIberisService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.profile$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.uploadFile$ = this.store
      .pipe(select(uploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.calculateTotalLPCostByCompanyNames$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.createEstimates$ = this.store
      .pipe(select(resultCreateEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.idIberis$ = this.store
      .pipe(select(resultIdIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSendMailLeadGeneration$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adByHashedId$ = this.store
      .pipe(select(getAdCompaignByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSynthesisEstimate$ = this.store
      .pipe(select(resultSynthesisEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isCreated$ = this.store
      .pipe(select(addCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.updateAdvertisingCampaign$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group({
      title: [
        null,
        [
          Validators.required,
          Validators.maxLength(32),
          Validators.minLength(3),
        ],
      ],
      startingDate: [null, Validators.required],
      endingDate: [null, Validators.required],
      locationPartners: [[], Validators.required],
      subCategories: [[]],
      zones: [[]],
    });
    this.formStep2 = this.fb.group({
      webSite: [
        "https://",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^https://.*"),
        ],
      ],
    });

    this.objectiveName = Object.keys(HashedObjectiveEnum).find(
      (key) =>
        HashedObjectiveEnum[key as ObjectiveTypeEnum] ===
        this.activatedRoute.snapshot.params["objective"]
    );
    this.campaignHashedID =
      this.activatedRoute.snapshot.params["campaignHasedID"];
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(
          getAdvertisingCampaignByHashedId({ resultCampaignByHashedId: null })
        );
        this.isPublish = this.route.url.includes("/payment");
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(SetAddAdvertisingCampaign({ adCreated: null }));
    this.isCreated = false;
    this.isCreated$.subscribe((data) => {
      if (data) {
        this.campaignHashedID = data.message;
        this.store.dispatch(
          invokeUpdateEstimate({
            params: this.params,
            estimateId: this.estimateId,
            createEstimate: {
              ...this.createEstimate,
              notes: this.campaignHashedID,
            },
          })
        );
        this.store.dispatch(
          invokeNotifyAdminWhenAdvertisingCampaignCreated({
            title: this.createAdCampaign.title,
            email: this.profile.email.toLowerCase(),
          })
        );
        this.isCreated = true;
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              campaignHashedID: this.campaignHashedID,
              isMuted: true,
            },
          })
        );
      }
    });
    this.store.dispatch(resultIdIberis({ idIberis: null }));
    this.store.dispatch(invokeAdDetails({ invokeAdDetails: this.adDetails }));
    this.store.dispatch(invokeIdIberis());
    this.store.dispatch(
      calculateTotalLPCostByCampanyNames({ totalCostWithDiscount: null })
    );
    this.updateAdvertisingCampaign$.subscribe((data) => {
      if (data) {
        this.store.dispatch(updateAdCampaignDetails({ successMessage: null }));
      }
    });
    if (this.isPublish) {
      this.store.dispatch(
        invokeAdvertisingCompaignByHashedId({
          campaignHashedId: this.campaignHashedID,
        })
      );
    }
    if (this.isPublish) {
      this.adByHashedId$.subscribe((result) => {
        if (result) {
          this.adStatus = result.status;
          this.wizard.reset();
          this.startDate = result.startingDate;
          this.endDate = result.endingDate;
          this.adByHashedId = {
            ...result,
            startingDate: new Date(result.startingDate),
            endingDate: new Date(result.endingDate),
          };
          this.form.patchValue({ ...this.adByHashedId });
          this.formStep2.patchValue({
            webSite: this.adByHashedId.redirectionLink,
          });
          let calculationMethod: LocationSearchMethod =
            LocationSearchMethod.LOCATION_PARTNER;
          if (result.calculationMethod) {
            calculationMethod = result.calculationMethod;
          }
          this.filename = this.adByHashedId.videoLink;

          const adDetails: AdDetailsInterface = {
            ...this.adDetails,
            endDate: result.endingDate,
            startDate: result.startingDate,
            locations: result.locationPartners,
            title: result.title,
            objectiveName: result.objectiveName,
            estimateId: result.estimateId,
            videoLink: result.videoLink,
            calculationMethod: calculationMethod,
            campaignHashedID: this.campaignHashedID,
            validators: {
              title: true,
              locations: true,
              zones: true,
              dates: true,
              conditions: false,
            },
          };
          this.objectiveName = result.objectiveName;
          this.store.dispatch(
            invokeAdDetails({
              invokeAdDetails: adDetails,
            })
          );

          this.calculateCost.endDate = result.endingDate;
          this.calculateCost.startDate = result.startingDate;
          this.calculateCost.names = result.locationPartners;
          this.newCampaignCalculatorService.onSelectCalculateCost(
            adDetails,
            adDetails.locations
          );

          this.store.dispatch(
            invokeSynthesisEstimate({
              params: this.params,
              estimateId: result.estimateId,
            })
          );
        }
      });
    }
    this.adDetails$.subscribe((adDetails: AdDetailsInterface) => {
      this.adDetails = adDetails;
      this.startDate = this.adDetails.startDate;
      this.endDate = this.adDetails.endDate;
    });

    this.resultSynthesisEstimate$.subscribe(
      (result: ResultSynthesisEstimate | null) => {
        if (result != null) {
          this.resultSynthesisEstimate = result.data.estimate;
          this.estimateId = result.data.estimate.hashed_id;
          this.estimateNumber = result.data.estimate.estimate_number.replace(
            "DV-",
            ""
          );
          this.store.dispatch(
            invokeAdDetails({
              invokeAdDetails: {
                ...this.adDetails,
                estimateNumber: this.estimateNumber,
                estimateId: result.data.estimate.hashed_id,
                startDate: this.startDate,
                endDate: this.endDate,
              },
            })
          );
        }
      }
    );
    this.resultSendMailLeadGeneration$.subscribe(
      (result: SuccessDto | null) => {
        if (result != null) {
          this.resultLeadGeneration = result.message;
          if (this.resultLeadGeneration == "MISSING_INFORMATION") {
            this.toastr.error(
              this.translate.instant(
                "response.error." + this.resultLeadGeneration
              ),
              this.translate.instant("response.errorTitle")
            );
          }
          this.store.dispatch(resultSindingMail({ successMessage: null }));
        }
      }
    );
    this.uploadFile$.subscribe((value: string | null) => {
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: {
            ...this.adDetails,
            justificationLink: value ?? "",
          },
        })
      );
    });
    this.profile$.subscribe((user) => {
      if (user) this.profile = user;
      else this.store.dispatch(invokeProfileDetails());
    });
    this.store.dispatch(resultCreateEstimate({ resultCreateEStimate: null }));
    this.calculateTotalLPCostByCompanyNames$.subscribe((value) => {
      if (value != null) {
        this.calculateTotalLPCostByCompanyNames = value.totalCostPerDay.filter(
          (value) =>
            value.availabilities.availabilityStatus !=
            LocationAvailabilityEnum.NOT_AVAILABLE
        );
        this.selectedLocationsNames =
          this.calculateTotalLPCostByCompanyNames.map(
            (location) => location.companyName
          );
        this.form
          .get("locationPartners")
          ?.patchValue(this.selectedLocationsNames);
        this.totalCostPerDay =
          this.newCampaignCalculatorService.calculateCostPerDay(value);
        this.impressionsPerDay =
          this.newCampaignCalculatorService.calculateImpressionsPerDay(value);

        /*const totalCost: number = this.adByHashedId
          ? this.adByHashedId.totalHt
          : this.newCampaignCalculatorService.calculateTotalCost(
              value,
              this.calculateDays()
            );*/
        const totalCost: number =
          this.newCampaignCalculatorService.calculateTotalCost(
            value,
            this.calculateDays()
          );
        const totalImpressions: number =
          this.newCampaignCalculatorService.calculateTotalImpressions(
            value,
            this.calculateDays()
          );
        const clicksPerDay: number =
          Math.trunc(this.impressionsPerDay * 0.05) ?? 0;
        const leadPerDay: number =
          Math.trunc(this.impressionsPerDay * 0.6) ?? 0;
        const totalClicks: number = Math.trunc(totalImpressions * 0.05) ?? 0;
        const totalLead: number = Math.trunc(totalImpressions * 0.6) ?? 0;
        if (
          this.selectedLocationsNames.length != this.adDetails.locations.length
        ) {
          this.condition = Constants.availibilityCondition;
        }
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              startDate: this.startDate,
              endDate: this.endDate,
              totalCostPerDay: this.totalCostPerDay,
              totalCost: totalCost,
              impressionsPerDay: this.impressionsPerDay,
              clicksPerDay: clicksPerDay,
              leadPerDay: leadPerDay,
              totalClicks: totalClicks,
              totalLead: totalLead,
              totalImpressions: totalImpressions,
              locations: this.selectedLocationsNames,
            },
          })
        );
      }
    });

    this.form.valueChanges.subscribe(() => {
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: {
            ...this.adDetails,
            title: this.form.get("title")?.value,
            startDate: this.form.get("startingDate")?.value,
            endDate: this.form.get("endingDate")?.value,
            objectiveName: this.objectiveName,
            locations: this.form.get("locationPartners")?.value,
            validators: {
              conditions: this.adDetails.validators.conditions,
              dates: this.datesValidator(),
              locations: this.locationsValidator(),
              title: this.titleValidator(),
              zones: this.zonesValidator(),
            },
          },
        })
      );
    });
    this.formStep2.valueChanges.subscribe(() => {
      if (this.objectiveName == ObjectiveTypeEnum.WEB_SITE_TRAFFIC) {
        this.webSite = this.formStep2.get("webSite")?.value;
      } else {
        this.webSite = null;
      }
    });
    if (!this.isPublish) {
      this.companyService.getIberisCompanyData(this.params).subscribe({
        next: (data) => {
          const estimate_number = JSON.parse(
            data.data.company.estimate_naming_series
          );
          this.nextEstimatedNumber = estimate_number.number;
        },
        error: (e) => {},
      });
    }
    this.createEstimates$.subscribe((result) => {
      if (result?.data.estimate) {
        this.estimateId = result.data.estimate.hashed_id;
        this.estimateNumber = result.data.estimate.estimate_number;

        this.createAdCampaign = {
          title: this.form.get("title")?.value,
          startingDate: this.form.get("startingDate")?.value,
          endingDate: this.form.get("endingDate")?.value,
          locationPartners: this.selectedLocationsNames,
          videoLink: this.filename,
          objectiveName: this.objectiveName,
          status: CampaignStatusEnum.DRAFT,
          estimateId: this.estimateId,
          redirectionLink: this.webSite,
          views: 0,
          synthesisReport: null,
          calculationMethod: this.adDetails.calculationMethod,
          totalHt: this.adDetails.totalCost,
        };
        this.store.dispatch(
          invokeAddAdvertisingCampaign({
            addCampaign: this.createAdCampaign,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.POST,
          UserHistoryServicesEnum.UPDATE_ADVERTISING_CAMPAIGN
        );
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              locations: this.selectedLocationsNames,
              estimateId: result.data.estimate.hashed_id,
              estimateNumber: result.data.estimate.estimate_number.replace(
                "DV-",
                ""
              ),
            },
          })
        );
      }
    });
    this.idIberis$.subscribe((result) => {
      if (result) {
        this.idIberis = result;
      }
    });
  }
  calculateDays(): number {
    if (this.startDate && this.endDate) {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    } else return 0;
  }
  handleValueChanged(value: any) {
    this.formStep2.get("webSite")?.setValue(value);
  }
  handleFileName(value: string) {
    this.filename = value;
  }
  handleLoadingVideo(value: boolean) {
    this.loadingVideo = value;
  }
  getWebSite() {
    return this.formStep2.get("webSite")?.valid;
  }
  //*****Validators ******//
  titleValidator(): boolean {
    return this.form.get("title")?.valid ?? false;
  }
  datesValidator(): boolean {
    return (
      (this.form.get("startingDate")?.valid &&
        this.form.get("endingDate")?.valid) ??
      false
    );
  }
  locationsValidator(): boolean {
    return this.form.get("locationPartners")?.valid ?? false;
  }
  zonesValidator(): boolean {
    return this.form.get("zones")?.valid ?? false;
  }

  //****  pagination *****//
  checkValidation(step: number): boolean {
    if (step == 1) {
      return (
        this.titleValidator() &&
        this.datesValidator() &&
        (this.form.get("locationPartners")?.valid || this.zonesValidator())
      );
    } else if (step == 2) {
      if (this.objectiveName == ObjectiveTypeEnum.WEB_SITE_TRAFFIC)
        return (
          (this.formStep2.get("webSite")?.valid ?? false) &&
          this.adDetails.videoFile != undefined
        );
      if (this.objectiveName == ObjectiveTypeEnum.GENERATION_DE_PROSPECTS)
        return (
          this.formLeadGeneration.description != "" &&
          this.formLeadGeneration.title != ""
        );
      else {
        return true;
      }
    } else {
      return true;
    }
    //here we must check validations
  }
  saveLead(leadGeneration: LeadGeneration) {
    this.formLeadGeneration = leadGeneration;
  }

  next(step: number) {
    if (step == 1) {
      this.wizard.goToNextStep();
    }
    this.submitted = true;
    let estimateNumber = "";
    let number = "";
    if (this.isPublish) {
      number = this.resultSynthesisEstimate.estimate_number.replace("DV-", "");
      estimateNumber = number.substring(number.lastIndexOf("-") + 1);
    } else {
      estimateNumber = this.nextEstimatedNumber;
    }
    var discountType: number = 1;
    var discountValue: number = 0;
    switch (this.adDetails.promotion?.discountType) {
      case DiscountTypeEnum.AMOUNT: {
        discountType = 2;
        discountValue = this.adDetails.promotion?.discountValue;
        break;
      }
      case DiscountTypeEnum.PERCENTAGE: {
        discountType = 1;
        discountValue = this.adDetails.promotion?.discountValue;
        break;
      }
    }
    this.createEstimate = {
      date: this.datePipe.transform(Constants.DATE_IBERIS, "yyyy-MM-dd"),
      due: this.datePipe.transform(Constants.DUE_DATE_IBERIS, "yyyy-MM-dd"),
      estimate_number: estimateNumber,
      object: "Campagne " + this.adDetails.title,
      notes: this.campaignHashedID,
      tax_type: 1,
      client_id: this.idIberis,
      use_conditions: 0,
      show_stamp: 1,
      show_billing: 1,
      show_delivery: 0,
      show_bank: 1,
      bank_id: environment.IBERIS.BANK_ID,
      show_conditions: 1,
      show_description: 1,
      show_pictures: 1,
      choice: 1,
      currency_rate: 1,
      language: this.params.lang,
      conditions: this.condition,
      reference: "",
      discount: "",
      additional_contact_id: "",
      show_unity: 1,
      items: {
        //item_hashed_id: "",
        item: "Campagne " + this.adDetails.title,
        description:
          this.datePipe.transform(this.adDetails.startDate, "yyyy-MM-dd ") +
          "-" +
          this.datePipe.transform(this.adDetails.endDate, "yyyy-MM-dd ") +
          "\n" +
          this.selectedLocationsNames,
        discountType: discountType,
        discountAmount: discountValue,
        qte: 1,
        price: this.adDetails.totalCost,
        taxes: [{ tax_hashed_id: environment.IBERIS.TAX_HASHED_ID }],
      },
      useInput: {
        input_hashed_id: environment.IBERIS.TIMBRE_FISCAL_HASHED_ID,
        value: 1,
      },
    };
    this.submitted = true;
    if (
      this.adDetails.objectiveName != ObjectiveTypeEnum.GENERATION_DE_PROSPECTS
    ) {
      this.nextSteps_other_objectives(step);
    } else {
      if (this.defaultItems.length < 5) {
        this.defaultItems = [
          {
            title: "leadGeneration.fields.name",
            logo: "icofont icofont-ui-user",
            type: TypeFieldsLeadGeneration.TEXT,
            values: [],
          },
          {
            title: "leadGeneration.fields.tel",
            logo: "icofont icofont-telephone",
            type: TypeFieldsLeadGeneration.NUMBER,
            values: [],
          },
          {
            title: "leadGeneration.fields.email",
            logo: "icofont icofont-ui-email",
            type: TypeFieldsLeadGeneration.TEXT,
            values: [],
          },
          {
            title: "leadGeneration.fields.birthday",
            logo: "icofont icofont-ui-calendar",
            type: TypeFieldsLeadGeneration.DATE,
            values: [],
          },
          {
            title: "leadGeneration.fields.profession",
            logo: "icofont icofont-teacher",
            type: TypeFieldsLeadGeneration.TEXT,
            values: [],
          },
        ];
      }
      this.store.dispatch(
        saveFieldsLeadGeneration({
          fieldLeadGeneration: null,
        })
      );
      switch (step) {
        case 1:
          if (!this.checkValidation(1)) {
            break;
          } else {
            if (this.isPublish) {
              this.updateCampaign(1);
            } else if (this.isCreated) {
              this.updateCampaignCreated(1);
            }
            break;
          }
        case 2:
          this.unMuteVideo();
          this.sendLeadGeneration();
          if (!this.checkValidation(2)) {
            break;
          } else {
            break;
          }
        case 3:
          this.muteVideo();
          if (!this.isPublish && !this.isCreated) {
            this.store.dispatch(
              invokeCreateEstimate({
                params: this.params,
                createEstimate: this.createEstimate,
              })
            );
          }
          if (this.isPublish) {
            this.updateCampaign(2);
          } else if (this.isCreated) {
            this.updateCampaignCreated(2);
          }
          break;
        default:
          break;
      }
    }
  }

  sendLeadGeneration() {
    this.store.dispatch(
      invokeSendLeadGenerationForm({
        leadGeneration: this.formLeadGeneration,
      })
    );
    if (this.checkValidation(2)) {
      this.wizard.goToNextStep();
    }
  }

  nextSteps_other_objectives(step: number) {
    switch (step) {
      case 1:
        this.unMuteVideo();
        if (!this.checkValidation(1)) {
          break;
        } else {
          if (this.isPublish) {
            this.updateCampaign(1);
          } else if (this.isCreated) {
            this.updateCampaignCreated(1);
          }
          break;
        }
      case 2:
        this.muteVideo();
        if (!this.isPublish && !this.isCreated) {
          this.store.dispatch(
            invokeCreateEstimate({
              params: this.params,
              createEstimate: this.createEstimate,
            })
          );
        }
        if (this.isPublish) {
          this.updateCampaign(2);
        } else if (this.isCreated) {
          this.updateCampaignCreated(2);
        }
        break;
      default:
        break;
    }
  }
  updateCampaignCreated(step: number) {
    let file: string | null = null;
    if (this.adDetails.justificationLink) {
      file = this.adDetails.justificationLink;
    }
    const updateAdCampaign: AdvertisingCampaignInterface = {
      title: this.form.get("title")?.value,
      startingDate: this.form.get("startingDate")?.value,
      endingDate: this.form.get("endingDate")?.value,
      locationPartners: this.calculateTotalLPCostByCompanyNames.map(
        (e) => e.companyName
      ),
      videoLink: this.filename,
      objectiveName: this.objectiveName,
      status: this.createAdCampaign.status,
      estimateId: this.estimateId,
      redirectionLink: this.webSite,
      views: 0,
      synthesisReport: null,
      justificationFile: file,
      publishDate: new Date(),
      orderReference: this.estimateId,
      paymentMethod: this.adDetails.paymentMethod,
      calculationMethod: this.adDetails.calculationMethod,
      advertisingCampaignHashedId: this.campaignHashedID,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      emailPublisher: this.profile.email.toLowerCase(),
      publisherCompanyName: this.profile.companyName,
      totalHt: this.adDetails.totalCost,
    };
    const isEqual =
      this.createAdCampaign.locationPartners.length ===
        this.calculateTotalLPCostByCompanyNames.length &&
      this.createAdCampaign.locationPartners.every((partner) =>
        this.calculateTotalLPCostByCompanyNames
          .map((e) => e.companyName)
          .includes(partner)
      );
    this.updateCampaignTreatment(
      step,
      this.createAdCampaign,
      updateAdCampaign,
      isEqual
    );
  }
  updateCampaign(step: number) {
    const updateAdCampaign = {
      title: this.form.get("title")?.value,
      startingDate: this.form.get("startingDate")?.value,
      endingDate: this.form.get("endingDate")?.value,
      locationPartners: this.calculateTotalLPCostByCompanyNames.map(
        (e) => e.companyName
      ),
      videoLink: this.filename,
      objectiveName: this.objectiveName,
      status: this.adByHashedId.status,
      estimateId: this.estimateId,
      redirectionLink: this.webSite,
      views: 0,
      synthesisReport: null,
      justificationFile: this.adByHashedId.justificationFile,
      publishDate: this.adByHashedId.publishDate,
      orderReference: this.adByHashedId.orderReference,
      paymentMethod: this.adByHashedId.paymentMethod,
      calculationMethod: this.adDetails.calculationMethod,
      advertisingCampaignHashedId: this.campaignHashedID,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      emailPublisher: this.profile.email.toLowerCase(),
      publisherCompanyName: this.profile.companyName,
      totalHt: this.adDetails.totalCost,
    };
    const isEqual =
      this.adByHashedId.locationPartners.length ===
        this.calculateTotalLPCostByCompanyNames.length &&
      this.adByHashedId.locationPartners.every((partner) =>
        this.calculateTotalLPCostByCompanyNames
          .map((e) => e.companyName)
          .includes(partner)
      );
    this.updateCampaignTreatment(
      step,
      this.adByHashedId,
      updateAdCampaign,
      isEqual
    );
  }
  updateCampaignTreatment(
    step: number,
    ad: any,
    updateAdCampaign: AdvertisingCampaignInterface,
    isEqual: boolean
  ) {
    switch (step) {
      case 1:
        {
          if (
            ad.title != this.form.get("title")?.value ||
            ad.startingDate != this.form.get("startingDate")?.value ||
            ad.endingDate != this.form.get("endingDate")?.value ||
            !isEqual
          ) {
            if (this.campaignHashedID) {
              this.store.dispatch(
                invokeUpdateEstimate({
                  params: this.params,
                  estimateId: this.estimateId,
                  createEstimate: this.createEstimate,
                })
              );
              this.store.dispatch(
                invokeUpdateAdCampaignDetails({
                  campaignHashedId: this.campaignHashedID,
                  adCompaign: updateAdCampaign,
                })
              );
              this.saveHistory.saveUserHistory(
                UserHistoryActionsEnum.PATCH,
                UserHistoryServicesEnum.UPDATE_ADVERTISING_CAMPAIGN
              );
            }
          }
        }
        break;
      case 2: {
        if (
          this.campaignHashedID &&
          ((ad.redirectionLink != this.formStep2.get("webSite")?.value &&
            this.objectiveName == ObjectiveTypeEnum.WEB_SITE_TRAFFIC) ||
            ad.videoLink != this.filename)
        ) {
          this.store.dispatch(
            invokeUpdateAdCampaignDetails({
              campaignHashedId: this.campaignHashedID,
              adCompaign: updateAdCampaign,
            })
          );
          this.saveHistory.saveUserHistory(
            UserHistoryActionsEnum.PATCH,
            UserHistoryServicesEnum.UPDATE_ADVERTISING_CAMPAIGN
          );
        }
      }
    }
  }
  resultPaymentMethod(result: AdvertisingPaymentMethodEnum | null) {
    this.paymentMethod = result;
  }
  totalCostFunction(data: number) {
    this.totalCost = data;
  }
  paymentSelected() {
    this.loading = true;
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: {
          ...this.adDetails,
          paymentMethod: this.paymentMethod,
        },
      })
    );
    if (this.estimateId) {
      switch (this.paymentMethod) {
        /*case AdvertisingPaymentMethodEnum.PAYMEE: {
          const payment: PostPaymeeInterface = {
            amount: this.totalCost,
            email: this.profile.email,
            first_name: this.profile.firstName,
            last_name: this.profile.lastName,
            note: this.campaignHashedID,
            order_id: this.estimateId,
            phone: this.profile.phone,
            return_url:
              environment.PAYMENT.RETURN_URL + "?estimateId=" + this.estimateId,
            webhook_url: environment.PAYMENT.WEBHOOK_URL,
            cancel_url: environment.PAYMENT.CANCEL_URL,
          };
          this.paymeeService.postPayment(payment).subscribe({
            next: (value: ResultPaymeeInterface) => {
              const payment: AdPaymentDetailsInterface = {
                justificationFile: null,
                paymentMethod: this.paymentMethod,
                paymentToken: value.data.token,
                campaignHashedId: this.campaignHashedID,
              };
              this.store.dispatch(
                invokeUpadateAdPaymentDetails({
                  payment: payment,
                })
              );
              window.location.href = value.data.payment_url;
            },
            error: (err: ErrorPostPaymeeInterface) => {
              this.toastr.error(
                err.details,
                this.translate.instant("response.errorTitle")
              );
            },
          });
          break;
        }*/
        case AdvertisingPaymentMethodEnum.KONNECT: {
          const payment: PostKonnectInterface = {
            receiverWalletId: environment.PAYMENT.APP_KONNECT.WALLET,
            amount: Math.floor(Number(this.totalCost.toFixed(3)) * 1000),
            email: this.profile.email.toLowerCase(),
            firstName: this.profile.firstName,
            lastName: this.profile.lastName,
            orderId: this.campaignHashedID,
            phoneNumber: this.profile.phone.toString(),
            token: "TND",
            type: "immediate",
            acceptedPaymentMethods: ["wallet", "bank_card", "e-DINAR"],
            lifespan: 20,
            checkoutForm: false,
            addPaymentFeesToAmount: false,
            silentWebhook: true,
            theme: "light",
            successUrl:
              environment.PAYMENT.RETURN_URL + "?estimateId=" + this.estimateId,
            webhook: environment.PAYMENT.WEBHOOK_URL,
            failUrl: environment.PAYMENT.CANCEL_URL,
          };
          this.konnectService.postPayment(payment).subscribe({
            next: (value: ResultKonnectInterface) => {
              const payment: AdPaymentDetailsInterface = {
                justificationFile: null,
                paymentMethod: this.paymentMethod,
                paymentToken: value.paymentRef,
                campaignHashedId: this.campaignHashedID,
              };
              this.store.dispatch(
                invokeUpadateAdPaymentDetails({
                  payment: payment,
                })
              );
              this.saveHistory.saveUserHistory(
                UserHistoryActionsEnum.POST,
                UserHistoryServicesEnum.PAYMENT_KONNECT
              );
              window.location.href = value.payUrl;
              this.loading = false;
            },
            error: (err: ErrorPostKonnectInterface) => {
              this.toastr.error(
                err.errors.map((value) => value.message).toString(),
                this.translate.instant("response.errorTitle")
              );
              this.loading = false;
            },
          });
          break;
        }
        case AdvertisingPaymentMethodEnum.BANK_TRANSFER: {
          this.router.navigate([
            "/",
            "dashboard",
            "advertiser",
            "advertising-campaigns",
            "payment-success",
          ]);
          this.saveHistory.saveUserHistory(
            UserHistoryActionsEnum.POST,
            UserHistoryServicesEnum.PAYMENT_BANK_TRANSFER
          );
          break;
        }
        case AdvertisingPaymentMethodEnum.NONE:
          break;
      }
    }
  }

  changeStatus() {
    console.log("this.adStatus", this.adStatus);
    if (this.adStatus == CampaignStatusEnum.DRAFT) {
      this.store.dispatch(
        invokeChangeAdStatus({
          campaignHashedId: this.campaignHashedID,
          status: CampaignStatusEnum.AWAITING_PAYMENT,
        })
      );
      this.adStatus = CampaignStatusEnum.AWAITING_PAYMENT;
    }
  }

  defaultIndex() {
    switch (this.isPublish) {
      case true: {
        if (this.objectiveName == ObjectiveTypeEnum.GENERATION_DE_PROSPECTS)
          return 3;
        else return 2;
      }
      case false:
        return 0;
    }
  }

  unMuteVideo() {
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: { ...this.adDetails, isMuted: false },
      })
    );
  }

  muteVideo() {
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: { ...this.adDetails, isMuted: true },
      })
    );
  }
}
