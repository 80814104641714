import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { OptionActionEnum } from "src/app/shared/data/actions-data";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { UpdateComponent } from "src/app/shared/components/drob-zone-options/modal/update/update.component";
import { TypeCards } from "src/app/shared/enum/typeCards";
import { Observable, takeUntil } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import { BaseComponent } from "src/app/base.component";
import { invokeCheckIfExistByCompanyName, invokeCheckIfExistByEmail, setCheckUserExistsByCompanyName, setCheckUserExistsByEmail } from "src/app/store/user/user.actions";
import { invokeSubCategoriesByCategory } from "src/app/store/subCategory/subCategory.actions";
import { allCategoryNamesSelector } from "src/app/store/category/category.selectors";
import { subCategoriesByCategoryNameSelector } from "src/app/store/subCategory/subCategory.selectors";
import { allTagNamesSelector } from "src/app/store/tag/tag.selectors";
import { invokeAllCategoryNames } from "src/app/store/category/category.actions";
import { invokeAllTagNames } from "src/app/store/tag/tag.actions";
import { setFileName } from "src/app/store/file/file.actions";
import { uploadFileSelector } from "src/app/store/file/file.selectors";
import { checkUserExistByCompanyNameSelector, checkUserExistByEmailSelector } from "src/app/store/user/user.selectors";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-update-location-partner",
  templateUrl: "./update-location-partner.component.html",
})
export class UpdateLocationPartnerComponent
  extends BaseComponent
  implements OnInit
{
  @Input() email: string;
  @Input() locationPartner: LocationPartnerInterface;
  @Input() selectedOption: OptionActionEnum;
  step = OptionActionEnum;
  companyInformation: FormGroup;
  personalInformation: FormGroup;
  companySpecification: FormGroup;
  locationInformation: FormGroup;
  partnerStatus: FormGroup;

  //category
  allCategoryNames$: Observable<string[] | null>;
  getCategoryNames: string[] | null;

  //subCategoryByCategory
  subCategoriesByCategory$: Observable<string[] | null>;
  getSubCategoriesByCategory: string[] | null;

  //tag
  allTagNames$: Observable<string[] | null>;
  getTagNames: string[];
  filename: string | null;
  uploadFile$: Observable<string | null>;
  checkLocationExistByCompanyName$ : Observable<boolean | null>
  checkLocationExistByEmail$ : Observable<boolean | null>
  checkLocationExistByCompanyName : boolean
  checkLocationExistByEmail : boolean
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    super(store);
    this.uploadFile$ = this.store
      .pipe(select(uploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.allCategoryNames$ = this.store
      .pipe(select(allCategoryNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.subCategoriesByCategory$ = this.store
      .pipe(select(subCategoriesByCategoryNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.checkLocationExistByCompanyName$ = this.store
      .pipe(select(checkUserExistByCompanyNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.checkLocationExistByEmail$ = this.store
      .pipe(select(checkUserExistByEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.allTagNames$ = this.store
      .pipe(select(allTagNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    (this.personalInformation = this.fb.group({
      email: new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
      ]),
      firstName: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
    })),
      (this.companyInformation = this.fb.group({
        companyName: new FormControl(null, [Validators.required]),
        companyAddress: new FormControl(null, [Validators.required]),
        companyActivity: new FormControl(null, [Validators.required]),
        phone: [
          null,
          [Validators.required, Validators.pattern("^(?=.*?[0-9]).{8}$")],
        ],
        companyRole: new FormControl(null, [Validators.required]),
      })),
      (this.companySpecification = this.fb.group({
        category: new FormControl(null, [Validators.required]),
        subCategories: new FormControl(null, [Validators.required]),
        tags: new FormControl(null, [Validators.required]),
      })),
      (this.locationInformation = this.fb.group({
        //address: new FormControl(null, [Validators.required]),
        latitude: new FormControl(null, [Validators.required]),
        longitude: new FormControl(null, [Validators.required]),
        zone: new FormControl(null, [Validators.required]),
      }));
    this.partnerStatus = this.fb.group({
      expenses: new FormControl(null),
      partnerStatus: new FormControl(null, [Validators.required]),
      date: new FormControl([null, null]),
      paymentFrequency: new FormControl(null),
      price: new FormControl(null),
      pricingMethod: new FormControl(null),
      showingAdmin: new FormControl(null),
      showingAdvertiser: new FormControl(null),
      status : new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    console.log("this.locationPartner", this.locationPartner)
    this.checkLocationExistByCompanyName$.subscribe((data)=>{
      if(data != null){
        this.checkLocationExistByCompanyName = data
        this.store.dispatch(setCheckUserExistsByCompanyName({isExistByCompanyName : null}))
        if(!data && !this.checkLocationExistByEmail){
          this.updateComponent();
        }
      }
    })
    this.checkLocationExistByEmail$.subscribe((data)=>{
      if(data != null){
        this.checkLocationExistByEmail = data
        this.store.dispatch(setCheckUserExistsByEmail({isExist : null}))
        if(data){
          this.toastr.error(
            this.translate.instant("response.error.email-exist"),
            this.translate.instant("response.errorTitle")
          );
        }
        if(!data && !this.checkLocationExistByCompanyName){
          this.updateComponent();
        }
      }
    })
    this.uploadFile$.subscribe((result: string | null) => {
      if (result != null) {
        this.filename = result;
        this.store.dispatch(setFileName({ filename: null }));
      }
    });
    this.allCategoryNames$.subscribe((result: string[] | null) => {
      if (result == null) {
        this.store.dispatch(invokeAllCategoryNames());
      }
    });

    this.allTagNames$.subscribe((result: string[] | null) => {
      if (result == null) {
        this.store.dispatch(invokeAllTagNames());
      }
    });

    this.subCategoriesByCategory$.subscribe((result: string[] | null) => {
      if (result != null) {
        this.getSubCategoriesByCategory = result;
      } else if (this.locationPartner.category) {
        this.store.dispatch(
          invokeSubCategoriesByCategory({
            categoryName: this.locationPartner.category,
          })
        );
      }
    });

    this.personalInformation.patchValue({
      email: this.locationPartner.email.toLowerCase(),
      firstName: this.locationPartner.firstName,
      lastName: this.locationPartner.lastName,
    });
    this.companyInformation.patchValue({
      companyName: this.locationPartner.companyName,
      companyAddress: this.locationPartner.companyAddress,
      companyActivity: this.locationPartner.companyActivity,
      phone: this.locationPartner.phone,
      companyRole: this.locationPartner.companyRole,
      logo: this.locationPartner.logo,
    });
    this.companySpecification.patchValue({
      category: this.locationPartner.category,
      subCategories: this.locationPartner.subCategories,
      tags: this.locationPartner.tags,
    });
    this.locationInformation.patchValue({
      latitude: this.locationPartner.latitude,
      longitude: this.locationPartner.longitude,
      zone: this.locationPartner.zoneName,
    });
    this.partnerStatus.patchValue({
      expenses: this.locationPartner.expenses,
      partnerStatus: this.locationPartner.partnerStatus,
      paymentFrequency: this.locationPartner.paymentFrequency,
      date: [
        this.locationPartner.pilotPhaseStartDate,
        this.locationPartner.pilotPhaseEndDate,
      ],
      price: this.locationPartner.price,
      pricingMethod: this.locationPartner.pricingMethod,
      showingAdmin: this.locationPartner.showingAdmin,
      showingAdvertiser: this.locationPartner.showingAdvertiser,
      status : this.locationPartner.status
    });
  }
  onClickCloseModal() {
    this.modalService.dismissAll();
  }
  onChangeCategory() {
    this.companySpecification.get("subCategories")?.reset([]);
    this.store.dispatch(
      invokeSubCategoriesByCategory({
        categoryName: this.companySpecification.get("category")?.value,
      })
    );
  }
  updateComponent() {
    this.locationPartner = {
      email: this.personalInformation.get("email")?.value.toLowerCase(),
      firstName: this.personalInformation.get("firstName")?.value,
      lastName: this.personalInformation.get("lastName")?.value,
      password: this.personalInformation.get("password")?.value,
      companyName: this.companyInformation.get("companyName")?.value,
      companyAddress: this.companyInformation.get("companyAddress")?.value,
      companyActivity: this.companyInformation.get("companyActivity")?.value,
      companyRole: this.companyInformation.get("companyRole")?.value,
      phone: this.companyInformation.get("phone")?.value,
      category: this.companySpecification.get("category")?.value,
      subCategories: this.companySpecification.get("subCategories")?.value,
      tags: this.companySpecification.get("tags")?.value,
      longitude: this.locationInformation.get("longitude")?.value,
      latitude: this.locationInformation.get("latitude")?.value,
      zoneName: this.locationInformation.get("zone")?.value,
      partnerStatus: this.partnerStatus.get("partnerStatus")?.value,
      paymentFrequency: this.partnerStatus.get("paymentFrequency")?.value,
      pricingMethod: this.partnerStatus.get("pricingMethod")?.value,
      price: this.partnerStatus.get("price")?.value,
      pilotPhaseStartDate: this.partnerStatus.get("date")?.value[0] ?? null,
      pilotPhaseEndDate: this.partnerStatus.get("date")?.value[1] ?? null,
      expenses: this.partnerStatus.get("expenses")?.value,
      showingAdmin: this.partnerStatus.get("showingAdmin")?.value,
      showingAdvertiser: this.partnerStatus.get("showingAdvertiser")?.value,
      logo: this.filename,
      endDateAverage: this.locationPartner.endDateAverage,
      startDateAverage: this.locationPartner.startDateAverage,
      premiumEndDate: this.locationPartner.premiumEndDate,
      region: this.locationPartner.region,
      status : this.partnerStatus.get("status")?.value
    }; 
    this.onClickCloseModal();
    const modalRef = this.modalService.open(UpdateComponent, {
      centered: true,
      size: "xl",
    });
    modalRef.componentInstance.type = TypeCards.ADMIN_LOCATION_UPDATE;
    modalRef.componentInstance.isCol4 = false;
    modalRef.componentInstance.title = "LPOptions.update";
    modalRef.componentInstance.email = this.email.toLowerCase();
    modalRef.componentInstance.locationPartner = this.locationPartner;
  }
  onClickBack() {
    this.updateComponent();
  }
  saveChanges() {
    this.confirmAction.emit(true)
    let isUpdateCompanyName = false
    let isUpdateEmail = false
    if(this.selectedOption == this.step.ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION && 
      this.locationPartner.companyName != this.companyInformation.get("companyName")?.value){
        isUpdateCompanyName = true
      this.store.dispatch(
        invokeCheckIfExistByCompanyName({ companyName: this.companyInformation.get("companyName")?.value })
      );
    }
    if (this.selectedOption ==this.step.ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION &&
      this.locationPartner.email.toLowerCase() != this.personalInformation.get("email")?.value.toLowerCase()
  ) {
      isUpdateEmail = true;
      this.store.dispatch(
        invokeCheckIfExistByEmail({
          email: this.personalInformation.get("email")?.value.toLowerCase(),
        })
      );
    }
    if(!isUpdateCompanyName && !isUpdateEmail){
      this.updateComponent();
    }
  }
  checkUserExist() {
    this.store.dispatch(
      invokeCheckIfExistByEmail({
        email: this.personalInformation.get("email")?.value.toLowerCase(),
      })
    );
  }

  isDisableButton() {
    switch (this.selectedOption) {
      case OptionActionEnum.ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION: {
        return !this.personalInformation.valid;
      }
      case OptionActionEnum.ADMIN_UPDATE_LOCATION_COMPANY_SPECIFICATION: {
        return !this.companySpecification.valid;
      }
      case OptionActionEnum.ADMIN_UPDATE_LOCATION_LOCATION_INFORMATION: {
        return !this.locationInformation.valid;
      }
      case OptionActionEnum.ADMIN_UPDATE_LOCATION_PARTNER_STATUS_INFORMATION: {
        return !this.partnerStatus.valid;
      }
      default:
        return;
    }
  }
}
