import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { catchError, map, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  constructor(
    public jwtHelper: JwtHelperService,
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {}

  //check validity
  checkValidity(): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${environment.APP_PORT + Constants.USER_ENDPOINT}/checkToken`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => of(false))
      );
  }
  // Check token expiration date
  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

  // Get the role name
  getRoleName(token: string | null) {
    if (token) {
      const tokenPayload = this.jwtHelper.decodeToken(token ?? "");
      return tokenPayload.role.authority;
    }
  }

  getCompanyName(): string {
    const token = this.localStorageService.getItem("token");
    const tokenPayload = this.jwtHelper.decodeToken(token ?? "");
    return tokenPayload.companyName;
  }

  // Decode the token to get its payload
  getTokenPayload(token: string): any {
    return this.jwtHelper.decodeToken(token);
  }

  getToken(): string {
    const token = this.localStorageService.getItem("token");
    return token;
  }

  getEmail(): string {
    const token = this.localStorageService.getItem("token");
    const tokenPayload = this.jwtHelper.decodeToken(token);
    return tokenPayload.email;
  }

  getPermissions(token: string | null) {
    if (token) {
      const tokenPayload = this.jwtHelper.decodeToken(token ?? "");
      const permissions: string[] = tokenPayload.permissions;
      // Supprimer le préfixe avant le premier point
      return permissions.map((permission) => {
        const parts = permission.split(".");
        return parts.length > 1 ? parts[1] : parts[0];
      });
    }
    return undefined;
  }
}
