import { Component, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { BrowserFamily } from "../../models/statistics/browserFamily";
import { setBrowserFamily } from "src/app/store/consumers/consumers.actions";
import { loadingConsumerSelector } from "src/app/store/consumers/consumers.selectors";
import { catchForbiddenError } from "src/app/store/user/user.actions";
import { catchForbiddenErrorSelector } from "src/app/store/user/user.selectors";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
})
export class ProgressBarComponent extends BaseComponent implements OnInit {
  @Input("resultbrowserFamily")
  resultbrowserFamily: Observable<BrowserFamily | null>;
  length = 0;
  percentage: number[] = [];
  result: number[] = [];
  keys: string[] = [];
  values: number[] = [];
  @Input() isLoading: boolean;
  catchForbiddenError$: Observable<boolean | null>;
  forbidden: boolean | null = false;
  constructor(private store: Store<AppStateInterface>) {
    super(store);
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnInit(): void {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.forbidden = true;
        this.isLoading = false;
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.store.dispatch(setBrowserFamily({ browserFamily: null }));
    this.resultbrowserFamily.subscribe((result) => {
      if (result != null) {
        this.isLoading = false;
        this.percentage = [];
        this.values = Object.values(result);
        this.length = this.values.reduce((acc, current) => acc + current, 0);
        const sortedEntries = Object.entries(result).sort(
          (a, b) => b[1] - a[1]
        );
        const objetTrie = sortedEntries.reduce(
          (acc: { [key: string]: number }, [key, value]: [string, any]) => {
            acc[key] = value;
            return acc;
          },
          {}
        );
        this.keys = Object.keys(objetTrie);

        Object.values(objetTrie).forEach((value) => {
          this.percentage.push((value / this.length) * 100);
        });
        this.percentage = [...new Set(this.percentage)];
      }
    });
  }
}
