import { Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-card-inseights",
  templateUrl: "./card-inseights.component.html",
})
export class CardInseightsComponent implements OnInit {
  @Input() isLoading: boolean | null;
  @Input() value!: number;
  @Input() icon = "";
  @Input() title = "";
  @Input() unit : number = 0;
  @Input() isPercentage: boolean = false;
  @Input() isFinished: boolean = false;
  @Input() isForbidden: boolean = false;
  isDashboard: boolean;
  constructor(private route: Router) {
    if (route.url == "/dashboard/advertiser/main") {
      this.isDashboard = true;
    }
  }
  ngOnInit(): void {}
}
