import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { DiscountTypeEnum } from "src/app/shared/enum/discount-type.enum";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { PromotionCodeLiteInterface } from "src/app/shared/models/promotion-code/check-promotion-code.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeCalculateTotalLPCostByCampanyNames } from "src/app/store/locationPartner/location-partner.action";
import { invokeAdHavePromotionCode } from "src/app/store/promotion-code/promotion-code.actions";
import { getAdHavePromotionCodeSelector } from "src/app/store/promotion-code/promotion-code.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-details-ad",
  templateUrl: "./details-ad.component.html",
})
export class DetailsAdComponent extends BaseComponent implements OnInit {
  @Input() detailsAd: AdvertisingCampaignInterface;
  cdnURL = environment.CDN_RELEAD;

  priceHT: number = 0;
  total: number = 0;
  tva: number = 0;
  discount: number = 0;

  promotionExists$: Observable<PromotionCodeLiteInterface | null>;
  promotionApplied: PromotionCodeLiteInterface | null = null;
  constructor(
    public badgeService: BadgeService,
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
    private cdr: ChangeDetectorRef
  ) {
    super(store);

    this.promotionExists$ = this.store
      .pipe(select(getAdHavePromotionCodeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnInit(): void {
    this.store.dispatch(
      invokeAdHavePromotionCode({
        havePromotion: this.detailsAd.advertisingCampaignHashedId,
      })
    );
    this.promotionExists$.subscribe((value) => {
      if (value && value?.discountValue != 0) {
        this.promotionApplied = value;
      }
    });

    if(this.detailsAd.totalHt) this.priceHT = this.detailsAd.totalHt
    this.tva = this.priceHT * 0.19;
    this.updateTotale();
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  updateTotale() {
    if (this.priceHT > 0) {
      this.total =
        Number(this.priceHT.toFixed(3)) + Number(this.tva.toFixed(3)) + 1;
    }
    switch (this.promotionApplied?.discountType) {
      case DiscountTypeEnum.AMOUNT: {
        this.discount = this.promotionApplied.discountValue;
        const newPriceHT = this.priceHT - this.promotionApplied.discountValue;
        this.tva = newPriceHT * 0.19;
        this.total =
          Number(newPriceHT.toFixed(3)) + Number(this.tva.toFixed(3)) + 1;
        break;
      }
      case DiscountTypeEnum.PERCENTAGE: {
        if (this.promotionApplied.discountValue != 0) {
          var percentageToCalculate: number =
            1 - this.promotionApplied.discountValue / 100;
          const newPriceHT = this.priceHT * percentageToCalculate;
          this.tva = newPriceHT * 0.19;
          this.total =
            Number(newPriceHT.toFixed(3)) + Number(this.tva.toFixed(3)) + 1;
          this.discount =
            this.priceHT * (this.promotionApplied.discountValue / 100);
        }
      }
    }
    this.cdr.detectChanges();
  }
}
