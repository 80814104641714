export enum LocationPartnerStatus {
  AVAILABLE = "AVAILABLE",
  CURRENTLY_INSTALLING = "CURRENTLY_INSTALLING",
  RESERVED = "RESERVED",
}

export enum LocationPartnerStatusEnumMapping {
  AVAILABLE = "enumMapping.LocationPartnerStatus.lowercase.AVAILABLE",
  CURRENTLY_INSTALLING = "enumMapping.LocationPartnerStatus.lowercase.CURRENTLY_INSTALLING",
  RESERVED = "enumMapping.LocationPartnerStatus.lowercase.RESERVED",
}
export interface LocationPartnerStatusInterface {
  id: LocationPartnerStatus;
  value: string;
}
