import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  getAdCompaignByHashedIdSelector,
  loadingAdvertisingCampaignSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { invokeAdvertisingCompaignByHashedId } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  globalStatisticsByAdHashedId,
  invokeGlobalStatisticsByAdHashedId,
} from "src/app/store/statistics/statistics.actions";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { GlobalStatistics } from "src/app/shared/models/statistics/global-statistics";
import { JwtService } from "src/app/shared/services/jwt.service";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import {
  advertiserProfileDetailsSelector,
  advertiserByAdHashedIdSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import {
  invokeAdvertiserByAdHashedId,
  invokeProfileDetails,
} from "src/app/store/advertiser/advertiser.actions";
import { environment } from "src/environments/environment";
import {
  ObjectiveTypeEnum,
  ObjectiveTypeEnumMapping,
} from "src/app/shared/enum/objectives";
import { InputAudience } from "src/app/shared/models/input-audience.interface";
import { downloadedFileSelector } from "src/app/store/file/file.selectors";
import {
  invokeDownloadFile,
  resultDownloadFile,
} from "src/app/store/file/file.actions";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { AudienceType } from "src/app/shared/enum/chart/audiance";
import { TechnicalCardSearchMethod } from "src/app/shared/models/statistics/technical-data";
import { TechnicalCardSearchMethodEnum } from "src/app/shared/enum/chart/technical-data";
import { globalStatisticsForAdSelector } from "src/app/store/statistics/statistics.selectors";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import {
  invokeSendRequestToDownloadLead,
  resultSindingMail,
} from "src/app/store/mailing/mailing.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import {
  loadingSendingEmailSelector,
  sendMailSelector,
} from "src/app/store/mailing/mailing.selectors";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { AdsLocationsComponent } from "src/app/shared/components/ads-locations/ads-locations.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectedLps } from "src/app/shared/models/location-partners/selectedLps";
import { saveSelectedLPsSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { catchForbiddenErrorSelector, catchServerErrorSelector } from "src/app/store/user/user.selectors";
import { catchForbiddenError, catchServerError } from "src/app/store/user/user.actions";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { selectedLPs } from "src/app/store/locationPartner/location-partner.action";
import { RedisOptions } from "src/app/shared/models/RedisOptions";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";

@Component({
  selector: "app-ad-statistics",
  templateUrl: "./ad-statistics.component.html",
  styleUrls: ["./ad-statistics.component.scss"],
})
export class AdStatisticsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Output() views = new EventEmitter<number>();
  @Output() isFinished: boolean = false;

  objectives = ObjectiveTypeEnum;
  objectiveTypeEnumMapping = ObjectiveTypeEnumMapping;

  @Output() videoUrl = new EventEmitter<string>();
  @Output() mute = new EventEmitter<boolean>();
  muting: boolean = false;
  statisticsLoading: boolean = true;
  roleEnum = RoleEnum;
  role: string;
  muteVideo() {
    this.muting = !this.muting;
    this.mute.emit();
  }
  formInsightcolumns: IColumn[] = [
    {
      key: "formDetails",
      label: "inseights.details.formInsights.formDetails",
      sorter: true,
    },
    {
      key: "firstName",
      label: "inseights.details.formInsights.firstName",
      sorter: true,
    },
    {
      key: "lastName",
      label: "inseights.details.formInsights.lastName",
      sorter: true,
    },
    {
      key: "email",
      label: "inseights.details.formInsights.email",
      sorter: true,
    },
    {
      key: "tel",
      label: "inseights.details.formInsights.phoneNumber",
      sorter: true,
    },
  ];

  campaignHashedId: string = "";
  titleCampaign: string = "";
  isAdFinished: boolean = false;
  @Output() search = new EventEmitter<TechnicalCardSearchMethod>();
  @Output() adTitle$ = new EventEmitter<string | null>();

  adCompaignByHashedId$: Observable<AdvertisingCampaignInterface | null>;
  adCompaign: AdvertisingCampaignInterface[] = [];

  @Output() locations = new EventEmitter<string[]>();
  impression = {
    value: 0,
    title: "inseights.details.engagementOverview.impressions",
    icon: "assets/identity/eye-icon.svg",
    unit: 0,
  };
  visionnage = {
    value: 0,
    title: "inseights.details.engagementOverview.viewing",
    icon: "assets/identity/eye-icon.svg",
    unit: 0,
  };
  uniqueViews = {
    value: 0,
    title: "inseights.details.engagementOverview.uniqueViews",
    icon: "assets/identity/uniqueViews.svg",
    unit: 0,
  };
  completed = {
    value: 0,
    title: "inseights.details.engagementOverview.engagement",
    icon: "assets/identity/time-icon.svg",
    unit: 0,
  };

  //taux de clicks
  clickThrowRate = {
    value: 0,
    title: "inseights.details.engagementOverview.clicks",
    icon: "assets/identity/click.svg",
    unit: 0,
  };
  prospect = {
    value: 0,
    title: "inseights.details.engagementOverview.prospect",
  };

  length: number;
  lenghtChrome: number;

  globalStatisticsByAd$: Observable<GlobalStatistics | null>;
  globalStatistics: GlobalStatistics;

  loading$: Observable<boolean | null>;
  objectiveName: string;

  @Output() inputAudience = new EventEmitter<InputAudience>();

  downloadFile$: Observable<any>;
  resultDownloadFile: any;
  fileName: string | null;

  adDetails$: Observable<AdvertisingCampaignInterface | null>;
  detailsAd: AdvertisingCampaignInterface;

  profileDetails$: Observable<AdvertiserDetailsInterface | null>;
  advertiser: AdvertiserDetailsInterface;
  advertiser$: Observable<AdvertiserDetailsInterface | null>;
  fullName: string = "";
  AdvertiserCompanyName: string = "";

  showButtonDesc: boolean = false;

  resultSendRequestDownloadForm$: Observable<SuccessDto | null>;
  resultSendRequestDownloadForm: string;
  loadingSendingEmail$: Observable<boolean | null>;
  loadingSendingEmail: boolean;
  saveSelectedLP$: Observable<SelectedLps | null>;
  selectedLp: string;
  blob: Blob;
  catchServerErreur$: Observable<boolean | null>;
  redisOptions: RedisOptions = {
    isFinished: false,
    isRefreshCache: false,
  };
  cachedAt: Date | null;

  catchForbiddenError$: Observable<boolean | null>;
  forbidden : boolean = false
  loadingProfile : boolean | null = false
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private jwt: JwtService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private extensionService: ExtensionService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.campaignHashedId = this.route.snapshot.params["campaignHasedID"];
    /*if (title == null) this.router.navigate(["/dashboard/params"]);
    else this.campaignHashedId = title;*/

    this.adCompaignByHashedId$ = this.store
      .pipe(select(getAdCompaignByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.globalStatisticsByAd$ = this.store
      .pipe(select(globalStatisticsForAdSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.downloadFile$ = this.store
      .pipe(select(downloadedFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.profileDetails$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.advertiser$ = this.store
      .pipe(select(advertiserByAdHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSendRequestDownloadForm$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingSendingEmail$ = this.store
      .pipe(select(loadingSendingEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.saveSelectedLP$ = this.store
      .pipe(select(saveSelectedLPsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerErreur$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.store.dispatch(
      selectedLPs({
        selectedLps: null,
      })
    );
  }
  ngAfterViewInit(): void {
    this.inputAudience.emit({
      name: this.campaignHashedId,
      type: AudienceType.INSIGHTS,
      title: "inseights.details.audiences.title",
      titleSpline: "inseights.details.audiences.summary",
      titleColumnChart: "inseights.details.audiences.distribution",
    });
    this.adCompaignByHashedId$.subscribe((result) => {
      if (result) {
        this.isAdFinished = result?.status == CampaignStatusEnum.FINISHED;
        this.search.emit({
          technicalCard: TechnicalCardSearchMethodEnum.ADVERTISING_CAMPAIGN,
          value: this.campaignHashedId ?? "",
          locationPartner: this.selectedLp,
          finished: this.isAdFinished,
        });
      }
    });
  }
  ngOnInit(): void {
    this.loading$.subscribe((result)=>{ this.loadingProfile = result})
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.forbidden = true;
        this.loadingProfile = false;
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.store.dispatch(resultSindingMail({ successMessage: null }));
    this.role = this.jwt.getRoleName(this.jwt.getToken());
    switch (this.role) {
      case RoleEnum.ROLE_ADVERTISER: {
        this.store.dispatch(invokeProfileDetails());
        break;
      }
      case RoleEnum.ROLE_ADMIN: {
        this.store.dispatch(
          invokeAdvertiserByAdHashedId({
            campaignHashedId: this.campaignHashedId,
          })
        );
        break;
      }
    }

    this.advertiser$.subscribe((result: AdvertiserDetailsInterface | null) => {
      if (result && this.role == RoleEnum.ROLE_ADMIN) {
        this.fullName = result.firstName + " " + result.lastName ?? "";
        this.AdvertiserCompanyName = result.companyName;
      }
    });
    this.profileDetails$.subscribe(
      (result: AdvertiserDetailsInterface | null) => {
        if (result != null && this.role == RoleEnum.ROLE_ADVERTISER) {
          this.fullName = result.firstName + " " + result.lastName ?? "";
          this.AdvertiserCompanyName = result.companyName;
          this.advertiser = result;
        }
      }
    );
    this.store.dispatch(
      invokeAdvertisingCompaignByHashedId({
        campaignHashedId: this.campaignHashedId,
      })
    );
    this.adCompaignByHashedId$.subscribe(
      (result: AdvertisingCampaignInterface | null) => {
        if (
          result != null &&
          result.advertisingCampaignHashedId == this.campaignHashedId
        ) {
          this.isFinished = this.isShowGrothIndex(
            result.endingDate,
            result.status,
            result.startingDate
          );
          this.titleCampaign = result.title;
          this.objectiveName = result.objectiveName;
          this.fileName = result.synthesisReport;
          this.videoUrl.emit(environment.CDN_RELEAD + result.videoLink);
          this.locations.emit(result.locationPartners);
          this.adCompaign.push(result);
          if (this.adCompaign.length > 1) {
            this.adCompaign.shift();
          }
        }
      }
    );
    this.saveSelectedLP$.subscribe((data) => {
      if (data) {
        this.selectedLp = data.companyNames[0];
        this.adCompaignByHashedId$.subscribe((result) => {
          if (result) {
            this.isAdFinished = result?.status == CampaignStatusEnum.FINISHED;
            this.fetchGlobalStatistics(false);
          }
        });
      }
      this.statisticsLoading = true;
      this.search.emit({
        technicalCard: TechnicalCardSearchMethodEnum.ADVERTISING_CAMPAIGN,
        value: this.campaignHashedId ?? "",
        locationPartner: this.selectedLp,
        finished: this.isAdFinished,
      });
    });
    this.store.dispatch(
      globalStatisticsByAdHashedId({ globalStatistics: null })
    );
    this.globalStatisticsByAd$.subscribe((result: GlobalStatistics | null) => {
      if (result != null) {
        this.cachedAt = result.cachedAt;
        this.statisticsLoading = false;
        this.globalStatistics = result;
        this.impression.value = result.views;
        this.impression.unit = Math.trunc(result.viewsChanges);

        this.uniqueViews.value = result.uniqueViews;
        this.uniqueViews.unit = Math.trunc(result.uniqueViewsChanges);

        this.completed.value = result.completed;
        this.completed.unit = Math.trunc(result.completedChanges);

        if (result.views != 0) {
          this.clickThrowRate.value = parseFloat(
            ((result.clicks / result.views) * 100).toFixed(3)
          );
        }
        this.clickThrowRate.unit = Math.trunc(result.clicksYesterday);
        /*this.completed.value = parseFloat(
          ((result.completed / result.views) * 100).toFixed(3)
        );*/
        this.views.emit(result.views);
      } else {
        this.statisticsLoading = true;
        this.adCompaignByHashedId$.subscribe((result) => {
          if (result) {
            this.isAdFinished = result.status == CampaignStatusEnum.FINISHED;
            this.fetchGlobalStatistics(false);
          }
        });
      }
    });
    this.downloadFile$.subscribe((result) => {
      if (result) {
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${
          this.titleCampaign
        }.${this.extensionService.getExtension(result.url)}`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
        this.store.dispatch(resultDownloadFile({ downloadedFile: null }));
      }
    });
    this.catchServerErreur$.subscribe((result) => {
      if (result) {
        this.loadingSendingEmail = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.loadingSendingEmail$.subscribe((data) => {
      if (data) this.loadingSendingEmail = data;
    });
    this.resultSendRequestDownloadForm$.subscribe(
      (result: SuccessDto | null) => {
        if (result != null) {
          this.resultSendRequestDownloadForm = result.message;
          if (this.resultSendRequestDownloadForm != "") {
            this.toastr.success(
              this.translate.instant(
                "response.success." + this.resultSendRequestDownloadForm
              ),
              this.translate.instant("response.successTitle")
            );
            this.store.dispatch(resultSindingMail({ successMessage: null }));
          }
        }
      }
    );
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.ADVERTISER_CAMPAIGNS_STATISTICS
    );
  }
  isShowGrothIndex(
    endingDate: Date | null,
    status: CampaignStatusEnum,
    startingDate: Date
  ): boolean {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);
    const comparisonDate = endingDate ?? currentDate;
    if (
      comparisonDate <= yesterday ||
      status != CampaignStatusEnum.ONGOING ||
      yesterday <= new Date(startingDate)
    ) {
      return true;
    } else {
      return false;
    }
  }
  onClickDownloadSynthesisReport() {
    if (this.isFinished) {
      if (this.fileName == null) {
        this.toastr.error(
          this.translate.instant("inseights.details.noSummaryReport"),
          this.translate.instant("response.errorTitle")
        );
      } else {
        this.store.dispatch(invokeDownloadFile({ filename: this.fileName }));
      }
    } else {
      this.toastr.warning(
        this.translate.instant("inseights.details.summaryReportIfNotFinish"),
        this.translate.instant("response.warningTitle")
      );
    }
  }

  sendRequestDownloadLeadToAdmin() {
    this.store.dispatch(
      invokeSendRequestToDownloadLead({ advertiser: this.advertiser })
    );
  }

  onClickShowLocations() {
    this.modalService.open(AdsLocationsComponent, { centered: true });
  }

  fetchGlobalStatistics(refreshCache: boolean) {
    this.statisticsLoading = true;
    this.store.dispatch(
      invokeGlobalStatisticsByAdHashedId({
        campaignHashedId: this.campaignHashedId,
        refreshCache: refreshCache,
        finished: this.isAdFinished,
        locationPartner: this.selectedLp,
      })
    );
  }
}
