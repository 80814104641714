import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { invokeDeleteAdvertiser } from "src/app/store/advertiser/advertiser.actions";
import { advertiserAdvetisingCampaignsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeGetAdvertiserAdvetisingCampaigns,
  resultAdvertiserAdvetisingCampaigns,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { catchForbiddenError, catchServerError } from "src/app/store/user/user.actions";
import {
  catchForbiddenErrorSelector,
  catchServerErrorSelector,
} from "src/app/store/user/user.selectors";

@Component({
  selector: "app-delete-advertiser",
  templateUrl: "./delete-advertiser.component.html",
})
export class DeleteAdvertiserComponent extends BaseComponent implements OnInit {
  @Input() email: string;

  advertiserAdvetisingCampaigns$: Observable<string[] | null>;

  havingAds: boolean = true;

  loading: boolean = false;

  catchServerError$: Observable<boolean | null>;
  catchForbiddenError$: Observable<boolean | null>;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerError$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.advertiserAdvetisingCampaigns$ = this.store
      .pipe(select(advertiserAdvetisingCampaignsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit() {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.closeModal();
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.catchServerError$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.closeModal();
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    if (this.email)
      this.store.dispatch(
        invokeGetAdvertiserAdvetisingCampaigns({ email: this.email })
      );
    this.advertiserAdvetisingCampaigns$.subscribe((ads) => {
      if (ads != null) {
        ads.length != 0 ? (this.havingAds = true) : (this.havingAds = false);
        this.store.dispatch(
          resultAdvertiserAdvetisingCampaigns({ advertisingCamapigns: null })
        );
      }
    });
  }

  onClickDelete() {
    this.confirmAction.emit(true)
    this.loading = true;

    if (!this.havingAds) {
      this.store.dispatch(invokeDeleteAdvertiser({ email: this.email }));
      this.saveHistory.saveUserHistory(
        UserHistoryActionsEnum.DELETE,
        UserHistoryServicesEnum.DELETE_ADVERTISER
      );
    }
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
