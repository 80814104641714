import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Data } from "../../models/iberis/commande/data";
import { ActionReturned, OptionActionEnum } from "../../data/actions-data";
import { createEstimate } from "../../models/iberis/commande/createEstimate";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import {
  invokeUpdateEstimate,
  resultUpdateEstimate,
} from "src/app/store/commande/commande.actions";
import { CommonParamsIberis } from "../../models/iberis/paramsForGetAll";
import { AppStateInterface } from "src/app/store/appState.interface";
import { select, Store } from "@ngrx/store";
import { CampaignStatusEnum } from "../../enum/campaign-status";
import { ResultCreateEstimate } from "../../models/iberis/commande/resultCreateEstimate";
import { Observable, takeUntil } from "rxjs";
import { resultUpdateEstimateSelector } from "src/app/store/commande/commande.selectors";
import { invokeDeleteAdCampaign } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AdvertisingCampaignInterface } from "../../models/advertising-campaigns/advertising-campaign.interface";
import { BaseComponent } from "src/app/base.component";
import { SaveUserHistoryService } from "../history/saveHistory";
import { UserHistoryActionsEnum } from "../../enum/userHistoryActions";
import { UserHistoryServicesEnum } from "../../enum/userHistoryService";

@Injectable({
  providedIn: "root",
})
export class CancelEstimate extends BaseComponent {
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  resultUpdateEstimate$: Observable<ResultCreateEstimate | null>;
  currentAd: AdvertisingCampaignInterface;
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private store: Store<AppStateInterface>,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.resultUpdateEstimate$ = this.store
      .pipe(select(resultUpdateEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  cancelEstimate(
    estimateDetails: Data,
    currentAd: AdvertisingCampaignInterface,
    currentAction: OptionActionEnum
  ) {
    let conditions: string = estimateDetails.conditions;
    if (currentAction == OptionActionEnum.CAMPAIGN_DELETE) {
      conditions =
        estimateDetails.conditions +
        "\nCe devis a été automatiquement rejeté car l'annonce qui lui était associée a été supprimée.";
    }
    const estimate: createEstimate = {
      bank_id: environment.IBERIS.BANK_ID,
      choice: 5,
      estimate_number:
        estimateDetails.estimate_number.replace("DV-", "").split("-").pop() ||
        "",
      client_id: estimateDetails.contact.hashed_id,
      additional_contact_id: estimateDetails.hashed_contact_additional_id,
      conditions: conditions,
      currency_rate: estimateDetails.currency_rate,
      date: this.datePipe.transform(estimateDetails.date, "yyyy-MM-dd"),
      due: this.datePipe.transform(estimateDetails.due, "yyyy-MM-dd"),
      language: estimateDetails.language,
      notes: estimateDetails.notes,
      object: estimateDetails.object,
      reference: estimateDetails.reference_number,
      use_conditions: 0,
      show_stamp: 1,
      show_billing: 1,
      show_delivery: 0,
      show_bank: 1,
      show_conditions: 1,
      show_description: 1,
      show_pictures: 1,
      tax_type: 1,
      show_unity: 1,
      useInput: {
        input_hashed_id: environment.IBERIS.TIMBRE_FISCAL_HASHED_ID,
        value: 1,
      },
      discount: "",
      items: {
        description: estimateDetails.items[0].description,
        discountAmount: Number(estimateDetails.items[0].discount_rate),
        discountType: estimateDetails.items[0].discount_type,
        item: estimateDetails.items[0].item,
        price: Number(estimateDetails.items[0].rate),
        qte: estimateDetails.items[0].quantity,
        taxes: [{ tax_hashed_id: environment.IBERIS.TAX_HASHED_ID }],
      },
    };
    this.store.dispatch(
      invokeUpdateEstimate({
        params: this.params,
        estimateId: currentAd.estimateId,
        createEstimate: estimate,
      })
    );
  }
}
