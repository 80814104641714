export enum PricingMethod {
  VIEWS = "VIEWS",
  UNIQUE_VIEWS = "UNIQUE_VIEWS",
  NONE = "NONE",
}

export const PricingMethodEnumMapping: Record<PricingMethod, string> = {
  [PricingMethod.VIEWS]: "enumMapping.paymentMethode.lowercase.VIEWS",
  [PricingMethod.UNIQUE_VIEWS]: "enumMapping.paymentMethode.lowercase.UNIQUE_VIEWS",
  [PricingMethod.NONE]: "enumMapping.paymentMethode.lowercase.NONE",
};

export interface PricingMethodInterface {
  id: PricingMethod;
  value: string;
}
