<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: center">
    {{ "register.personalInformations.title" | translate }}
  </h5>
  <div class="form-group">
    <div class="small-group">
      <label
        >{{ "register.personalInformations.firstName" | translate }} *</label
      >
      <label style="margin-left: 36%"
        >{{ "register.personalInformations.lastName" | translate }}*</label
      >
    </div>
    <div class="small-group">
      <div class="input-group">
        <span class="input-group-text">
          <img src="assets/identity/name.png" alt="icone name" />
        </span>
        <input
          formControlName="firstName"
          class="form-control"
          onlyAlphabets
          type="text"
          required="required"
          [placeholder]="
            'register.personalInformations.placeholder.firstName' | translate
          "
          [ngClass]="{
            'is-invalid':
              !form.get('firstName')?.valid && form.get('firstName')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            !form.get('firstName')?.valid && form.get('firstName')?.touched
          "
        >
          {{
            "register.personalInformations.errorMessage.firstNameLength"
              | translate
          }}
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-text">
          <img src="assets/identity/name.png" alt="icone name" />
        </span>
        <input
          formControlName="lastName"
          class="form-control"
          type="text"
          onlyAlphabets
          required="required"
          [placeholder]="
            'register.personalInformations.placeholder.lastName' | translate
          "
          [ngClass]="{
            'is-invalid':
              !form.get('lastName')?.valid && form.get('lastName')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="!form.get('lastName')?.valid && form.get('lastName')?.touched"
        >
          {{
            "register.personalInformations.errorMessage.lastNameLength"
              | translate
          }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label> {{ "authentication.email" | translate }} *</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/email.png" alt="icone email" />
      </span>
      <input
        formControlName="email"
        class="form-control"
        type="email"
        autocomplete="email"
        required
        [placeholder]="'authentication.placeholder.email' | translate"
        [ngClass]="{
          'is-invalid': !form.get('email')?.valid && form.get('email')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('email')?.valid && form.get('email')?.touched"
      >
        {{ "authentication.errorMessage.emailInvalid" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label> {{ "register.personalInformations.phone" | translate }} *</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/phone.svg" alt="icone phone" />
      </span>
      <input
        formControlName="phone"
        class="form-control m-input digits"
        type="text"
        [mask]="'99 999 999'"
        required=""
        [placeholder]="
          'register.personalInformations.placeholder.phone' | translate
        "
        [ngClass]="{
          'is-invalid':
            !form.get('phone')?.valid && form.get('phone')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('phone')?.valid && form.get('phone')?.touched"
      >
        {{ "register.personalInformations.errorMessage.phoneRequired" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label> {{ "authentication.password" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="password"
        class="form-control"
        [type]="showPassword ? 'text' : 'password'"
        required=""
        [placeholder]="'authentication.placeholder.password' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('password')?.valid && form.get('password')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('password')?.valid && form.get('password')?.touched"
      >
        {{ "authentication.errorMessage.passwordInvalid" | translate }}
        <ul style="padding-left: 10px">
          <li>- {{ "password.pattern.validator1" | translate }}</li>
          <li>- {{ "password.pattern.validator2" | translate }}</li>
          <li>- {{ "password.pattern.validator3" | translate }}</li>
        </ul>
      </div>
      <div
        class="show-hide"
        (click)="clickShowPassword()"
        *ngIf="!showPassword"
      >
        <span> {{ "password.show" | translate }} </span>
      </div>
      <div class="show-hide" (click)="clickShowPassword()" *ngIf="showPassword">
        <span>{{ "password.cash" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label> {{ "authentication.confirmPassword" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="confirmPassword"
        class="form-control"
        [type]="showConfirmPassword ? 'text' : 'password'"
        required=""
        [placeholder]="'authentication.placeholder.confirmPassword' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('confirmPassword')?.valid &&
            form.get('confirmPassword')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('confirmPassword')?.valid &&
          form.get('confirmPassword')?.touched
        "
      >
        {{ "authentication.errorMessage.passwordInvalid" | translate }}
        <ul style="padding-left: 10px">
          <li>- {{ "password.pattern.validator1" | translate }}</li>
          <li>- {{ "password.pattern.validator2" | translate }}</li>
          <li>- {{ "password.pattern.validator3" | translate }}</li>
        </ul>
      </div>
      <div
        class="show-hide"
        (click)="clickShowConfirmPassword()"
        *ngIf="!showConfirmPassword"
      >
        <span> {{ "password.show" | translate }} </span>
      </div>
      <div
        class="show-hide"
        (click)="clickShowConfirmPassword()"
        *ngIf="showConfirmPassword"
      >
        <span>{{ "password.cash" | translate }}</span>
      </div>
    </div>
  </div>
</form>
