import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { OptionActionInterface } from "src/app/shared/models/option-action.interface";
import { MustMatch } from "src/app/shared/validators/passwordMatch";
import { AppStateInterface } from "src/app/store/appState.interface";
import { locationPartnerCreatedSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { updateFieldsSelector } from "src/app/store/settings/settings.selectors";
import { PricingMethod } from "src/app/shared/enum/pricing-method";
import { CreateLPInterface } from "src/app/shared/models/location-partners/createLP";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import {
  invokeCreateSupplierInIberis,
  resultCreateSupplier,
} from "src/app/store/suppliers/suppliers.actions";
import { ResultCreateSupplier } from "src/app/shared/models/iberis/supplier/resultCreateSupplier";
import { resultCreateSupplierSelector } from "src/app/store/suppliers/suppliers.selectors";
import { getSubCategoriesByCategory } from "src/app/store/subCategory/subCategory.actions";
import { OptionActionEnum } from "src/app/shared/data/actions-data";
import { environment } from "src/environments/environment";
import {
  createLocationPartnerSuccess,
  invokeCreateLocationPartner,
} from "src/app/store/locationPartner/location-partner.action";
import { setFileName } from "src/app/store/file/file.actions";
import { uploadFileSelector } from "src/app/store/file/file.selectors";
import {
  invokeCheckIfExistByCompanyName,
  invokeCheckIfExistByEmail,
  setCheckUserExistsByCompanyName,
  setCheckUserExistsByEmail,
} from "src/app/store/user/user.actions";
import {
  checkUserExistByCompanyNameSelector,
  checkUserExistByEmailSelector,
} from "src/app/store/user/user.selectors";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { WizardComponent } from "angular-archwizard";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
@Component({
  selector: "app-create-location-partner",
  templateUrl: "./create-location-partner.component.html",
})
export class CreateLocationPartnerComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild("wizard") wizard: WizardComponent;
  locationPartnerForm: FormGroup;
  createLocationPartner$: Observable<SuccessDto | null>;
  resultCreateLocationPartner: string | null;
  update: OptionActionInterface = {
    name: [],
    icon: [],
    optionSelected: null,
    title: "",
  };
  updateFields$: Observable<OptionActionInterface | null>;
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  createSupplier: any;
  createSupplier$: Observable<ResultCreateSupplier | null>;
  supplierCreated: ResultCreateSupplier | null;
  @Input() closeModal$: Observable<boolean>;

  /** */
  @Input() selectedOption: OptionActionEnum;

  filename: string | null = null;
  uploadFile$: Observable<string | null>;

  isExistByEmail$: Observable<boolean | null>;
  isExistByCompanyName$: Observable<boolean | null>;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private store: Store<AppStateInterface>,
    private translate: TranslateService,
    private toastr: ToastrService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.uploadFile$ = this.store
      .pipe(select(uploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.createLocationPartner$ = this.store
      .pipe(select(locationPartnerCreatedSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.updateFields$ = this.store
      .pipe(select(updateFieldsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.createSupplier$ = this.store
      .pipe(select(resultCreateSupplierSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isExistByCompanyName$ = this.store
      .pipe(select(checkUserExistByCompanyNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isExistByEmail$ = this.store
      .pipe(select(checkUserExistByEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnerForm = this.fb.group({
      personalInformation: this.fb.group(
        {
          firstname: new FormControl(null, [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
          ]),
          lastname: new FormControl(null, [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
          ]),
          email: new FormControl(null, [
            Validators.required,
            Validators.email,
            Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
          ]),
          password: new FormControl(null, [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ]),
          confirmPassword: new FormControl(null, [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ]),
        },
        {
          validator: MustMatch("password", "confirmPassword"),
        }
      ),
      companyInformation: this.fb.group({
        companyName: new FormControl(null, [Validators.required]),
        companyAddress: new FormControl(null, [Validators.required]),
        companyActivity: new FormControl(null, [Validators.required]),
        phone: [
          null,
          [Validators.required, Validators.pattern("^(?=.*?[0-9]).{8}$")],
        ],
        companyRole: new FormControl(null, [Validators.required]),
      }),
      companySpecification: this.fb.group({
        category: new FormControl(null, [Validators.required]),
        subCategories: new FormControl(null, [Validators.required]),
        tags: new FormControl([], [Validators.required]),
      }),
      locationInformation: this.fb.group({
        //address: new FormControl(null, [Validators.required]),
        latitude: new FormControl(null, [Validators.required]),
        longitude: new FormControl(null, [Validators.required]),
        zone: new FormControl(null, [Validators.required]),
        partnerStatus: new FormControl(null, [Validators.required]),
        date: new FormControl([null, null]),
        paymentFrequency: new FormControl(null),
      }),
    });
  }
  ngOnInit(): void {
    this.isExistByEmail$.subscribe((data) => {
      if (data == false) {
        this.wizard.goToNextStep();
      }
      if (data) {
        this.toastr.error(
          this.translate.instant("response.error.email-exist"),
          this.translate.instant("response.errorTitle")
        );
      }
      this.store.dispatch(setCheckUserExistsByEmail({ isExist: null }));
    });
    this.isExistByCompanyName$.subscribe((data) => {
      if (data == false) {
        this.wizard.goToNextStep();
      }
      this.store.dispatch(
        setCheckUserExistsByCompanyName({ isExistByCompanyName: null })
      );
    });
    this.uploadFile$.subscribe((result: string | null) => {
      if (result != null) {
        this.filename = result;
        this.store.dispatch(setFileName({ filename: null }));
      }
    });
    this.store.dispatch(
      getSubCategoriesByCategory({ subCategoriesByCategoryName: null })
    );

    this.createLocationPartner$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.resultCreateLocationPartner = result.message;
        this.store.dispatch(
          createLocationPartnerSuccess({ successMessage: null })
        );
      }
    });
    this.updateFields$.subscribe((result) => {
      if (result) {
        this.update = result;
      }
    });
    this.createSupplier$.subscribe((result: ResultCreateSupplier | null) => {
      if (result) {
        let location: CreateLPInterface = {
          firstName: this.locationPartnerForm.get(
            "personalInformation.firstname"
          )?.value,
          lastName: this.locationPartnerForm.get("personalInformation.lastname")
            ?.value,
          email: this.locationPartnerForm.get("personalInformation.email")
            ?.value.toLowerCase(),
          password: this.locationPartnerForm.get("personalInformation.password")
            ?.value,
          companyActivity: this.locationPartnerForm.get(
            "companyInformation.companyActivity"
          )?.value,
          companyAddress: this.locationPartnerForm.get(
            "companyInformation.companyAddress"
          )?.value,
          companyName: this.locationPartnerForm.get(
            "companyInformation.companyName"
          )?.value,
          phone: this.locationPartnerForm.get("companyInformation.phone")
            ?.value,

          latitude: this.locationPartnerForm.get("locationInformation.latitude")
            ?.value,
          longitude: this.locationPartnerForm.get(
            "locationInformation.longitude"
          )?.value,
          zoneName: this.locationPartnerForm.get("locationInformation.zone")
            ?.value,
          partnerStatus: this.locationPartnerForm.get(
            "locationInformation.partnerStatus"
          )?.value,
          paymentFrequency: this.locationPartnerForm.get(
            "locationInformation.paymentFrequency"
          )?.value,
          pilotPhaseStartDate:
            this.locationPartnerForm.get("locationInformation.date")
              ?.value[0] ?? null,
          pilotPhaseEndDate:
            this.locationPartnerForm.get("locationInformation.date")
              ?.value[1] ?? null,
          subCategories: this.locationPartnerForm.get(
            "companySpecification.subCategories"
          )?.value,
          tags: this.locationPartnerForm.get("companySpecification.tags")
            ?.value,
          logo: this.filename,
          price: 0,
          pricingMethod: PricingMethod.NONE,
          roleName: RoleEnum.ROLE_LOCATION,
          subRole: "Manager",
          iberis: result.data.provider.hashed_id,
          companyRole: this.locationPartnerForm.get(
            "companyInformation.companyRole"
          )?.value,
        };

        this.store.dispatch(
          invokeCreateLocationPartner({ createLocationPartner: location })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.POST,
          UserHistoryServicesEnum.CREATE_LOCATIONS
        );
        this.store.dispatch(
          resultCreateSupplier({ resultCreateSupplier: null })
        );
        const modal = this.modalService.dismissAll(
          CreateLocationPartnerComponent
        );
      }
    });
  }
  onClickDismissAll() {
    this.modalService.dismissAll();
  }
  clearForm() {
    this.locationPartnerForm.reset();
    this.modalService.dismissAll();
  }
  submit() {
    this.confirmAction.emit(true)
    this.createSupplier = {
      email: this.locationPartnerForm.get("personalInformation.email")?.value.toLowerCase(),
      first_name: this.locationPartnerForm.get("personalInformation.firstname")
        ?.value,
      last_name: this.locationPartnerForm.get("personalInformation.lastname")
        ?.value,
      company: this.locationPartnerForm.get("companyInformation.companyName")
        ?.value,
      display_name: 2,
      phone: this.locationPartnerForm.get("companyInformation.phone")?.value,
      activity_id: this.locationPartnerForm.get(
        "companyInformation.companyActivity"
      )?.value,
      currency_id: "rorJ",
      title: 1,
      price_list_id: environment.IBERIS.PRICE_ID,
      deadline_id: environment.IBERIS.DEADLINE_ID,
    };
    this.store.dispatch(
      invokeCreateSupplierInIberis({
        params: this.params,
        createSupplier: this.createSupplier,
      })
    );
  }
  verifyEmail() {
    this.store.dispatch(
      invokeCheckIfExistByEmail({
        email: this.locationPartnerForm.get("personalInformation.email")?.value.toLowerCase(),
      })
    );
  }
  verifyCompanyName() {
    this.store.dispatch(
      invokeCheckIfExistByCompanyName({
        companyName: this.locationPartnerForm.get(
          "companyInformation.companyName"
        )?.value,
      })
    );
  }
  showModal(simpleContent: TemplateRef<any>) {
    const modalRef = this.modalService.open(simpleContent);
  }
}
