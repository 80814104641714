import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { BaseComponent } from "src/app/base.component";
import { GrantPremiumAccess } from "src/app/shared/models/location-partners/GrantPremiumAccess";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeGrantPremiumAccessForLP } from "src/app/store/locationPartner/location-partner.action";

@Component({
  selector: "app-premium-access",
  templateUrl: "./premium-access.component.html",
})
export class PremiumAccessComponent extends BaseComponent implements OnInit {
  @Input() locationPartner: LocationPartnerInterface;
  @Input() title: string;
  grantPremiumAccess: GrantPremiumAccess = {
    companyName: "",
    endDatePremium: null,
  };
  form: FormGroup;
  today = new Date();
  submitted: boolean = false;
  date : number | null = null
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
    private fb: FormBuilder
  ) {
    super(store);
    this.form = this.fb.group({
      date: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.form.patchValue({ date: this.locationPartner.premiumEndDate });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  selectDate() {
    this.date = new Date(
      new Date(this.form.get("date")?.value).setHours(23)
    ).setMinutes(59);
    this.grantPremiumAccess = {
      companyName: this.locationPartner.companyName,
      endDatePremium: new Date(this.date),
    };
  }
  onClickUpdateStatus() {
    this.submitted = true;
    this.confirmAction.emit(true)
    if (this.grantPremiumAccess.endDatePremium != null) {
      this.store.dispatch(
        invokeGrantPremiumAccessForLP({
          grantPremiumAccess: this.grantPremiumAccess,
        })
      );
      this.closeModal();
    }
  }
}
