import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { RouterInterface } from "src/app/shared/models/router/router.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeGetRouterByMac,
  invokeIsExistByMac,
  invokeRouterFilter,
  resultIsExistByMac,
} from "src/app/store/router/router.action";
import { locationPartnersComapyNamesSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { invokeCreateRouter } from "src/app/store/router/router.action";
import {
  loadingRouterSelector,
  resultRouterExistByMacSelector,
  routerByMacSelector,
  routerSuccessMessageSelector,
} from "src/app/store/router/router.selectors";
import {
  RouterStatus,
  RouterStatusInterface,
  RouterStatusToShow,
} from "src/app/shared/enum/router-status";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { RouterType } from "src/app/shared/enum/routerType";
import { RoutersFilterInterface } from "src/app/shared/models/router/routersFilter.interface";
import { initialState } from "src/app/store/router/router.reducers";
import { invokeLocationPartnersCompanyNames } from "src/app/store/locationPartner/actions/get-locations.actions";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { WizardComponent } from "angular-archwizard";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { catchForbiddenError } from "src/app/store/user/user.actions";
import { catchForbiddenErrorSelector } from "src/app/store/user/user.selectors";

@Component({
  selector: "app-create-update-router",
  templateUrl: "./create-router-stepper.component.html",
})
export class CreateRouterStepperComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild("wizard") wizard: WizardComponent;
  @Input("oldMAC") oldMAC: string;
  @Input("routerType") routerType: boolean;
  public validate = false;
  public tooltipValidation = false;

  routerStatus: RouterStatusInterface[];

  getRouter$: Observable<RouterInterface | null>;
  getRouter: RouterInterface | null;

  mac: string | null = null;

  RouterSuccessMessage$: Observable<SuccessDto | null>;

  loading$: Observable<boolean | null>;
  form: FormGroup;
  routerForm: FormGroup;
  locationPartnerNames$: Observable<string[] | null>;
  pageSize: number = 10;
  pageNumber: number = 1;
  routersFilter: RoutersFilterInterface = {
    ...initialState.routersFilter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  isExistByMac$: Observable<boolean | null>;
  isExistByMac: boolean;
  catchForbiddenError$: Observable<boolean | null>;
  isLoading : boolean | null = false
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translate: TranslateService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.RouterSuccessMessage$ = this.store
      .pipe(select(routerSuccessMessageSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.getRouter$ = this.store
      .pipe(select(routerByMacSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingRouterSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnerNames$ = this.store
      .pipe(select(locationPartnersComapyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isExistByMac$ = this.store
      .pipe(select(resultRouterExistByMacSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.routerForm = this.fb.group({
      accessInformation: this.fb.group({
        userName: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ]),
      }),
      routerSpecification: this.fb.group({
        MACAddress: new FormControl(null, [Validators.required]),
        acquisitionPrice: new FormControl(null, [Validators.required]),
        serialNumber: new FormControl(null, [Validators.required]),
        SSID: new FormControl(null, [Validators.required]),
        dateOfPurchase: new FormControl(null, [Validators.required]),
        status: new FormControl(null, [Validators.required]),
      }),
      releadRouter: this.fb.group({
        FASIP: new FormControl(null, [Validators.required]), //relead
        reverseTunnel: new FormControl(null, [Validators.required]),
        sessionTimeOut: new FormControl(null, [Validators.required]),
        reference: new FormControl(null, [Validators.required]),
      }),
      fsiRouter: this.fb.group({
        supplier: new FormControl(null, [Validators.required]), //fsi
      }),
      locationInformation: this.fb.group({
        dateOfProduction: new FormControl(null, [Validators.required]),
        locationPartner: new FormControl(null, [Validators.required]),
      }),
    });
  }
  ngOnInit(): void {
    this.loading$.subscribe((data)=> { this.isLoading = data})
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.closeModal()
        this.confirmAction.emit(true)
        this.isLoading = false
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.isExistByMac$.subscribe((result) => {
      if (result == false) {
        this.wizard.goToNextStep();
      }
      if (result) {
        this.isExistByMac = result;
        this.toastr.error(
          this.translate.instant("response.error.mac-exist"),
          this.translate.instant("response.errorTitle")
        );
      }
      this.store.dispatch(resultIsExistByMac({ isExist: null }));
    });
    this.route.params.subscribe(async (params: Params) => {
      this.mac = params["mac"] ?? null;
      if (params["mac"]) {
        this.store.dispatch(invokeGetRouterByMac({ MAC: params["mac"] }));
      }
    });
    this.store.dispatch(invokeLocationPartnersCompanyNames());
    this.getRouter$.subscribe((router: RouterInterface | null) => {
      if (router != null && this.mac != null) {
        this.getRouter = router;
        this.form.patchValue({ ...router });
      }
    });

    this.RouterSuccessMessage$.subscribe((rslt) => {
      if (rslt != null) {
        this.validate = false;
        this.clearForm();
      }
    });
    this.routerStatus = Object.keys(RouterStatus)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: RouterStatus[key as keyof typeof RouterStatus],
        value: RouterStatusToShow[key as keyof typeof RouterStatus],
      }));
  }
  getRouterStatusValue(id: RouterStatus | undefined): string {
    return this.routerStatus.find((item) => item.id == id)?.value ?? "";
  }
  submit() {
    this.confirmAction.emit(true)
    this.validate = true;
    let createRouter: any = {
      mac: this.routerForm.get("routerSpecification.MACAddress")?.value,
      ip: this.routerForm.get("releadRouter.FASIP")?.value,
      acquisitionPrice: this.routerForm.get(
        "routerSpecification.acquisitionPrice"
      )?.value,
      reverseTunnel: this.routerForm.get("releadRouter.reverseTunnel")?.value,
      serialNumber: this.routerForm.get("routerSpecification.serialNumber")
        ?.value,
      ssid: this.routerForm.get("routerSpecification.SSID")?.value,
      sessionTimeOut: this.routerForm.get("releadRouter.sessionTimeOut")?.value,
      reference: this.routerForm.get("releadRouter.reference")?.value,
      dateOfPurchase: this.routerForm.get("routerSpecification.dateOfPurchase")
        ?.value,
      userName: this.routerForm.get("accessInformation.userName")?.value,
      password: this.routerForm.get("accessInformation.password")?.value,
      locationPartner: this.routerForm.get(
        "locationInformation.locationPartner"
      )?.value,
      productionDate: this.routerForm.get(
        "locationInformation.dateOfProduction"
      )?.value,
      status: this.routerForm.get("routerSpecification.status")?.value,
      internetProvider: this.routerForm.get("fsiRouter.supplier")?.value,
    };
    if (this.routerType) {
      createRouter.type = RouterType.RELEAD_ROUTER;
      createRouter.status = this.routerForm.get(
        "routerSpecification.status"
      )?.value;
    } else {
      createRouter.type = RouterType.FSI_ROUTER;
    }
    this.store.dispatch(invokeCreateRouter({ createRouter: createRouter }));
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.POST,
      UserHistoryServicesEnum.CREATE_ROUTERS
    );
    this.store.dispatch(
      invokeRouterFilter({
        routerFilter: {
          ...this.routersFilter,
          type: createRouter.type,
        },
      })
    );
  }
  public tooltipSubmit() {
    this.tooltipValidation = !this.tooltipValidation;
  }
  reset() {
    if (this.mac != null) {
      this.form.patchValue(this.getRouter ?? {});
    } else {
      this.form.reset();
    }
  }
  showModal(simpleContent: TemplateRef<any>) {
    const modalRef = this.modalService.open(simpleContent, { centered: true });
  }
  clearForm() {
    this.routerForm.reset();
    this.modalService.dismissAll();
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  checkRouterExist() {
    this.store.dispatch(
      invokeIsExistByMac({
        Mac: this.routerForm.get("routerSpecification.MACAddress")?.value,
      })
    );
  }
}
