import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '../store/auth/auth.effects';
import { AuthReducer } from '../store/auth/auth.reducers';
import { ArchwizardModule } from 'angular-archwizard';
import { PersonalInformationComponent } from './register/personal-information/personal-information.component';
import { BusinessInformationComponent } from './register/business-information/business-information.component';
import { NgxAbstractControlAsModule } from 'ngx-abstract-control-as';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';


@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    PersonalInformationComponent,
    BusinessInformationComponent,
    ConfirmEmailComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    ArchwizardModule,
    StoreModule.forFeature('authtState', AuthReducer),
    EffectsModule.forFeature([
      AuthEffects,
    ]),
    NgxAbstractControlAsModule,
    NgxMaskModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule
  ]
})
export class AuthModule { }
