<div class="progress-showcase" *ngIf="length != 0 && !isLoading" >
  <div class="grid-column" *ngFor="let item of percentage; let i = index">
    <p *ngIf="keys[i] !=undefined"> {{keys[i].toUpperCase()}} </p>
    <div class="progress sm-progress-bar mt-2">
      <div
      data-bs-toggle="tooltip"
      placement="bottom"
      [ngbTooltip]="tooltip"
      (mouseenter)="item"
        class="progress-bar custom-progress-width bg-primary"
        [ngStyle]="{ width: item + '%' }"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <ng-template #tooltip>
      {{item | number:'1.1-2'}} %
    </ng-template>
  </div>
</div>
<div *ngIf="forbidden" class="text-center d-flex justify-content-center m-t-20">
  {{ "response.error.FORBIDDEN_RESPONSE" | translate }}
</div>
<div class="text-center" *ngIf="isLoading">
  <div class="loader-box">
    <div class="loader-34"></div>
  </div>
  <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
</div>
<div *ngIf="length == 0 && !isLoading && !forbidden;" class="d-flex justify-content-center m-t-20">
  {{"noData" | translate}}
</div>
