import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { OsFamily } from "src/app/shared/models/statistics/osFamily-statics";
import { AppStateInterface } from "src/app/store/appState.interface";
import { loadingConsumerSelector } from "src/app/store/consumers/consumers.selectors";
import { catchForbiddenError } from "src/app/store/user/user.actions";
import { catchForbiddenErrorSelector } from "src/app/store/user/user.selectors";
let primary_color = "#DF2281";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
})
export class BarChartComponent extends BaseComponent implements OnInit {
  @Input("resultOsFamily") resultOsFamily: Observable<OsFamily | null>;
  public chartOptions: Partial<ChartOptions> | any;
  length = 0;
  @ViewChild("chartApex", { static: false }) chartApex: ChartComponent;
  xaxis: ApexXAxis = {};
  yaxis: ApexYAxis = {};
  @Input() title = "";
  @Input() style: any;
  @Input() xaxisTitle = "";
  @Input() yaxisTitle = "";
  @Input() seriesTitles: string[] = [];
  @Input() labels: any;
  @Input() isLoading: boolean;
  catchForbiddenError$: Observable<boolean | null>;
  forbidden: boolean | null = false;
  constructor(
    private store: Store<AppStateInterface>,
    private translate: TranslateService
  ) {
    super(store);
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        height: "300",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      xaxis: {
        title: { text: "" },
        categories: [],
      },

      yaxis: {
        title: {
          text: "",
        },
      },
    };
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnInit(): void {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.forbidden = true;
        this.isLoading = false;
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    if (this.resultOsFamily != null) {
      this.resultOsFamily.subscribe((data) => {
        if (data != undefined) {
          this.isLoading = false;
          this.length = Object.keys(data).length;
          const sortedEntries = Object.entries(data).sort(
            (a, b) => b[1] - a[1]
          );
          const objetTrie = sortedEntries.reduce(
            (acc: { [key: string]: number }, [key, value]: [string, any]) => {
              acc[key] = value;
              return acc;
            },
            {}
          );
          this.configChartOsFamily(objetTrie);
        }
      });
    }
  }
  configChartOsFamily(result: { [key: string]: number }) {
    if (result) {
      this.chartOptions = {
        ...this.chartOptions,
        title: this.title,
        series: [
          {
            name: this.translate.instant(this.yaxisTitle),
            data: Object.values(result),
            color: primary_color,
          },
        ],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          categories: Object.keys(result),
          title: {
            text: this.translate.instant(this.yaxisTitle),
          },
        },
      };
    }
  }
}
export type ChartOptions = {
  series?: ApexAxisChartSeries;
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  labels?: string[];
  stroke?: ApexStroke;
  title?: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
};
