import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { SelectedLps } from "src/app/shared/models/location-partners/selectedLps";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  advertisingCompaignResult,
  deleteAdCampaign,
  invokeAddAdvertisingCampaign,
  invokeAdvertisingCompaignFilter,
  SetAddAdvertisingCampaign,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeUploadFileWithProgress,
  setFileName,
} from "src/app/store/file/file.actions";
import { uploadFileProgressSelector } from "src/app/store/file/file.selectors";
import {
  locationPartnersComapyNamesSelector,
  saveSelectedLPsSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import {
  ActionInterface,
  ActionReturned,
  locationCampaignActions,
  OptionActionEnum,
} from "src/app/shared/data/actions-data";
import { VideoAdComponent } from "src/app/shared/components/video-ad/video-ad.component";
import { environment } from "src/environments/environment";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { invokeLocationPartnersCompanyNames } from "src/app/store/locationPartner/actions/get-locations.actions";
import { FilterListAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { ResultFilterAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/result-filter-advertising-compaign.interface";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import {
  addCampaignSelector,
  deleteAdvertisingCampaignSelector,
  filterAdvertisingCompaignSelector,
  loadingAdvertisingCampaignTableSelector,
  resultFilterAdvertisingCompaignSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { DeleteComponent } from "src/app/shared/components/drob-zone-options/modal/delete/delete.component";
import { UpdateCampaignStatusComponent } from "../../ads-compaigns/update-campaign-status/update-campaign-status.component";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { UpdateCampaignLPComponent } from "./update-campaign-lp/update-campaign-lp.component";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { Router } from "@angular/router";
import { DetailsAdComponent } from "src/app/shared/components/details-modal/details-ad/details-ad.component";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import {
  catchForbiddenError,
  catchServerError,
} from "src/app/store/user/user.actions";
import { catchForbiddenErrorSelector, catchServerErrorSelector } from "src/app/store/user/user.selectors";
@Component({
  selector: "app-campagnes-lp",
  templateUrl: "./campagnes-lp.component.html",
  styleUrls: ["./campagnes-lp-component.scss"],
})
export class CampagnesLPComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  pageSize: number = 10;
  pageNumber: number = 1;
  formFilter: FormGroup;
  submitted: boolean;
  form: FormGroup;
  validate: boolean;
  showAlert: boolean = false;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  files: File[] = [];
  showError: boolean = false;
  isShown: boolean = false;
  filename: string | null = null;
  approveInProgress: boolean;
  uploadFile$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  uploadFileProgress$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  selectedLps: string[] = [];
  showSelectedLPs$: Observable<SelectedLps | null>;

  loading$: Observable<boolean | null>;

  options: ActionInterface[] = locationCampaignActions;
  allCompanyNames$: Observable<string[] | null>;
  resultAllCompanyNames: string[] | null;
  advertisingCompaignFilter$: Observable<FilterListAdvertisingCompaign>;
  advertisingCompaignFilter: FilterListAdvertisingCompaign = {
    ...initialState.filter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  resultFilterAdvertisingCompaign$: Observable<ResultFilterAdvertisingCompaign | null>;
  resultAdvertisingCompainFilter: AdvertisingCampaignInterface[] | null;
  count: number;
  successMessage$: Observable<SuccessDto | null>;
  success: string;
  loadingCreate: boolean = false;
  created$: Observable<SuccessDto | null>;
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    configPB: NgbProgressbarConfig,
    private translate: TranslateService,
    private router: Router,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    configPB.max = 100;
    configPB.striped = true;
    configPB.animated = true;
    configPB.type = "primary";
    configPB.height = "20px";
    this.uploadFileProgress$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFile$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.showSelectedLPs$ = this.store
      .pipe(select(saveSelectedLPsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.allCompanyNames$ = this.store
      .pipe(select(locationPartnersComapyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      title: [""],
    });
    this.form = this.fb.group({
      lp: [null, [Validators.required]],
    });
    this.advertisingCompaignFilter$ = this.store
      .pipe(select(filterAdvertisingCompaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultFilterAdvertisingCompaign$ = this.store
      .pipe(select(resultFilterAdvertisingCompaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignTableSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.successMessage$ = this.store
      .pipe(select(deleteAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.created$ = this.store
      .pipe(select(addCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerError$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  columns: IColumn[] = [
    {
      key: "title",
      label: "admin.locations.campaigns.campaignName",
      sorter: true,
    },
    {
      key: "companyName",
      label: "admin.locations.campaigns.companyName",
      sorter: true,
    },
    {
      key: "objective",
      label: "admin.locations.campaigns.objective",
      sorter: true,
    },
    {
      key: "updateDate",
      label: "admin.locations.campaigns.updateDate",
      sorter: true,
    },
  ];
  emails: string[] = [];
  catchServerError$: Observable<boolean | null>;
  catchForbiddenError$: Observable<boolean | null>;
  ngOnInit(): void {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.loadingCreate = false;
        this.modalService.dismissAll();
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.catchServerError$.subscribe((result) => {
      if (result) {
        this.loadingCreate = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.loading$.subscribe((data) => {});
    this.store.dispatch(
      advertisingCompaignResult({ listAdvertisingCompaign: null })
    );
    this.resultFilterAdvertisingCompaign$.subscribe((result) => {
      if (result) {
        this.resultAdvertisingCompainFilter = result.advertisingCampaigns;
        this.count = result.count;
      } else {
        this.invokeTable();
      }
    });
    this.advertisingCompaignFilter$.subscribe(
      (result: FilterListAdvertisingCompaign) => {
        if (result) {
          this.advertisingCompaignFilter = result;
          this.pageNumber = result.pager.pageNumber;
        }
      }
    );
    this.store.dispatch(invokeLocationPartnersCompanyNames());
    this.allCompanyNames$.subscribe((data) => {
      if (data) {
        this.resultAllCompanyNames = data;
      }
    });
    this.successMessage$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.success = result.message;
        this.store.dispatch(deleteAdCampaign({ successMessage: null }));
        if (this.success != "") {
          this.toastr.success(
            this.translate.instant("response.success." + this.success),
            this.translate.instant("response.successTitle")
          );
          this.invokeTable();
        }
        this.modalService.dismissAll();
        this.clear();
        this.uploadProgress = 0;
        this.showProgress = false;
      }
    });
    this.created$.subscribe((created: SuccessDto | null) => {
      if (created != null) {
        this.loadingCreate = false;
        this.toastr.success(
          this.translate.instant("response.success." + created.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(SetAddAdvertisingCampaign({ adCreated: null }));
        this.clear();
        this.modalService.dismissAll();
        this.submitted = false;
        this.invokeTable();
      }
    });
    this.uploadFile$.subscribe(
      (result: HttpEvent<any> | null | HttpResponse<{ filename: string }>) => {
        if (result?.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          const total = result?.total ?? 1;
          this.uploadProgress = Math.round((result.loaded / total) * 100);
        }
        if (result?.type == HttpEventType.Response) {
          this.filename =
            (result as HttpResponse<{ filename: string }>)?.body?.filename ??
            "";
          this.submitted = true;
          this.store.dispatch(setFileName({ filename: null }));
          this.store.dispatch(setFileName({ filename: null }));
          setTimeout(() => {
            this.showProgress = false;
          }, 2000);
        }
      }
    );
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_LOCATION,
          title: this.formFilter.get("title")?.value,
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageNumber: event,
          },
        },
        refreshCache: false,
      })
    );
  }
  columnFilterChange(event: any) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_LOCATION,
          title: this.formFilter.get("title")?.value,
          sortName: event.column,
        },
        refreshCache: false,
      })
    );
  }
  get changePageSize() {
    return this.advertisingCompaignFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_LOCATION,
          title: this.formFilter.get("title")?.value,
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageSize: pageSize,
          },
        },
        refreshCache: false,
      })
    );
  }
  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_LOCATION,
          title: this.formFilter.get("title")?.value,
          pager: {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          },
        },
        refreshCache: false,
      })
    );
  }
  get title() {
    return this.form.get("title");
  }
  addCampaign(addCampaign: any) {
    this.modalService.open(addCampaign, {
      centered: true,
      size: "lg",
    });
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.POST,
      UserHistoryServicesEnum.CREATE_CAMPAIGN_LP
    );
  }
  onSubmitCreateCampaign() {
    this.submitted = true;
    this.loadingCreate = true;
    this.validate = this.form.invalid;
    if (!this.validate) {
      this.store.dispatch(
        invokeAddAdvertisingCampaign({
          addCampaign: {
            startingDate: null,
            title: this.formFilter.get("title")?.value,
            views: 0,
            endingDate: null,
            objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
            redirectionLink: "",
            locationPartners: [this.form.get("lp")?.value],
            videoLink: this.filename,
            status: CampaignStatusEnum.WAITING,
            synthesisReport: null,
            estimateId: null,
            calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
            totalHt : null
          },
        })
      );
    }
  }
  onSelect(event: File[]) {
    if (event[0].size > 5242880) {
      this.showError = true;
      this.showProgress = false;
    } else {
      this.files.push(event[0]);
      this.isShown = true;
      const formData = new FormData();
      formData.append("file", this.files[0], this.files[0].name);
      this.store.dispatch(invokeUploadFileWithProgress({ file: formData }));
    }
  }
  onRemove() {
    this.filename = null;
    this.files = [];
    this.uploadProgress = 0;
    this.showProgress = false;
  }
  clear() {
    this.onRemove();
    this.form.reset({
      title: "",
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_LOCATION,
          title: this.formFilter.get("title")?.value,
          sortName: column,
          isAscending: sort,
        },
        refreshCache: false,
      })
    );
  }

  saveOption(action: ActionReturned, ad: AdvertisingCampaignInterface) {
    switch (action.option.type) {
      case OptionActionEnum.CAMPAIGNS_STATISTICS: {
        this.router.navigate([
          "/",
          "dashboard",
          "admin",
          "campaigns",
          "pipeline",
          "statistics",
          ad.advertisingCampaignHashedId,
        ]);
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.CAMPAIGN_LOCATIONS_STATISTICS
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_SHOW_VIDEO: {
        const modal = this.modalService.open(VideoAdComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.videoLink =
          environment.CDN_RELEAD + ad.videoLink;
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.CAMPAIGN_LOCATIONS_SHOW_VIDEO
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DELETE: {
        const modal = this.modalService.open(DeleteComponent, {
          centered: true,
        });
        modal.componentInstance.name = "pop-up.campaign.title";
        modal.componentInstance.reference = ad.title;
        modal.componentInstance.CampaignHashedId =
          ad.advertisingCampaignHashedId;
        modal.componentInstance.title = action.option.name;
        modal.componentInstance.type = OptionActionEnum.CAMPAIGN_DELETE;

        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_CAMPAIGN_LOCATION
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_UPDATE_STATUS: {
        const modal = this.modalService.open(UpdateCampaignStatusComponent, {
          centered: true,
        });
        modal.componentInstance.detailsAd = ad;
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.PATCH,
          UserHistoryServicesEnum.CAMPAIGN_LOCATION_UPDATE_STATUS
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_LP_UPDATE: {
        const modal = this.modalService.open(UpdateCampaignLPComponent, {
          centered: true,
        });
        modal.componentInstance.ad = ad;
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.PATCH,
          UserHistoryServicesEnum.CAMPAIGN_LOCATION_UPDATE
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DETAILS: {
        const modal = this.modalService.open(DetailsAdComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.detailsAd = ad;
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.CAMPAIGN_LOCATION_DETAILS
        );
        break;
      }
    }
  }
  invokeTable() {
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_LOCATION,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
        refreshCache: false,
      })
    );
  }
}
