import {
  Component,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
  Data,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "./base.component";
import {
  ApiStateInterface,
  selectApiState,
  setAPIStatus,
} from "./store/apiState.interface";
import { AppStateInterface } from "./store/appState.interface";
import { ToastrService } from "ngx-toastr";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { languageData } from "./shared/data/languages";
import { NavigatorService } from "./shared/services/navigator/navigator.service";
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { MetaService } from "./shared/services/meta/meta.service";
import {
  catchForbiddenError,
  catchServerError,
} from "./store/user/user.actions";

declare let gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends BaseComponent implements OnInit {
  @HostBinding("@.disabled")
  public animationsDisabled = false;

  ApiState$: Observable<ApiStateInterface>;

  errorCount: number = 0;
  locale: string;
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animation"]
    );
  }

  toggleAnimations() {
    this.animationsDisabled = !this.animationsDisabled;
  }
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private store: Store<AppStateInterface>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private navigatorService: NavigatorService,
    private metaService: MetaService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(store);
    this.ApiState$ = this.store
      .pipe(select(selectApiState))
      .pipe(takeUntil(this.ngDestroyed$));
    translate.addLangs(["en", "fr"]);
    const storedLang: string =
      localStorage.getItem("lang") ?? languageData[1].key;
    if (storedLang) {
      translate.use(storedLang);
    } else {
      translate.setDefaultLang("fr");
      translate.use("fr");
    }
    /** START : Code to Track Page View using gtag.js */
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        if (!this.isIncludedRoute(event.urlAfterRedirects)) {
          return;
        }
        this.setupGoogleAnalytics();
        gtag("event", "page_view", {
          page_path: event.urlAfterRedirects,
        });
      });
    /** END : Code to Track Page View  using gtag.js */
  }
  private isIncludedRoute(route: string): boolean {
    // Add your included routes here
    const includedRoutes = "/dashboard/advertiser/";
    return route.includes(includedRoutes);
  }
  ngOnInit(): void {
    this.navigatorService
      .getIpAddress()
      .subscribe((value) => localStorage.setItem("Ip-Address", value.ip));

    this.ApiState$.subscribe((data) => {
      if (data.apiStatus === "error") {
        if (data.apiResponseMessage.status == 401) {
          if (this.errorCount == 0) {
            this.errorCount++;
            this.toastr.error(
              this.translate.instant("response.error.REVOKED_ACCOUNT"),
              "UNAUTHORIZED"
            );
            localStorage.removeItem('token');
            this.router.navigate(['/auth/login']);
            setTimeout(() => {
              this.errorCount = 0;
            }, 1000);
          }
        } else if (
          data.apiResponseMessage.statusText == "Unknown Error" ||
          data.apiResponseMessage.status == 500
        ) {
          if (this.errorCount == 0) {
            this.errorCount++;
            this.toastr.error(
              this.translate.instant("response.error.undefined"),
              this.translate.instant("response.errorTitle")
            );
            setTimeout(() => {
              this.errorCount = 0;
            }, 1000);
          }
        } else if (
          data.apiResponseMessage.status == 403 ||
          data.apiResponseMessage.httpCode == 403
        ) {
          this.store.dispatch(catchForbiddenError({ forbiddenError: true }));
          if (this.errorCount == 0) {
            this.errorCount++;
            this.toastr.error(
              this.translate.instant("response.error.FORBIDDEN_RESPONSE"),
              this.translate.instant("response.errorTitle")
            );
            setTimeout(() => {
              this.errorCount = 0;
            }, 1000);
          }
        } else {
          this.store.dispatch(catchServerError({ serverError: true }));
          const errorMessage =
            data.apiResponseMessage.error?.message ||
            data.apiResponseMessage.message;
          this.toastr.error(
            this.translate.instant("response.error." + errorMessage),
            this.translate.instant("response.errorTitle")
          );
        }
        this.store.dispatch(
          setAPIStatus({ apiStatus: { apiResponseMessage: "", apiStatus: "" } })
        );
      }

      if (data.apiStatus === "success") {
        this.store.dispatch(
          setAPIStatus({ apiStatus: { apiResponseMessage: "", apiStatus: "" } })
        );
      }
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data),
        tap(({ title, description }: Data) => {
          this.metaService.updateTitle(title);
          this.metaService.updateDescription(description);
        })
      )
      .subscribe();

    this.locale = this.translate.currentLang;
    // don't forget to unsubscribe!
    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
      }
    );
  }

  /** Add Google Analytics Script Dynamically */
  setupGoogleAnalytics(): void {
    const gtagScript: HTMLScriptElement = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-XZXPEB2VR5";
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count */
    gtag("config", "G-XZXPEB2VR5", { send_page_view: false });
  }
}
