import { state } from "@angular/animations";
import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const userState = (state: AppStateInterface) => state.userState;

export const userSuccessMessageSelector = createSelector(
  userState,
  (state) => state.successMessage
);

export const sendForgetPasswordLoaderSelector = createSelector(
  userState,
  (state) => state.sendForgetPasswordLoader
);
export const forgotPasswordSuccesSelector = createSelector(
  userState,
  (state) => state.forgetPasswordSuccess
);
export const forgotPasswordFailedSelector = createSelector(
  userState,
  (state) => state.forgetPasswordFailed
);
export const checkTokenForResetPasswordSuccessSelector = createSelector(
  userState,
  (state) => state.checkForgetPassCode
);

export const checkUserExistByEmailSelector = createSelector(
  userState,
  (state) => state.isExist
);
export const checkUserExistByCompanyNameSelector = createSelector(
  userState,
  (state) => state.isExistByCompanyName
);
export const dashboardSettingsSelector = createSelector(
  userState,
  (state) => state.dashboardSettings
);

export const tokensHistorySelector = createSelector(
  userState,
  (state) => state.tokensHistory
);
export const resultSubRoleSelector = createSelector(
  userState,
  (state) => state.subRole
);

export const catchServerErrorSelector = createSelector(
  userState,
  (state) => state.serverError
);

export const catchForbiddenErrorSelector = createSelector(
  userState,
  (state) => state.forbiddenError
);
