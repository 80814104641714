import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeUploadFileWithProgress,
  setFileName,
} from "src/app/store/file/file.actions";
import { uploadFileProgressSelector } from "src/app/store/file/file.selectors";
import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { FormControl, Validators } from "@angular/forms";
import {
  invokeAddAdvertisingCampaign,
  invokeAdDetails,
  SetAddAdvertisingCampaign,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import {
  adDetailsSelector,
  addCampaignSelector,
  loadingAdvertisingCampaignSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { JwtService } from "src/app/shared/services/jwt.service";
import { ToastrService } from "ngx-toastr";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Constants } from "src/app/shared/data/constants";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";

@Component({
  selector: "app-promote",
  templateUrl: "./promote.component.html",
  styleUrls: ["./promote.component.scss"],
})
export class PromoteComponent extends BaseComponent implements OnInit {
  created$: Observable<SuccessDto | null>;
  showError: boolean = false;
  isShown: boolean = false;
  companyName: string;
  uploadFileProgress$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  filename: string | null;
  submitted: boolean = false;
  campaignLP: any;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  videoLinkFormControl: FormControl<any> = new FormControl(null, [
    Validators.required,
    Validators.minLength(3),
    Validators.pattern("^https://.*"),
  ]);
  files: File[] = [];
  formData = new FormData();
  videoLink: string;
  videoGuideLink = Constants.VIDEO_GUIDE_LINK;
  ad: any = {
    title: "",
    startingDate: null,
    endingDate: null,
    publishDate: null,
    videoLink: "",
    redirectionLink: "",
    locationPartners: [],
    views: 0,
    objectiveName: null,
    order: null,
  };
  uploadFile$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  adDetails$: Observable<AdDetailsInterface>;
  bookMeeting: string;
  loading$: Observable<boolean | null>;
  loading: boolean;
  constructor(
    private store: Store<AppStateInterface>,
    configPB: NgbProgressbarConfig,
    private toastr: ToastrService,
    private jwtService: JwtService,
    private translate: TranslateService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    configPB.max = 100;
    configPB.striped = true;
    configPB.animated = true;
    configPB.type = "primary";
    configPB.height = "20px";
    this.created$ = this.store
      .pipe(select(addCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFileProgress$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFile$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnInit(): void {
    this.loading$.subscribe((data) => {
      if (data) {
        this.loading = data;
      }
    });
    this.bookMeeting = environment.links.bookMeetingLP;
    this.companyName = this.jwtService.getCompanyName();
    this.created$.subscribe((created: SuccessDto | null) => {
      if (created != null) {
        this.loading = false;
        this.toastr.success(
          this.translate.instant("response.success." + created.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(SetAddAdvertisingCampaign({ adCreated: null }));
        this.onClickClear();
        this.submitted = false;
      }
    });
    this.uploadFile$.subscribe(
      (result: HttpEvent<any> | null | HttpResponse<{ filename: string }>) => {
        if (result?.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          const total = result?.total ?? 1;
          this.uploadProgress = Math.round((result.loaded / total) * 100);
        }
        if (result?.type == HttpEventType.Response) {
          this.filename =
            (result as HttpResponse<{ filename: string }>)?.body?.filename ??
            "";
          this.submitted = true;
          this.store.dispatch(setFileName({ filename: null }));
          this.store.dispatch(setFileName({ filename: null }));
          setTimeout(() => {
            this.showProgress = false;
          }, 2000);
        }
      }
    );
    this.adDetails$.subscribe((result: AdDetailsInterface) => {
      this.ad = result;
      // if (!this.isShown) this.onSelect([result.videoFile] as File[]);
    });
  }
  onSelect(event: File[]) {
    if (event[0].size > 5242880) {
      this.showError = true; 
      this.showProgress = false;
    }
    else {
      this.submitted = true;
      this.files.push(event[0]);
      this.isShown = true;
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: { ...this.ad, videoFile: event[0] },
        })
      );
      const formData = new FormData();
      formData.append("file", this.ad.videoFile as File, this.ad.videoFile.name);
      this.store.dispatch(invokeUploadFileWithProgress({ file: formData }));
    }
  }
  onSubmit() {
    this.store.dispatch(
      invokeAddAdvertisingCampaign({
        addCampaign: {
          startingDate: null,
          title: this.replaceSpacesWithHyphen(this.companyName),
          views: 0,
          endingDate: null,
          objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
          redirectionLink: "",
          locationPartners: [this.companyName],
          videoLink: this.filename,
          status: CampaignStatusEnum.WAITING,
          synthesisReport: null,
          estimateId: null,
          calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
          totalHt : null
        },
      })
    );
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.POST,
      UserHistoryServicesEnum.PROMOTE_CAMPAIGN_LP
    );
  }
  onClickClear() {
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: { ...this.ad, videoFile: null },
      })
    );
    this.filename = null;
    this.files = [];
    this.uploadProgress = 0;
    this.showProgress = false;
    this.submitted = false;
  }
  onRemove(_t36: File) {
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: { ...this.ad, videoFile: null },
      })
    );
    this.filename = null;
    this.files = [];
    this.uploadProgress = 0;
    this.showProgress = false;
    this.submitted = false;
  }
  replaceSpacesWithHyphen(input: string): string {
    return input.replace(/\s+/g, "-");
  }
}
