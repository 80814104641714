import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DateView } from "@danielmoncada/angular-datetime-picker";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { FilterListAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { ResultFilterAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/result-filter-advertising-compaign.interface";
import {
  CampaignStatusEnum,
  CampaignStatusEnumMapping,
} from "src/app/shared/enum/campaign-status";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  adCampaignSelector,
  filterAdvertisingCompaignSelector,
  loadingAdvertisingCampaignTableSelector,
  resultAddSynthesisReportSelector,
  resultFilterAdvertisingCompaignSelector,
  successUploadFileSelector,
  updateAdCampaignDetailsSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  advertisingCompaignResult,
  invokeAdvertisingCompaignFilter,
  resultAddSynthesisReport,
  resultSuccessAd,
  resultUploadJustificationFile,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";
import { ObjectiveTypeEnumMapping } from "src/app/shared/enum/objectives";
import { UploadFilesComponent } from "src/app/shared/components/upload-files/upload-files.component";
import {
  ActionInterface,
  ActionReturned,
  OptionActionEnum,
  adminCampaignActions,
} from "src/app/shared/data/actions-data";
import { DetailsAdComponent } from "src/app/shared/components/details-modal/details-ad/details-ad.component";
import { VideoAdComponent } from "src/app/shared/components/video-ad/video-ad.component";
import { environment } from "src/environments/environment";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { UpdateCampaignStatusComponent } from "./update-campaign-status/update-campaign-status.component";
import {
  advertisingCampaignsFilter,
  FilterInterface,
} from "src/app/shared/data/filter-data";
import { UpdateCampaignComponent } from "./update-campaign/update-campaign.component";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import {
  invokeDownloadFile,
  resultDownloadFile,
} from "src/app/store/file/file.actions";
import { downloadedFileSelector } from "src/app/store/file/file.selectors";
import { DeleteComponent } from "src/app/shared/components/drob-zone-options/modal/delete/delete.component";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { Router } from "@angular/router";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { getAdHavePromotionCode } from "src/app/store/promotion-code/promotion-code.actions";
import { calculateTotalLPCostByCampanyNames } from "src/app/store/locationPartner/location-partner.action";
import { catchForbiddenError } from "src/app/store/user/user.actions";
import { catchForbiddenErrorSelector } from "src/app/store/user/user.selectors";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
import { resultSynthesisEstimateSelector } from "src/app/store/commande/commande.selectors";
import { Data } from "src/app/shared/models/iberis/commande/data";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { DatePipe } from "@angular/common";
import { CancelEstimate } from "src/app/shared/services/iberis/cancelEstimate";
import { resultSynthesisEstimate } from "src/app/store/commande/commande.actions";
@Component({
  selector: "app-ads-compaigns",
  templateUrl: "./ads-compaigns.component.html",
})
export class AdsCompaignsComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  public years = DateView.MULTI_YEARS;
  columns: IColumn[] = [
    {
      key: "title",
      label: "admin.campaigns.pipeline.campaignName",
      sorter: true,
      width: 20,
    },
    {
      key: "firstName",
      label: "admin.campaigns.pipeline.advertiser",
      sorter: true,
      width: 15,
    },
    {
      key: "objective",
      label: "admin.campaigns.pipeline.objective",
      sorter: true,
      width: 15,
    },
    {
      key: "locations",
      label: "admin.campaigns.pipeline.locations",
      sorter: false,
      width: 40,
    },
    {
      key: "startingDate",
      label: "admin.campaigns.pipeline.startDate",
      sorter: true,
      width: 15,
    },
    {
      key: "endingDate",
      label: "admin.campaigns.pipeline.endDate",
      sorter: true,
      width: 15,
    },
  ];
  date = [null, null];
  pageSize: number = 10;
  pageNumber: number = 1;
  advertisingCompaignFilter$: Observable<FilterListAdvertisingCompaign>;
  advertisingCompaignFilter: FilterListAdvertisingCompaign = {
    ...initialState.filter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  resultFilterAdvertisingCompaign$: Observable<ResultFilterAdvertisingCompaign | null>;
  resultAdvertisingCompainFilter: ResultFilterAdvertisingCompaign;
  formFilter: FormGroup;
  form: FormGroup;
  validate: string;
  submitted: boolean;
  adCampaign: AdvertisingCampaignInterface;
  adCampaign$: Observable<SuccessDto | null>;
  resultUpdateAdCampaign: SuccessDto | null;
  titleCampaign: string;
  waiting: boolean;
  isFiltring: boolean;
  campaignObjective = ObjectiveTypeEnumMapping;
  count: number;
  advertisingCampaign: AdvertisingCampaignInterface[] = [];
  campaignStatus = CampaignStatusEnum;
  loading$: Observable<boolean | null>;

  companyName: string[] = [];

  filterAds: FilterInterface = advertisingCampaignsFilter;

  addSynthesisReport$: Observable<SuccessDto | null>;
  resultAddSynthesisReport: SuccessDto | null;
  successMessage: string;
  successMessage$: Observable<SuccessDto | null>;

  downloadFile$: Observable<any>;
  resultDownloadFile: any;
  blob: Blob;
  adTitle: string;
  successUploadJustificatif$: Observable<SuccessDto | null>;
  cachedAt: Date | null;
  catchForbiddenError$: Observable<boolean | null>;
  forbidden: boolean | null = false;
  loading: boolean | null;
  isConfirmAction: boolean;

  resultSynthesisEstimate$: Observable<ResultSynthesisEstimate | null>;
  syntesisEstimate: Data;

  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  currentAd: AdvertisingCampaignInterface;
  currentAction: ActionReturned;

  deleteUpdateModal: NgbModalRef;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    public badgeService: BadgeService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translate: TranslateService,
    private extensionService: ExtensionService,
    private router: Router,
    private saveHistory: SaveUserHistoryService,
    private datePipe: DatePipe,
    private deleteEstimateService: CancelEstimate
  ) {
    super(store);
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.advertisingCompaignFilter$ = this.store
      .pipe(select(filterAdvertisingCompaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultFilterAdvertisingCompaign$ = this.store
      .pipe(select(resultFilterAdvertisingCompaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adCampaign$ = this.store
      .pipe(select(adCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignTableSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.addSynthesisReport$ = this.store
      .pipe(select(resultAddSynthesisReportSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.successMessage$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.downloadFile$ = this.store
      .pipe(select(downloadedFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.successUploadJustificatif$ = this.store
      .pipe(select(successUploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultSynthesisEstimate$ = this.store
      .pipe(select(resultSynthesisEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.formFilter = this.fb.group({
      title: [""],
      date: [[null, null]],
    });
    this.form = this.fb.group({
      title: [""],
      objectiveName: [""],
      uploadVideo: [""],
      redirectionLink: [""],
    });
  }
  ngOnInit(): void {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        if (!this.isConfirmAction) this.forbidden = true;
        this.loading = false;
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.addSynthesisReport$.subscribe((created: SuccessDto | null) => {
      if (created != null) {
        this.toastr.success(
          this.translate.instant("response.success." + created.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(resultAddSynthesisReport({ successMessage: null }));
        this.store.dispatch(
          invokeAdvertisingCompaignFilter({
            filter: {
              ...this.advertisingCompaignFilter,
              roleName: RoleEnum.ROLE_ADVERTISER,
              pager: {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
              },
            },
            refreshCache: false,
          })
        );
      }
    });
    this.filterAds.name.map(
      (e) =>
        (e.data = Object.keys(CampaignStatusEnum)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              CampaignStatusEnumMapping[key as keyof typeof CampaignStatusEnum]
          ))
    );
    this.loading$.subscribe((data) => {
      this.loading = data;
    });
    this.store.dispatch(
      advertisingCompaignResult({ listAdvertisingCompaign: null })
    );
    this.resultFilterAdvertisingCompaign$.subscribe((result) => {
      if (result) {
        this.resultAdvertisingCompainFilter = result;
        this.count = this.resultAdvertisingCompainFilter.count;
        if (this.resultAdvertisingCompainFilter.advertisingCampaigns) {
          this.companyName = [];
          this.advertisingCampaign =
            this.resultAdvertisingCompainFilter.advertisingCampaigns;
          this.advertisingCampaign?.map((e) => {
            this.companyName = [...this.companyName, e.publisherCompanyName];
          });
          this.cachedAt = result.cachedAt;
        }
      } else {
        this.store.dispatch(
          invokeAdvertisingCompaignFilter({
            filter: {
              ...this.advertisingCompaignFilter,
              roleName: RoleEnum.ROLE_ADVERTISER,
              pager: {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
              },
            },
            refreshCache: false,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.LIST_CAMPAIGNS
        );
      }
    });
    this.advertisingCompaignFilter$.subscribe(
      (result: FilterListAdvertisingCompaign) => {
        if (result) {
          this.advertisingCompaignFilter = result;
          this.pageNumber = result.pager.pageNumber;
        }
      }
    );
    this.adCampaign$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.resultUpdateAdCampaign = result;
      }
    });
    this.successMessage$.subscribe((data) => {
      if (data) {
        this.successMessage = data.message;
        this.store.dispatch(resultSuccessAd({ successMessage: null }));
        if (this.successMessage != "") {
          this.modalService.dismissAll();
        }
      }
    });
    this.successUploadJustificatif$.subscribe((success: SuccessDto | null) => {
      if (success != null) {
        this.store.dispatch(
          resultUploadJustificationFile({ successUpload: null })
        );
        this.toastr.success(
          this.translate.instant("response.success." + success.message),
          this.translate.instant("response.successTitle")
        );
        if (this.successMessage != "") {
          this.modalService.dismissAll();
          this.refreshCampaigns(false);
        }
      }
    });

    this.downloadFile$.subscribe((result) => {
      if (result) {
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${this.translate.instant(
          "campaignDetails.justificationFile"
        )}-${this.adTitle}.${this.extensionService.getExtension(result.url)}`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
        this.store.dispatch(resultDownloadFile({ downloadedFile: null }));
      }
    });

    this.resultSynthesisEstimate$.subscribe(
      (result: ResultSynthesisEstimate | null) => {
        if (result && result?.status.code == 200) {
          this.syntesisEstimate = result.data.estimate;
          this.deleteEstimateService.cancelEstimate(
            result.data.estimate,
            this.currentAd,
            this.currentAction.option.type
          );
        } else if (result?.status.code == 404) {
          this.toastr.error(
            this.translate.instant("response.error.undefined"),
            this.translate.instant("response.errorTitle")
          );
          this.store.dispatch(
            resultSynthesisEstimate({ resultSynthesisEstimate: null })
          );
          this.deleteUpdateModal.close();
        }
      }
    );
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageNumber: event,
          },
        },
        refreshCache: false,
      })
    );
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageSize: pageSize,
          },
        },
        refreshCache: false,
      })
    );
  }
  get changePageSize() {
    return this.advertisingCompaignFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  filter() {
    this.store.dispatch(invokeApiDistroyed());
    this.pageNumber = 1;
    this.isFiltring = true;
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          title: this.formFilter.get("title")?.value,
          endingDate: this.formFilter.get("date")?.value[0] ?? null,
          roleName: RoleEnum.ROLE_ADVERTISER,
          startingDate: this.formFilter.get("date")?.value[1] ?? null,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
        refreshCache: false,
      })
    );
  }
  removeFilter() {
    this.formFilter.reset({
      title: "",
      date: [null, null],
    });
    this.isFiltring = false;
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...initialState.filter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
        refreshCache: false,
      })
    );
  }
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());

    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          sortName: col,
          isAscending: sort,
        },
        refreshCache: false,
      })
    );
  }

  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          title: this.formFilter.get("title")?.value,
        },
        refreshCache: false,
      })
    );
  }
  saveOption(options: string[] | null) {
    this.store.dispatch(invokeApiDistroyed());
    let listFounds: any[] = [];
    options?.map((option) => {
      listFounds.push(
        Object.keys(CampaignStatusEnumMapping).find(
          (key) =>
            CampaignStatusEnumMapping[key as CampaignStatusEnum] === option
        )
      );
    });
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          status: listFounds,
        },
        refreshCache: false,
      })
    );
  }
  saveAction(action: ActionReturned, ad: AdvertisingCampaignInterface) {
    this.currentAd = ad;
    this.currentAction = action;
    switch (action.option.type) {
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS: {
        this.router.navigate([
          "/",
          "dashboard",
          "admin",
          "campaigns",
          "pipeline",
          "statistics",
          ad.advertisingCampaignHashedId,
        ]);
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.ADVERTISER_CAMPAIGNS_STATISTICS
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DETAILS: {
        const modal = this.modalService.open(DetailsAdComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.detailsAd = ad;
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.CAMPAIGN_DETAILS
        );
        this.store.dispatch(getAdHavePromotionCode({ result: null }));
        this.store.dispatch(
          calculateTotalLPCostByCampanyNames({ totalCostWithDiscount: null })
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_ADVERTISER_UPDATE: {
        const modal = this.modalService.open(UpdateCampaignComponent, {
          centered: true,
        });
        modal.componentInstance.ad = ad;
        modal.componentInstance.confirmAction.subscribe(
          (value: boolean) => (this.isConfirmAction = value)
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_UPDATE_STATUS: {
        this.deleteUpdateModal = this.modalService.open(
          UpdateCampaignStatusComponent,
          {
            centered: true,
          }
        );
        this.deleteUpdateModal.componentInstance.detailsAd = ad;
        this.deleteUpdateModal.componentInstance.confirmAction.subscribe(
          (value: boolean) => (this.isConfirmAction = value)
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DELETE: {
        this.deleteUpdateModal = this.modalService.open(DeleteComponent, {
          centered: true,
        });
        this.deleteUpdateModal.componentInstance.name = "pop-up.campaign.title";
        this.deleteUpdateModal.componentInstance.reference = ad.title;
        this.deleteUpdateModal.componentInstance.CampaignHashedId =
          ad.advertisingCampaignHashedId;
        this.deleteUpdateModal.componentInstance.estimateId = ad.estimateId;
        this.deleteUpdateModal.componentInstance.title = action.option.name;
        this.deleteUpdateModal.componentInstance.currentAd = ad;
        this.deleteUpdateModal.componentInstance.type =
          OptionActionEnum.CAMPAIGN_DELETE;
        this.deleteUpdateModal.componentInstance.confirmAction.subscribe(
          (value: boolean) => (this.isConfirmAction = value)
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_SHOW_VIDEO: {
        const modal = this.modalService.open(VideoAdComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.videoLink =
          environment.CDN_RELEAD + ad.videoLink;
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.ADMIN_CAMPAIGN_ADVERTISER_SHOW_VIDEO
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT: {
        const modal = this.modalService.open(UploadFilesComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.campaignHashedId =
          ad.advertisingCampaignHashedId;
        modal.componentInstance.modalTitle = action.option.name;
        modal.componentInstance.action =
          OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT;
        modal.componentInstance.savedFile = ad.synthesisReport;
        modal.componentInstance.accept =
          "application/pdf, .csv, image/*, .pptx";
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE: {
        const modal = this.modalService.open(UploadFilesComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.campaignHashedId =
          ad.advertisingCampaignHashedId;
        modal.componentInstance.modalTitle = action.option.name;
        modal.componentInstance.action =
          OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE;
        modal.componentInstance.savedFile = ad.justificationFile;
        modal.componentInstance.accept = "application/pdf, image/*";
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE: {
        this.adTitle = ad.title;
        this.store.dispatch(
          invokeDownloadFile({ filename: ad.justificationFile })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DOWNLOAD,
          UserHistoryServicesEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
        );
      }
    }
  }
  getOptions(ad: AdvertisingCampaignInterface): ActionInterface[] {
    var actions: ActionInterface[] = adminCampaignActions;
    if (ad.status != CampaignStatusEnum.FINISHED)
      actions = actions.filter(
        (option) =>
          option.type != OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT
      );
    if (ad.status == CampaignStatusEnum.FINISHED)
      actions = actions.filter(
        (option) =>
          option.type != OptionActionEnum.ADMIN_CAMPAIGN_ADVERTISER_UPDATE
      );
    if (ad.status == CampaignStatusEnum.CANCELED)
      actions = actions.filter(
        (option) =>
          option.type != OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE
      );
    if (ad.justificationFile == null)
      actions = actions.filter(
        (option) =>
          option.type != OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
      );
    return actions;
  }

  onClickFilterDates() {
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          startingDate: this.formFilter.get("date")?.value[0],
          endingDate: this.formFilter.get("date")?.value[1],
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
        refreshCache: false,
      })
    );
  }

  refreshCampaigns(refreshCache: boolean) {
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
        },
        refreshCache: refreshCache,
      })
    );
  }
}
