import { state } from "@angular/animations";
import { createReducer, on } from "@ngrx/store";
import {
  invokeForgotPassword,
  invokeResetPassword,
  invokeUserChangePassword,
  setForgotPasswordSuccess,
  setResetPassword,
  setDashboardSettings,
  setCheckUserExistsByEmail,
  setUserHistoryTokens,
  resultSubRoleByUser,
  setForgotPasswordFailed,
  checkTokenForResetPasswordSuccess,
  userSuccessMessage,
  setCheckUserExistsByCompanyName,
  catchServerError,
  catchForbiddenError,
} from "./user.actions";
import { UserStateInreface } from "./userState.interface";

export const initialState: Readonly<UserStateInreface> = {
  sendForgetPasswordLoader: false,
  checkForgetPassCode: null,
  forgetPasswordSuccess: null,
  forgetPasswordFailed: null,


  loading: false,
  successMessage: null,
  email: "",
  isExist: null,
  dashboardSettings: null,

  tokensHistory: {
    count:0,
    tokens:[]
  },
  subRole: null,
  isExistByCompanyName : null,

  serverError : false,
  forbiddenError : false
};

export const UserReducer = createReducer(
  initialState,
  on(invokeUserChangePassword, (state, { changePassword }) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(invokeForgotPassword, (state, { email }) => {
    return {
      ...state,
      sendForgetPasswordLoader: true,
    };
  }),
  on(setForgotPasswordSuccess, (state, { successMessage }) => {
    return {
      ...state,
      forgetPasswordSuccess: successMessage,
      sendForgetPasswordLoader: false,
    };
  }),
  on(setForgotPasswordFailed, (state, { error }) => {
    return {
      ...state,
      forgetPasswordFailed: error,
      sendForgetPasswordLoader: false,
    };
  }),
  
  on(checkTokenForResetPasswordSuccess, (state, { successMessage }) => {
    return {
      ...state,
      checkForgetPassCode: successMessage,
    };
  }),

  on(invokeResetPassword, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(setResetPassword, (state, { successMessage }) => {
    return {
      ...state,
      successMessage: successMessage,
      loading: false,
    };
  }),
  on(setDashboardSettings, (state, { dashboardSettings }) => {
    return {
      ...state,
      dashboardSettings: dashboardSettings,
    };
  }),
  on(setCheckUserExistsByEmail, (state, { isExist }) => ({
    ...state,
    isExist: isExist,
  })),
  on(setCheckUserExistsByCompanyName, (state, { isExistByCompanyName }) => ({
    ...state,
    isExistByCompanyName : isExistByCompanyName,
  })),
  on(setUserHistoryTokens, (state, { result }) => ({
    ...state,
    tokensHistory: result,
  })),
  on(resultSubRoleByUser, (state, { subRole }) => ({
    ...state,
    subRole: subRole,
  })),
  on(userSuccessMessage, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),

  on(catchServerError, (state, {serverError}) => {
    return {
      ...state,
      serverError : serverError
    };
  }),

  on(catchForbiddenError, (state, {forbiddenError}) => {
    return {
      ...state,
      forbiddenError : forbiddenError
    };
  }),
);
