export enum PaymentFrequency {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUALLY = "SEMI_ANNUALLY",
  NONE = "NONE",
}

export const PaymentFrequencyEnumMapping: Record<PaymentFrequency, string> = {
  [PaymentFrequency.ANNUAL]: "enumMapping.paymentFrequency.lowercase.ANNUAL",
  [PaymentFrequency.MONTHLY]: "enumMapping.paymentFrequency.lowercase.MONTHLY",
  [PaymentFrequency.QUARTERLY]: "enumMapping.paymentFrequency.lowercase.QUARTERLY",
  [PaymentFrequency.SEMI_ANNUALLY]: "enumMapping.paymentFrequency.lowercase.SEMI_ANNUALLY",
  [PaymentFrequency.NONE]: "enumMapping.paymentFrequency.lowercase.NONE",
};

export interface PaymentFrequencyInterface {
  id: PaymentFrequency;
  value: string;
}
