import { IberisActivities } from "../models/iberis/activities";
export class Constants {
  /* ENDPOINTS */
  public static FILE_STORAGE_ENDPOINT = "https://cdn.relead.tn/";
  public static USER_ENDPOINT = "/users";
  public static ADMIN_ENDPOINT = "/admin";
  public static CONSUMER_ENDPOINT = "/consumer";
  public static FILE_ENDPOINT = "/files";
  public static ADVERTISER_ENDPOINT = "/advertisers";
  public static STATICS_ENDPOINT = "/statistics";
  public static ADVERTISING_CAMPAIGN_ENDPOINT = "/advertising-campaigns";
  public static ROUTER_ENDPOINT = "/routers";
  public static BUG_REPORT_ENDPOINT = "/bug-reports";
  public static BILLING_ENDPOINT = "/billings";
  public static LOCATION_PARTNER_ENDPOINT = "/location-partners";
  public static CATEGORY_ENDPOINT = "/categories";
  public static FIXED_CHARGES = "/fixed-charges";
  public static SUB_CATEGORY_ENDPOINT = "/sub-categories";
  public static TAG_ENDPOINT = "/tags";
  public static ZONE_ENDPOINT = "/zones";
  public static REGION_ENDPOINT = "/regions";
  public static ROLE_ENDPOINT = "/roles";
  public static SUB_ROLE_ENDPOINT = "/sub-roles";
  public static GROUP_AUTHORITY_ENDPOINT = "/group-authorities";
  public static OBJECTIVE_ENDPOINT = "/objectives";
  public static MAILS_END_POINT = "/mails";
  public static COMMANDE_END_POINT = "/orders";
  public static HISTORY_END_POINT = "/histories";
  public static IBERIS_END_POINT = "/api/public/company/";
  public static PAYMEE_END_POINT = "/api/v2/payments";
  public static KONNECT_END_POINT = "/api/v2";
  public static PROMOTION_CODE_END_POINT = "/promotion-codes";
  public static RECAPTCHA = "/recaptcha";
  public static AUTHORITIES = "/authorities";

  public static DATE_IBERIS = new Date();
  public static DUE_DATE_IBERIS = new Date().setDate(
    this.DATE_IBERIS.getDate() + 30
  );

  public static VIDEO_GUIDE_LINK =
    "https://drive.google.com/file/d/1WXuK0fG1RhYRAiR_mbefqv2_yenMgktf/view";
  public static IBERIS_ACTIVITIES_EN: IberisActivities[] = [
    {
      title: "Agency or commercial company",
      hashed_id: "Dl",
    },
    {
      title: "Agriculture",
      hashed_id: "Er",
    },
    {
      title: "Art and design",
      hashed_id: "GZ",
    },
    {
      title: "Automobile industry",
      hashed_id: "JD",
    },
    {
      title: "Construction",
      hashed_id: "Kl",
    },
    {
      title: "Consumer goods",
      hashed_id: "Lq",
    },
    {
      title: "Education",
      hashed_id: "Mo",
    },
    {
      title: "Engineering",
      hashed_id: "Nl",
    },
    {
      title: "Entertainment",
      hashed_id: "OR",
    },
    {
      title: "Financial services",
      hashed_id: "mwj",
    },
    {
      title: "Catering activities",
      hashed_id: "nOg",
    },
    {
      title: "Games",
      hashed_id: "oyJ",
    },
    {
      title: "Public function",
      hashed_id: "pwQ",
    },
    {
      title: "Health services",
      hashed_id: "qWk",
    },
    {
      title: "Interior decoration",
      hashed_id: "rVv",
    },
    {
      title: "Internal",
      hashed_id: "vJN",
    },
    {
      title: "Legal",
      hashed_id: "wNy",
    },
    {
      title: "Industry",
      hashed_id: "xJJ",
    },
    {
      title: "Marketing",
      hashed_id: "yJA",
    },
    {
      title: "Mining and logistics",
      hashed_id: "JnX",
    },
    {
      title: "Non-profit",
      hashed_id: "Kov",
    },
    {
      title: "Publishing and web media",
      hashed_id: "LpB",
    },
    {
      title: "Retail (e-commerce and offline)",
      hashed_id: "MAB",
    },
    {
      title: "Real estate",
      hashed_id: "NAj",
    },
    {
      title: "Services",
      hashed_id: "OYp",
    },
    {
      title: "Technology",
      hashed_id: "PDx",
    },
    {
      title: "Telecommunications",
      hashed_id: "QAv",
    },
    {
      title: "Tourism / hospitality",
      hashed_id: "RAp",
    },
    {
      title: "Web design",
      hashed_id: "VMP",
    },
    {
      title: "Web development",
      hashed_id: "mwR",
    },
    {
      title: "Leather goods",
      hashed_id: "nOL",
    },
  ];

  public static IBERIS_ACTIVITIES_FR: IberisActivities[] = [
    {
      title: "Agence ou société commerciale",
      hashed_id: "Dl",
    },
    {
      title: "Agriculture",
      hashed_id: "Er",
    },
    {
      title: "Art et design",
      hashed_id: "GZ",
    },
    {
      title: "Industrie automobile",
      hashed_id: "JD",
    },
    {
      title: "Construction",
      hashed_id: "Kl",
    },
    {
      title: "Biens de consommation",
      hashed_id: "Lq",
    },
    {
      title: "Éducation",
      hashed_id: "Mo",
    },
    {
      title: "Ingénierie",
      hashed_id: "Nl",
    },
    {
      title: "Divertissement",
      hashed_id: "OR",
    },
    {
      title: "Services financiers",
      hashed_id: "mwj",
    },
    {
      title: "Activités de restauration",
      hashed_id: "nOg",
    },
    {
      title: "Jeux",
      hashed_id: "oyJ",
    },
    {
      title: "Fonction publique",
      hashed_id: "pwQ",
    },
    {
      title: "Services de santé",
      hashed_id: "qWk",
    },
    {
      title: "Décoration d'intérieur",
      hashed_id: "rVv",
    },
    {
      title: "Interne",
      hashed_id: "vJN",
    },
    {
      title: "Légal",
      hashed_id: "wNy",
    },
    {
      title: "Industrie",
      hashed_id: "xJJ",
    },
    {
      title: "Commercialisation",
      hashed_id: "yJA",
    },
    {
      title: "Exploitation minière et logistique",
      hashed_id: "JnX",
    },
    {
      title: "Non lucratif",
      hashed_id: "Kov",
    },
    {
      title: "Publication et médias Web",
      hashed_id: "LpB",
    },
    {
      title: "Vente au détail (e-commerce et hors ligne)",
      hashed_id: "MAB",
    },
    {
      title: "Immobilier",
      hashed_id: "NAj",
    },
    {
      title: "Service",
      hashed_id: "OYp",
    },
    {
      title: "Technologie",
      hashed_id: "PDx",
    },
    {
      title: "Télécommunications",
      hashed_id: "QAv",
    },
    {
      title: "Tourisme / hôtellerie",
      hashed_id: "RAp",
    },
    {
      title: "Création de sites web",
      hashed_id: "VMP",
    },
    {
      title: "Développement web",
      hashed_id: "mwR",
    },
    {
      title: "Maroquinerie",
      hashed_id: "nOL",
    },
  ];

  public static availibilityCondition =
    "Certaines des emplacements que vous avez sélectionnées peuvent ne pas être incluses dans le devis car elles ne sont pas disponibles. D'autres emplacements peuvent être partiellement disponibles, avec une disponibilité limitée sur un nombre restreint de jours, et non pour toute la durée de la campagne.";
}
