import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as _ from "lodash";
import { cloneDeep } from "lodash";
import { Observable, from, groupBy, mergeMap, takeUntil, toArray } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { LocationAvailabilityEnum } from "src/app/shared/enum/location-availability";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { CalculateCost } from "src/app/shared/models/calcul/calculateCost";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "src/app/shared/models/calcul/totalCost";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";
import { adDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  calculateTotalLPCostByCampanyNamesSelector,
  locationPartnersToCreateCampaignResultSelector,
} from "src/app/store/locationPartner/location-partner.selectors";

@Component({
  selector: "app-by-zone",
  templateUrl: "./by-zone.component.html",
  styleUrls: ["./by-zone.component.scss"],
})
export class ByZoneComponent extends BaseComponent implements OnInit {
  notAvailableExists: boolean = false;
  locationSelected: TotalCost;

  calculateTotalLPCostByCampanyNames$: Observable<TotalCostWithDiscount | null>;
  calculateTotalLPCostByCampanyNames: TotalCost[] = [];

  //companyNamesBySubCategoriesAndTag
  locationPartnersToCreateCampaignResult$: Observable<
    LocationPartnerInterface[] | null
  >;

  getLocationPartnersCompanyNames: string[] | null;
  getLocationPartnersZoneNames: string[] | null = [];
  getLocationPartnersZones: LocationPartnerWithZone[] | null;

  selectedZones: string[] = [];

  adDetails$: Observable<AdDetailsInterface>;
  adDetails: AdDetailsInterface;
  calculateCost: CalculateCost = {
    calculationMethod: LocationSearchMethod.ZONE,
    names: [],
    objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
    startDate: null,
    endDate: null,
  };
  initialCondition: boolean = false;
  locationAvailabilityEnum = LocationAvailabilityEnum;
  constructor(
    public badgeService: BadgeService,
    private store: Store<AppStateInterface>,
    public newCampaignCalculatorService: NewCampaignCalculatorService
  ) {
    super(store);
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnersToCreateCampaignResult$ = this.store
      .pipe(select(locationPartnersToCreateCampaignResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.calculateTotalLPCostByCampanyNames$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.calculateTotalLPCostByCampanyNames$.subscribe(
      (value: TotalCostWithDiscount | null) => {
        this.notAvailableExists = false;
        if (value != null) {
          this.calculateTotalLPCostByCampanyNames = value.totalCostPerDay;
          this.selectedZones = this.calculateTotalLPCostByCampanyNames.map(e=>e.zoneName)
          value.totalCostPerDay.map((lp) => {
            if (
              lp.availabilities.availabilityStatus ==
              LocationAvailabilityEnum.NOT_AVAILABLE
            )
              this.notAvailableExists = true;
          });
        }
        
      }
    );
    this.adDetails$.subscribe((adDetails) => {
      this.adDetails = adDetails;
      if (this.adDetails != adDetails) {
        if (this.adDetails.calculationMethod != adDetails.calculationMethod)
          this.selectedZones = [];
        this.onSelectCalculateCost();
      }
    });

    this.locationPartnersToCreateCampaignResult$.subscribe(
      (result: LocationPartnerInterface[] | null) => {
        if (result != null) {
          if (result != null) {
            this.getLocationPartnersCompanyNames = result.map(
              (value) => value.companyName
            );
            this.getLocationPartnersZoneNames = [];
            this.getLocationPartnersZones = [];
            from(result)
              .pipe(
                groupBy((obj) => obj.zoneName),
                mergeMap((group$) => group$.pipe(toArray()))
              )
              .subscribe((grouped) => {
                this.getLocationPartnersZones?.push({
                  zoneName: grouped[0].zoneName,
                  locations: grouped,
                });
                this.getLocationPartnersZoneNames?.push(grouped[0]?.zoneName);
              });
          }
        }
      }
    );
  }
  onSelectCalculateCost() {
    this.initialCondition = true;
    var names: string[] = [];
    this.selectedZones.forEach((value) => {
      var locations: string[] =
        this.getLocationPartnersZones
          ?.find((zone) => zone.zoneName == value)
          ?.locations.map((location) => location.companyName) ?? [];
      locations.forEach((companyName) => {
        names.push(companyName);
      });
    });
    this.calculateCost.endDate = this.adDetails.endDate;
    this.calculateCost.startDate = this.adDetails.startDate;
    this.newCampaignCalculatorService.onSelectCalculateCost(
      this.adDetails,
      names
    );
  }

  selectAllZones(event: any) {
    if (event.target.checked)
      this.selectedZones = cloneDeep(
        this.getLocationPartnersZones?.map((zone) => zone.zoneName) ?? []
      );
    else this.selectedZones = [];
    this.onSelectCalculateCost();
  }

  CheckZoneSelectedAll(): boolean {
    return (
      this.getLocationPartnersCompanyNames?.length ==
      this.calculateCost.names.length
    );
  }
}
interface LocationPartnerWithZone {
  zoneName: string;
  locations: LocationPartnerInterface[];
}
