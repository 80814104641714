import { Component, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeLanguage } from "src/app/store/settings/settings.actions";
import { languageSelector } from "src/app/store/settings/settings.selectors";
import { languageData, LanguageInterface } from "../../../../data/languages";
@Component({
  selector: "app-language",
  templateUrl: "./language.component.html",
})
export class LanguageComponent extends BaseComponent implements OnInit {
  languages: LanguageInterface[];

  getLanguage$: Observable<LanguageInterface>;
  actualLang: LanguageInterface;

  @Input() isAuth : boolean = false
  @Input() reloadScreen : boolean = false
  constructor(
    protected translate: TranslateService,
    private store: Store<AppStateInterface>
  ) {
    super(store);
    this.getLanguage$ = this.store
      .pipe(select(languageSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.getLanguage$.subscribe((lang) => {
      this.actualLang = lang;
    });

    this.languages = languageData;
  }

  switchLang(lang: LanguageInterface) {
    this.store.dispatch(invokeLanguage({ lang: lang }));
    this.translate.use(lang.key);
    localStorage.setItem("lang", lang.key);

    if(this.reloadScreen)  window.location.reload()
  }
}
