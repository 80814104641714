import { Injectable } from "@angular/core";
import { bugReportStatus } from "../enum/bugReport-status";
import { CampaignStatusEnum } from "../enum/campaign-status";
import { OrderStatus } from "../enum/order-status";
import { PricingMethod } from "../enum/pricing-method";
import { RouterStatus } from "../enum/router-status";
import { TotalCost } from "../models/calcul/totalCost";
import { LocationAvailabilityEnum } from "../enum/location-availability";
import { RenumerationEnum } from "../enum/renumeration.enum";
import { PartnerStatus } from "../enum/partner-status";
import { InvoiceStatusEnum } from "../enum/invoicesStatus";
import { CommandeStatusEnum } from "../enum/commandeStatus";
import { PaymentFrequency } from "../enum/payment-frequency";
import { DiscountTypeEnum } from "../enum/discount-type.enum";
import { AdvertiserStatusEnum } from "../enum/advertiser-status";
import { UserHistoryActionsEnum } from "../enum/userHistoryActions";
@Injectable({
  providedIn: "root",
})
export class BadgeService {
  constructor() {}

  getBadgeByRouterStatus(status: RouterStatus): string {
    switch (status as unknown as keyof typeof RouterStatus) {
      case RouterStatus.ONLINE.toString():
        return "badge-light-blue";
      case RouterStatus.OUTOFORDER.toString():
        return "badge-light-warning";
      case RouterStatus.ON_SITE:
        return "badge-light-success";
      case RouterStatus.READY_TO_GO:
        return "badge-light-primary";
      case RouterStatus.IN_STOCK:
        return "badge-light-info";
      default:
        return "badge-light-primary";
    }
  }

  getBadgeForAdvertisingCampaingStatus(status: CampaignStatusEnum): string {
    switch (status as unknown as keyof typeof CampaignStatusEnum) {
      case CampaignStatusEnum.ONGOING.toString():
        return "badge-light-info";
      case CampaignStatusEnum.WAITING.toString():
        return "badge-light-warning";
      case CampaignStatusEnum.FINISHED.toString():
        return "badge-light-success";
      case CampaignStatusEnum.DRAFT.toString():
        return "badge-light-gray";
      case CampaignStatusEnum.AWAITING_APPROVAL.toString():
        return "badge-light-blue";
      case CampaignStatusEnum.AWAITING_PAYMENT.toString():
        return "badge-light-primary";
      case CampaignStatusEnum.CANCELED.toString():
        return "badge-light-danger";
      default:
        return "badge-light-info";
    }
  }

  getBadgeByLocationPartnerPricingMethod(status: PricingMethod) {
    switch (status as unknown as keyof typeof PricingMethod) {
      case PricingMethod.VIEWS.toString():
        return "badge-light-info";
      case PricingMethod.UNIQUE_VIEWS.toString():
        return "badge-light-primary";
      default:
        return "badge-light-gray";
    }
  }
  getBadgeForLocationPartnerAvailability(location: TotalCost): string {
    switch (location.availabilities.availabilityStatus) {
      case LocationAvailabilityEnum.AVAILABLE:
        return "badge-light-success";
      case LocationAvailabilityEnum.NOT_AVAILABLE:
        return "badge-light-danger";
      case LocationAvailabilityEnum.CURRENTLY_INSTALLING:
        return "badge-light-yallow";
      case LocationAvailabilityEnum.PARTIALLY:
        return "badge-light-warning";
    }
  }
  getBadgeByAdvertiserStatus(status: AdvertiserStatusEnum) {
    switch (status as unknown as keyof typeof AdvertiserStatusEnum) {
      case AdvertiserStatusEnum.EMAIL_VERIFICATION.toString():
        return "badge-light-warning";
      case AdvertiserStatusEnum.ENABLED.toString():
        return "badge-light-info";
      case AdvertiserStatusEnum.APPROVAL.toString():
        return "badge-light-primary";
      case AdvertiserStatusEnum.DISABLED.toString():
        return "badge-light-danger";
      default:
        return "badge-light-success";
    }
  }
  getBadgeByBugReportStatus(status: bugReportStatus) {
    switch (status as unknown as keyof typeof bugReportStatus) {
      case bugReportStatus.IN_PROGRESS:
        return "badge-light-info";
      default:
        return "badge-light-primary";
    }
  }
  getBadgeByOrderStatus(status: CampaignStatusEnum | null): string {
    switch (status as unknown as keyof typeof CampaignStatusEnum) {
      case CampaignStatusEnum.DRAFT.toString():
        return "badge-light-warning";
      case CampaignStatusEnum.AWAITING_APPROVAL.toString():
        return "badge-light-blue";
      case CampaignStatusEnum.AWAITING_PAYMENT.toString():
        return "badge-light-primary";
      case CampaignStatusEnum.CANCELED.toString():
        return "badge-light-danger";
      case null:
        return "badge-light-gray";
      default:
        return "badge-light-success";
    }
  }

  getBadgeForLPRenumeration(renumeration: RenumerationEnum) {
    switch (renumeration) {
      case RenumerationEnum.NOT_RENUMERATED:
        return "badge-light-primary";
      case RenumerationEnum.RENUMERATED:
        return "badge-light-info";
      default:
        return "badge-light-primary";
    }
  }

  getBadgeByLocationPartnerStatus(status: PartnerStatus) {
    switch (status as unknown as keyof typeof PartnerStatus) {
      case PartnerStatus.INTERNET_PROVIDER_PAYMENT:
        return "badge-light-info";
      case PartnerStatus.REVENUE_SHARING:
        return "badge-light-primary";
      default:
        return "badge-light-warning";
    }
  }
  getBadgeByLocationPartner() {
    return "badge-light-info";
  }
  getBadgeIberisStatus(status: CommandeStatusEnum) {
    switch (status) {
      case CommandeStatusEnum.DRAFT:
        return "badge-light-warning";
      case CommandeStatusEnum.AWAITING_PAYMENT:
        return "badge-light-primary";
      /*case CommandeStatusEnum.PAYÉ:
        return "badge-light-primary";*/
      default:
        return "badge-light-primary";
    }
  }

  getBadgeFactureIberisStatus(status: InvoiceStatusEnum) {
    switch (status as unknown as keyof typeof InvoiceStatusEnum) {
      case InvoiceStatusEnum.EN_RETARD:
        return "badge-light-warning";
      case InvoiceStatusEnum.VALIDE:
        return "badge-light-info";
      default:
        return "badge-light-primary";
    }
  }
  getBadgeByLocationPartnerPaymentFrequency(
    paymentFrequency: PaymentFrequency
  ) {
    switch (paymentFrequency as unknown as keyof typeof PaymentFrequency) {
      case PaymentFrequency.ANNUAL:
        return "badge-light-primary";
      case PaymentFrequency.MONTHLY:
        return "badge-light-info";
      case PaymentFrequency.QUARTERLY:
        return "badge-light-warning";
      case PaymentFrequency.SEMI_ANNUALLY:
        return "badge-light-blue";
      default:
        return "badge-light-gray";
    }
  }

  getBadgeForDiscountType(discount: DiscountTypeEnum) {
    switch (discount) {
      case DiscountTypeEnum.PERCENTAGE:
        return "badge-light-primary";
      case DiscountTypeEnum.AMOUNT:
        return "badge-light-info";
      default:
        return "badge-light-danger";
    }
  }
  getNoneType() {
    return "badge-light-info";
  }

  getBadgeForActionLogger(action: UserHistoryActionsEnum): string {
    switch (action as unknown as keyof typeof UserHistoryActionsEnum) {
      case UserHistoryActionsEnum.GET.toString():
        return "badge-light-info";
      case UserHistoryActionsEnum.DOWNLOAD.toString():
        return "badge-light-warning";
      case UserHistoryActionsEnum.POST.toString():
        return "badge-light-success";
      case UserHistoryActionsEnum.UPLOAD.toString():
        return "badge-light-gray";
      case UserHistoryActionsEnum.PATCH.toString():
        return "badge-light-primary";
      case UserHistoryActionsEnum.DELETE.toString():
        return "badge-light-danger";
      default:
        return "badge-light-info";
    }
  }
}
