import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { Account } from "src/app/shared/models/user/account";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeAccount,
  invokeLogin,
  loginSuccess,
} from "src/app/store/auth/auth.actions";
import {
  accountSelector,
  authLoadingSelector,
  userIsLoggedInSelector,
} from "src/app/store/auth/auth.selectors";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { NavigatorService } from "src/app/shared/services/navigator/navigator.service";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { RecaptchaService } from "src/app/shared/services/recaptcha.service";
import { LanguageInterface } from "src/app/shared/data/languages";
import { languageSelector } from "src/app/store/settings/settings.selectors";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends BaseComponent implements OnInit {
  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
  isSuccess$: Observable<string | null>;

  loading$: Observable<boolean | null>;
  loading: boolean | null = false;
  submitted: boolean;
  account$: Observable<Account | null>;
  advertiserInfo$: Observable<AdvertiserDetailsInterface>;
  advertisser: AdvertiserDetailsInterface;
  public validate = false;

  form: FormGroup;

  public show: boolean = false;
  apiStatus: string;

  ipAddress: string | null = null;

  reCaptchaKey = environment.RECAPTCHA_PUBLIC_KEY;
  isValidateCaptcha: boolean | null = null;

  getLanguage$: Observable<LanguageInterface>;
  actualLang: string;

  @ViewChild("recaptchaContainer", { static: true })
  recaptchaContainer!: ElementRef;

  scriptId = "recaptcha-script"; // ID unique pour le script reCAPTCHA
  recaptchaContainerId = "recaptcha-container";
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppStateInterface>,
    private nagivatorService: NavigatorService,
    private captchaService: RecaptchaService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    super(store);

    this.isSuccess$ = this.store
      .pipe(select(userIsLoggedInSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(authLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.account$ = this.store
      .pipe(select(accountSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.getLanguage$ = this.store
      .pipe(select(languageSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
          Validators.pattern("^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*?\\s).{8,}$"),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.getLanguage$.subscribe((lang) => {
      this.actualLang = lang.key;
      this.loadRecaptchaScript(this.actualLang);
    });

    this.nagivatorService.getIpAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });

    this.isSuccess$.subscribe((token) => {
      if (token != null) {
        localStorage.setItem("token", token);
        this.store.dispatch(invokeAccount());
      } else {
        this.store.dispatch(loginSuccess({ token: null }));
      }
    });
    this.loading$.subscribe((loading) => {});
    this.account$.subscribe((account) => {
      if (account?.role && account.user) {
        this.router.navigate(["dashboard"]);
      }
    });
  }

    // an acceptable version
  loadRecaptchaScript(lang: string): void {
    const existingScript = document.getElementById(this.scriptId);
    if (existingScript) {
      existingScript.remove();
    }
    const parentContainer = document.getElementById("recaptcha-parent");
    if (parentContainer) {
      const oldContainer = document.getElementById("recaptcha-container");
      if (oldContainer) {
        oldContainer.remove();
      }
      const newContainer = document.createElement("div");
      newContainer.id = "recaptcha-container";
      parentContainer.appendChild(newContainer);
    }
    const script = document.createElement("script");
    script.id = this.scriptId;
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit&hl=${lang}`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      this.renderRecaptchaWidget();
    };

    document.body.appendChild(script);
  }

  renderRecaptchaWidget(): void {
    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaContainer) {
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha.render("recaptcha-container", {
          sitekey: this.reCaptchaKey,
          callback: (response: string) => {
            this.isValidateCaptcha = true;
            this.cdRef.detectChanges()
          },
          "expired-callback": () => {
            this.isValidateCaptcha = false;
            this.cdRef.detectChanges()
          },
          "error-callback": () => {
            this.isValidateCaptcha = false;
            this.cdRef.detectChanges()
          },
        });
      });
    }
  }
  /*recaptchaInstance: any = null;

  loadRecaptchaScript(lang: string): void {
    // Supprimer complètement le script reCAPTCHA existant et l'élément DOM associé
    this.removeRecaptchaScript().then(() => {
      const recaptchaContainer = document.getElementById(
        this.recaptchaContainerId
      );
      if (recaptchaContainer) {
        // Crée un nouvel élément pour remplacer l'ancien conteneur
        const newRecaptchaContainer = document.createElement("div");
        newRecaptchaContainer.id = this.recaptchaContainerId;
        recaptchaContainer.replaceWith(newRecaptchaContainer);
      }

      // Créer un nouveau script pour reCAPTCHA
      const script = document.createElement("script");
      script.id = this.scriptId;
      script.src = `https://www.google.com/recaptcha/api.js?render=explicit&hl=${lang}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.renderRecaptchaWidget();
      };
      script.onerror = () => {
        console.error("Erreur de chargement du script");
      };

      document.body.appendChild(script); // Ajouter le script à la page
    });
  }

  removeRecaptchaScript(): Promise<void> {
    return new Promise((resolve) => {
      const existingScript = document.getElementById(this.scriptId);
      if (existingScript) {
        // Réinitialiser le widget reCAPTCHA existant avant de retirer le script
        if (this.recaptchaInstance) {
          (window as any).grecaptcha.reset(this.recaptchaInstance);
          this.recaptchaInstance = null; // Réinitialiser l'instance pour permettre une nouvelle création
        }
        existingScript.remove();
      }
      resolve();
    });
  }

  renderRecaptchaWidget(): void {
    const recaptchaContainer = document.getElementById(
      this.recaptchaContainerId
    );

    if (recaptchaContainer) {
      // Vérifier si un widget existe déjà et le supprimer
      if (this.recaptchaInstance) {
        (window as any).grecaptcha.reset(this.recaptchaInstance);
        this.recaptchaInstance = null; // Réinitialiser l'instance pour permettre une nouvelle création
      }

      (window as any).grecaptcha.ready(() => {
        console.log("reCAPTCHA prêt");
        this.recaptchaInstance = (window as any).grecaptcha.render(
          this.recaptchaContainerId,
          {
            sitekey: this.reCaptchaKey,
            callback: (response: string) => {
              console.log("reCAPTCHA résolu:", response);
            },
            "expired-callback": () => {
              console.log("reCAPTCHA expiré.");
            },
            "error-callback": () => {
              console.error("Erreur avec reCAPTCHA.");
            },
          }
        );
      });
    } else {
      console.error("Conteneur reCAPTCHA introuvable");
    }
  }*/
    
  get email() {
    return this.form.get("email");
  }
  get password() {
    return this.form.get("password");
  }

  login(event: any) {
    this.submitted = true;
    this.loading = true;
    this.validate = !this.form.valid;
    if (this.form.valid) {
      this.loading = true;
      let user = this.form.value;
      this.store.dispatch(
        invokeLogin({
          email: user.email.toLowerCase(),
          password: user.password,
          ipAddress: this.ipAddress,
        })
      );
    }
    if (this.form.invalid) {
      return;
    }
  }
  showPassword() {
    this.show = !this.show;
  }

  resolved(token: string) {
    if (token) {
      this.isValidateCaptcha = true;
    }
    this.captchaService.validateCaptcha(token).subscribe({
      next: (response) => {
        //this.isValidateCaptcha = response;
        console.log("isValidateCaptcha", response);
      },
    });
  }

  onExpired() {
    this.isValidateCaptcha = false;
  }

  onError() {
    this.isValidateCaptcha = false;
  }

  getToken(token: any) {
    console.log(token);
  }
}
