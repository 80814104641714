import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../../data/constants";
import { CreateClient } from "../../models/iberis/client/createClient";
import { ResultCreateClient } from "../../models/iberis/client/resultCreateClient";
import { ResultListClientsFromIberis } from "../../models/iberis/client/resultListClientFromIbris";
import { CommonParamsIberis } from "../../models/iberis/paramsForGetAll";

@Injectable({
  providedIn: "root",
})
export class ClientIberisService {
  constructor(private http: HttpClient) {}
  getClientByHashedId(
    params: CommonParamsIberis,
    clientId : string | null
  ): Observable<ResultListClientsFromIberis> {
    return this.http.get<ResultListClientsFromIberis>(
      `${environment.APP_PORT_IBERIS + 
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/client/${clientId}/synthesis`
    );
  }

  createClient(
    params: CommonParamsIberis,
    createClient : CreateClient
  ): Observable<ResultCreateClient> {
    let param = new HttpParams();
    param = param.append("first_name", createClient.first_name);
    param = param.append("last_name", createClient.last_name);
    param = param.append("company", createClient.company);
    param = param.append("display_name", createClient.display_name);
    param = param.append("email", createClient.email);
    param = param.append("phone", createClient.phone);
    param = param.append("website", createClient.website);
    param = param.append("activity_id", createClient.activity_id);
    param = param.append("currency_id", createClient.currency_id);
    param = param.append("deadline_id", createClient.deadline_id);
    param = param.append("title", createClient.title);
    param = param.append("reference", createClient.reference);
    param = param.append("price_list_id", createClient.price_list_id)
    return this.http.post<ResultCreateClient>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/client/new?${param}`,
      null
    );
  }

  updateClient(
    params: CommonParamsIberis,
    updateClient : CreateClient,
    clientId : string | null
  ): Observable<ResultCreateClient> {
    let param = new HttpParams();
    param = param.append("first_name", updateClient.first_name);
    param = param.append("last_name", updateClient.last_name);
    param = param.append("company", updateClient.company);
    param = param.append("display_name", updateClient.display_name);
    param = param.append("bill_address", updateClient.bill_address);
    param = param.append("email", updateClient.email);
    param = param.append("fiscal_id" , updateClient.fiscal_id)
    param = param.append("phone", updateClient.phone);
    param = param.append("website", updateClient.website);
    param = param.append("activity_id", updateClient.activity_id);
    param = param.append("currency_id", updateClient.currency_id);
    param = param.append("deadline_id", updateClient.deadline_id);
    param = param.append("title", updateClient.title);
    param = param.append("reference", updateClient.reference);
    param = param.append("price_list_id", updateClient.price_list_id)
    return this.http.post<ResultCreateClient>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/client/${clientId}/edit?${param}`,
      null
    );
  }
}
