<div class="row">
  <div class="col-xxl-9 col-lg-8 col-12">
    <div class="card rounded">
      <div class="card-header">
        <h4 class="m-b-0">
          {{ "admin.campaigns.simulator.title" | translate }}
        </h4>
      </div>
      <div class="card-body p-t-0">
        <form [formGroup]="form">
          <label class="col-form-label label"
            >{{
              "admin.campaigns.simulator.objectiveName" | translate
            }}
            *</label
          >
          <select
            class="form-select"
            formControlName="objective"
            id="validationCustom04"
          >
            <option selected="" disabled="" value>
              {{ "placeholder.placeholderSelect" | translate }}
            </option>
            <option
              *ngFor="let item of allObjectiveNames"
              [ngValue]="item.value"
            >
              {{ item.value | translate }}
            </option>
          </select>
          <div class="row">
            <div class="col-6 m-b-15 m-t-5">
              <label class="col-form-label label" for="Start Date"
                >{{
                  "admin.campaigns.simulator.startDate" | translate
                }}
                *</label
              >
              <div class="input-group">
                <div
                  class="input-group date"
                  id="dt-date"
                  data-target-input="nearest"
                >
                  <input
                    class="form-control datetimepicker-input digits"
                    type="text"
                    [owlDateTime]="dt"
                    readonly
                    placeholder="yyyy-mm-dd"
                    formControlName="startDate"
                  />
                  <div
                    class="input-group-text bg-primary"
                    data-target="#dt-date"
                    [owlDateTimeTrigger]="dt"
                  >
                    <i
                      class="icofont icofont-ui-calendar"
                      style="color: white; font-size: 12px"
                    ></i>
                  </div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
              </div>
            </div>
            <div class="col-6 m-b-15 m-t-5">
              <label class="col-form-label label"
                >{{ "admin.campaigns.simulator.endDate" | translate }} *</label
              >
              <div class="input-group">
                <div
                  class="input-group date"
                  id="dt-date"
                  data-target-input="nearest"
                >
                  <input
                    class="form-control datetimepicker-input digits"
                    type="text"
                    [owlDateTime]="dt1"
                    readonly
                    [(ngModel)]="endDate"
                    placeholder="yyyy-mm-dd"
                    formControlName="endDate"
                  />
                  <div
                    class="input-group-text bg-primary"
                    data-target="#dt-date"
                    [owlDateTimeTrigger]="dt1"
                  >
                    <i
                      class="icofont icofont-ui-calendar"
                      style="color: white; font-size: 12px"
                    ></i>
                  </div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between m-t-10">
            <label class="col-form-label label"
              >{{ "admin.campaigns.simulator.locations" | translate }} *</label
            >
            <a
              type="button"
              (click)="onClickSelectLps()"
              class="color-primary font-a"
              >{{ "admin.campaigns.simulator.addLocations" | translate }}</a
            >
          </div>
          <div
            class="col-xxl-12 col-lg-12 box-col-12 box-container m-b-10"
            *ngIf="selectedLps.companyNames.length > 0 && totalCost.length == 0"
          >
            <ng-container *ngFor="let item of selectedLps.companyNames"
              ><span
                style="font-size: 10px"
                data-bs-toggle="tooltip"
                placement="bottom"
                [ngClass]="'badge ' + badgeService.getBadgeByLocationPartner()"
                >{{ item }}
              </span></ng-container
            >
          </div>
          <div
            class="col-xxl-12 col-lg-12 box-col-12 box-container m-b-10"
            *ngIf="selectedLps.companyNames.length > 0 && totalCost.length > 0"
          >
            <ng-container *ngFor="let item of totalCost"
              ><span
                data-bs-toggle="tooltip"
                placement="bottom"
                [ngbTooltip]="tooltipAvailability"
                (mouseenter)="locationSelected = item"
                [ngClass]="
                  'badge ' +
                  badgeService.getBadgeForLocationPartnerAvailability(item)
                "
                ><i
                  class="icofont icofont-tools-alt-2"
                  *ngIf="
                    item.availabilities.availabilityStatus ==
                    locationAvailabilityEnum.CURRENTLY_INSTALLING
                  "
                ></i
                >{{ item.companyName }}
              </span></ng-container
            >

            <ng-template #tooltipAvailability>
              <div>
                {{
                  "enumMapping.locationAvailibility." +
                    locationSelected.availabilities.availabilityStatus
                    | translate
                }}
              </div>
            </ng-template>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-primary m-r-20"
          (click)="onClickCalculate()"
          [disabled]="form.invalid || selectedLps.companyNames.length == 0"
        >
          <loading-button
            [loading]="loading"
            [value]="'buttons.calculate'"
            [icon]="null"
          ></loading-button>
        </button>
        <button class="btn btn-outline-primary" (click)="onClickReset()">
          {{ "buttons.return" | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- Calculate-->
  <div class="col-xxl-3 col-lg-4 col-6">
    <div class="card rounded m-b-10">
      <div class="card-header">
        <h6 class="f-w-600" style="font-style: 14px">
          {{ "admin.campaigns.simulator.preview" | translate }}
        </h6>
        <hr />
        <div class="row">
          <div class="col-12">
            <p class="margin-p">
              <span class="f-w-600"
                >{{
                  "admin.campaigns.simulator.objective" | translate
                }}
                : </span
              ><span class="f-w-300">
                {{ form.get("objective")?.value | translate }}
              </span>
            </p>
          </div>
          <div class="col-12">
            <p class="margin-p">
              <span class="f-w-600"
                >{{ "admin.campaigns.simulator.duration" | translate }} : </span
              ><span class="f-w-300"
                >{{
                  form.get("startDate")?.value
                    | daysBetweenDates : form.get("endDate")?.value
                }}
                {{ "info.days" | translate }}</span
              >
            </p>
          </div>
          <div class="col-12">
            <p class="margin-p">
              <span class="f-w-600"
                >{{ "admin.campaigns.simulator.locations" | translate }} :
              </span>
              <span class="f-w-300" *ngFor="let lp of selectedLps.companyNames">
                {{ lp }},&ThickSpace;
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card rounded">
      <div class="card-header">
        <h6 class="f-w-600" style="font-style: 14px">
          {{ "admin.campaigns.simulator.result" | translate }}
        </h6>
        <hr />
        <div class="row">
          <div class="col-12">
            <p class="margin-p">
              <span class="f-w-600"
                >{{
                  "admin.campaigns.simulator.estimatedPrice" | translate
                }}
                : </span
              ><span class="f-w-300"
                >{{ total.totalPrice.toFixed(3) }} TND</span
              >
            </p>
          </div>
          <div class="col-12">
            <p class="margin-p">
              <span class="f-w-600"
                >{{
                  "admin.campaigns.simulator.estimatedImpressions" | translate
                }}
                : </span
              ><span class="f-w-300">{{
                total.totalAverageViews | toKValue
              }}</span>
            </p>
          </div>
          <div class="col-12">
            <p class="margin-p">
              <span class="f-w-600"
                >{{
                  "admin.campaigns.simulator.priceByImpression" | translate
                }}
                : </span
              ><span class="f-w-300">
                {{ costPerImpression.toFixed(3) }} TND</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="totalCost.length > 0 && form.valid">
  <div class="card">
    <div class="card-body">
      <div class="table-responsive data-table">
        <form>
          <table class="table table-striped table-border-vertical">
            <thead>
              <tr>
                <ng-container *ngFor="let column of columns">
                  <th>
                    {{ column.label | translate }}
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of totalCost">
                <td>{{ item.companyName }}</td>
                <td>{{ item.uniqueViewsPerDay }}</td>
                <td>{{ item.viewsPerDay }}</td>
                <td>{{ item.costPerDay.toFixed(3) }}</td>
                <td>{{ item.marginPerDay.toFixed(3) }}</td>
                <td>{{ item.sellingPrice.toFixed(3) }}</td>
              </tr>
              <tr class="bg-light-primary f-w-600" style="font-size: 16">
                <td>{{ "admin.campaigns.simulator.total" | translate }}</td>
                <td>{{ total.totalAverageUniqueViewsPerDay }}</td>
                <td>{{ total.totalAverageViews }}</td>
                <td>{{ total.totalCostPerDay.toFixed(3) }}</td>
                <td>{{ total.totalMarginPerDay.toFixed(3) }}</td>
                <td>{{ total.totalPrice.toFixed(3) }}</td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>
