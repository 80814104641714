<div class="card">
  <div class="card-header pb-0">
    <div class="media-space-between">
      <h5>{{ "admin.history.title" | translate }}</h5>
      <div class="media-body">
        <form class="d-inline-flex" [formGroup]="formFilter">
          <div style="margin-right: 5px">
            <div
              class="input-group date"
              style="width: 17rem"
              id="dt-date"
              data-target-input="nearest"
            >
              <div
                class="input-group-text bg-primary"
                data-target="#dt-date"
                [owlDateTimeTrigger]="dt"
              >
                <i
                  class="icofont icofont-ui-calendar"
                  style="color: white; font-size: 12px"
                ></i>
              </div>
              <input
                formControlName="date"
                style="font-size: 12px"
                class="form-control datetimepicker-input digits"
                type="text"
                name="daterange"
                [placeholder]="'placehorderPickDate' | translate"
                [selectMode]="'range'"
                [owlDateTimeTrigger]="dt"
                [owlDateTime]="dt"
              />
            </div>
            <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
          </div>
          <div>
            <div class="input-group" style="width: 15rem">
              <input
                class="form-control"
                formControlName="emailOrNameOrCompanyName"
                type="text"
                [placeholder]="'placeholder.search' | translate"
              />
              <span
                class="input-group-text p-0"
                style="background-color: transparent"
              >
                <app-general-filter
                  [filter]="historyFilter"
                  (optionSelected)="saveOptionFilter($event)"
                ></app-general-filter>
              </span>
            </div>
          </div>
          <button
            class="btn btn-primary m-l-10"
            type="button"
            (click)="removeFilter()"
          >
            <i class="fa fa-repeat me-2"></i>
            {{ "buttons.removeFilter" | translate }}
          </button>
        </form>
      </div>
    </div>
    <div class="d-flex my-3">
      <button
        *ngIf="cachedAt"
        class="btn badge badge-light text-dark"
        type="button"
        (click)="invokeHistory(true)"
      >
        <i class="icofont icofont-refresh"></i>
        <span>
          {{ "cachedAt" | translate }}
          {{ cachedAt | localizedDate : "short" }}
        </span>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive data-table">
      <form>
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <th>{{ "admin.history.fullName" | translate }}</th>
              <ng-container *ngFor="let column of sortedColumns">
                <th
                  sortable
                  sortable="{{ column.key }}"
                  (sort)="onSort($event)"
                >
                  {{ column.label | translate }}
                </th>
              </ng-container>
              <ng-container *ngFor="let column of columns">
                <th>
                  {{ column.label | translate }}
                </th>
              </ng-container>
              <ng-container *ngFor="let column of dateColumn">
                <th
                  sortable
                  sortable="{{ column.key }}"
                  (sort)="onSort($event)"
                >
                  {{ column.label | translate }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody *ngIf="!loading">
            <tr *ngFor="let history of historyResult.history">
              <td>{{ history.firstName }} {{ history.lastName }}</td>
              <td>
                {{ history.email }}
              </td>
              <td>
                {{ history.companyName }}
              </td>
              <td>
                {{ history.subRoleName }}
              </td>
              <td>
                {{ history.ipAddress }}
              </td>
              <td *ngIf="history.action">
                <span
                  [ngClass]="
                    'badge ' +
                    badgeService.getBadgeForActionLogger(history.action)
                  "
                >
                  {{
                    "enumMapping.history.action." + history.action | translate
                  }}
                </span>
              </td>
              <td>
                {{ "userHistoryServices." + history.endPoint | translate }}
              </td>
              <td>
                {{ history.creationDate | localizedDate : "short" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="forbidden" class="d-flex justify-content-center m-t-20">
          {{ "response.error.FORBIDDEN_RESPONSE" | translate }}
        </div>
        <div
          *ngIf="historyResult.count == 0 && !loading && !forbidden"
          class="d-flex justify-content-center m-t-20"
        >
          {{ "noData" | translate }}
        </div>
        <div *ngIf="loading">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
        <div class="media">
          <div class="col-3"></div>
          <div class="col-6">
            <div class="d-flex justify-content-center p-3">
              <ngb-pagination
                [collectionSize]="historyResult.count!"
                [(page)]="filter.pager.pageNumber"
                [pageSize]="filter.pager.pageSize"
                [maxSize]="10"
                (pageChange)="changePage($event)"
                [boundaryLinks]="true"
                [ellipses]="true"
              ></ngb-pagination>
            </div>
          </div>
          <div class="col-3">
            <div class="d-flex justify-content-end p-3">
              <select
                class="form-select"
                style="width: auto"
                name="pageSize"
                [(ngModel)]="changePageSize"
              >
                <option [ngValue]="15">
                  15 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="30">
                  30 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="50">
                  50 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="historyResult.count">
                  {{ "pagination.allItems" | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
