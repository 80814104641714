import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { Constants } from "src/app/shared/data/constants";
import { AudienceType } from "src/app/shared/enum/chart/audiance";
import { TechnicalCardSearchMethodEnum } from "src/app/shared/enum/chart/technical-data";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { DateFilter } from "src/app/shared/models/date-filte.interface";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import { InputAudience } from "src/app/shared/models/input-audience.interface";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import { ResultGlobalStatisticsForLP } from "src/app/shared/models/statistics/resultGlobalStaticsForLP";
import { RetentionRate } from "src/app/shared/models/statistics/retentionRate";
import { TechnicalCardSearchMethod } from "src/app/shared/models/statistics/technical-data";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeOsFamily,
  invokeBrowserFamily,
  InvokeDeviceType,
  invokeDeviceBrand,
} from "src/app/store/consumers/consumers.actions";
import {
  invokeGetLocationPartnerByCompanyName,
  resultLPInfo,
} from "src/app/store/locationPartner/actions/get-locations.actions";
import { resultLPInfoSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { resultSindingMail } from "src/app/store/mailing/mailing.actions";
import { sendMailSelector } from "src/app/store/mailing/mailing.selectors";
import {
  resultGlobalStatisticsForLP,
  resultRetentionRateByWeeks,
  resultRetainedConsumers,
  resultUniqueConsumers,
  resultRetentionTime,
  invokeGlobalStatisticsForLP,
  invokeRetainedConsumers,
  invokeRetentionRateByWeeks,
  invokeRetentionTime,
  invokeSummaryStaticsInDateBetweenForLP,
  invokeUniqueConsumers,
  invokeViewsByGenderDemographicForLocationPartner,
} from "src/app/store/statistics/statistics.actions";
import {
  resultUniqueConsumersSelector,
  resultGlobalStatisticsForLPSelector,
  loadingSummaryStatisticsSelector,
  loadingConsumersSelector,
  resultRetentionRateByWeeksSelectors,
  loadingGlobalStaticsSelector,
  resutlRetainedConsumersSelector,
  resultRetentionTimeSelector,
  loadingresultRetentionTimeSelector,
  loadingRetainedConsumersSelector,
} from "src/app/store/statistics/statistics.selectors";
import { catchForbiddenError, catchServerError } from "src/app/store/user/user.actions";
import { catchForbiddenErrorSelector, catchServerErrorSelector } from "src/app/store/user/user.selectors";

@Component({
  selector: "app-location-statistics",
  templateUrl: "./location-statistics.component.html",
})
export class LocationStatisticsComponent
  extends BaseComponent
  implements OnInit
{
  @Output() retentionRate = new EventEmitter<RetentionRate | null>();
  @Output() search = new EventEmitter<TechnicalCardSearchMethod>();
  loading$: Observable<boolean | null>;
  Retention_time = {
    value: "",
    title: "location.statistics.retentionTime",
    icon: "assets/identity/retention.svg",
    unit: 0,
  };
  consummers = {
    value: 0,
    title: "location.statistics.consumers",
    icon: "assets/identity/consummers.svg",
    unit: 0,
  };
  impression = {
    value: 0,
    title: "location.statistics.impression",
    icon: "assets/identity/eye-icon.svg",
    unit: 0,
  };
  uniqueViews = {
    value: 0,
    title: "location.statistics.uniqueViews",
    icon: "assets/identity/uniqueViews.svg",
    unit: 0,
  };

  columnChart = {
    title: "Données démographiques",
    yaxisTitle: this.translate.instant("Charts.retentionRate.yaxisTitle"),
    xaxisTitle: this.translate.instant("Charts.retentionRate.weeks"),
  };

  @Output() inputAudience = new EventEmitter<InputAudience>();
  uniqueConsumers$: Observable<number | null>;
  uniqueConsumers: number;

  form: FormGroup;
  lpProfile$: Observable<LPInfo | null>;
  lpProfile: LPInfo;
  resultGlobalStaticsForLP$: Observable<ResultGlobalStatisticsForLP | null>;
  globalStatics: ResultGlobalStatisticsForLP | null;
  loadingConsumers$: Observable<boolean | null>;
  resultRetentionRate$: Observable<RetentionRate | null>;
  loadingGlobalStatics$: Observable<boolean | null>;
  resultRetainedConsumers$: Observable<number>;
  resultRetainedConsumers: number | null = null;
  resultRetentionTime$: Observable<number[] | null>;
  loadingRetentionTime$: Observable<boolean | null>;
  loadingReteinedConsumers$: Observable<boolean | null>;
  dateFilter: DateFilter = {
    startDate: null,
    endDate: null,
  };

  companyActivities: IberisActivities[];

  retentionChart: any;
  companyName: string | null;
  resultSendStatics$: Observable<SuccessDto | null>;
  retentionRateCachedAt: Date | null;
  cachedAt: Date | null;
  loadingReteinedConsumers: boolean | null;
  isLoading: boolean;
  catchForbiddenError$: Observable<boolean | null>;
  forbidden : boolean | null = false
  loadingProfile : boolean = true
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private store: Store<AppStateInterface>,
    private translate: TranslateService,
    private toastr: ToastrService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.companyName = this.route.snapshot.queryParamMap.get("companyName");
    if (this.companyName == null) this.router.navigate(["/dashboard"]);

    this.uniqueConsumers$ = this.store
      .pipe(select(resultUniqueConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultGlobalStaticsForLP$ = this.store
      .pipe(select(resultGlobalStatisticsForLPSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingSummaryStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingConsumers$ = this.store
      .pipe(select(loadingConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultRetentionRate$ = this.store
      .pipe(select(resultRetentionRateByWeeksSelectors))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingGlobalStatics$ = this.store
      .pipe(select(loadingGlobalStaticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultRetainedConsumers$ = this.store.pipe(
      select(resutlRetainedConsumersSelector)
    );
    this.resultRetentionTime$ = this.store
      .pipe(select(resultRetentionTimeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.lpProfile$ = this.store
      .pipe(select(resultLPInfoSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingRetentionTime$ = this.store
      .pipe(select(loadingresultRetentionTimeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingReteinedConsumers$ = this.store
      .pipe(select(loadingRetainedConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSendStatics$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group({
      date: [[null, null]],
    });
  }
  ngAfterViewInit(): void {
    this.inputAudience.emit({
      name: this.companyName,
      type: AudienceType.LOCATION,
      title: "Audiences",
      titleSpline: "invoices.summary",
      titleColumnChart: "invoices.distributionAgeGender",
    });
    this.search.emit({
      technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
      value: this.companyName ?? "",
      locationPartner: null,
      finished: false,
    });
  }
  ngOnInit() {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.forbidden = true;
        this.loadingProfile = false
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.loadingReteinedConsumers$.subscribe((data) => {
      this.loadingReteinedConsumers = data;
    });
    this.resultSendStatics$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.store.dispatch(resultSindingMail({ successMessage: null }));
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );
      }
    });
    if (this.translate.currentLang == "fr")
      this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
    else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;

    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        if (langChangeEvent.lang == "fr")
          this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
        else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;
      }
    );
    this.store.dispatch(
      resultGlobalStatisticsForLP({ resultGlobalStatisticsForLP: null })
    );
    this.store.dispatch(resultRetentionRateByWeeks({ retentionRate: null }));
    this.store.dispatch(resultLPInfo({ lpInfo: null }));
    this.store.dispatch(resultRetainedConsumers({ consumers: 0 }));
    this.store.dispatch(resultUniqueConsumers({ uniqueConsommers: null }));
    this.store.dispatch(resultRetentionTime({ retentionTime: null }));
    this.store.dispatch(
      invokeGetLocationPartnerByCompanyName({
        companyName: this.companyName ?? "",
      })
    );

    this.lpProfile$.subscribe((location: LPInfo | null) => {
      if (location) {
        this.loadingProfile = false
        this.lpProfile = {
          ...location,
          companyActivity:
            this.companyActivities.find(
              (value) => location.companyActivity == value.hashed_id
            )?.title ?? "",
        };
        this.store.dispatch(
          invokeRetentionTime({ email: location.email, refreshCache: false })
        );
        this.store.dispatch(
          invokeRetainedConsumers({
            email: location.email,
            refreshCache: false,
          })
        );
        this.store.dispatch(
          invokeUniqueConsumers({
            email: location.email,
            isRefreshCache: false,
          })
        );
      }
    });
    this.store.dispatch(
      invokeGlobalStatisticsForLP({
        companyName: this.companyName ?? "",
        refreshCache: false,
      })
    );
    this.fetchRetentionRate(false);
    const dateFilte = { startDate: null, endDate: null };
    this.store.dispatch(
      invokeSummaryStaticsInDateBetweenForLP({
        companyName: this.companyName ?? "",
        dateFilter: dateFilte,
        refreshCache: false,
      })
    );
    this.store.dispatch(
      invokeViewsByGenderDemographicForLocationPartner({
        companyName: this.companyName ?? "",
        forMap: false,
        dateFilter: dateFilte,
        refreshCache: false,
      })
    );
    this.store.dispatch(
      invokeOsFamily({
        search: {
          technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
          value: this.companyName ?? "",
          locationPartner: null,
          finished: false,
        },
        refreshCache: false,
      })
    );
    this.store.dispatch(
      invokeBrowserFamily({
        search: {
          technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
          value: this.companyName ?? "",
          locationPartner: null,
          finished: false,
        },
        refreshCache: false,
      })
    );
    this.store.dispatch(
      InvokeDeviceType({
        search: {
          technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
          value: this.companyName ?? "",
          locationPartner: null,
          finished: false,
        },
        refreshCache: false,
      })
    );
    this.store.dispatch(
      invokeDeviceBrand({
        search: {
          technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
          value: this.companyName ?? "",
          locationPartner: null,
          finished: false,
        },
        refreshCache: false,
      })
    );

    this.resultGlobalStaticsForLP$.subscribe(
      (result: ResultGlobalStatisticsForLP | null) => {
        if (result != null) {
          this.globalStatics = result;
          this.impression.value = result.views;
          this.uniqueViews.value = result.uniqueViews;
          this.cachedAt = result.cachedAt;
        }
      }
    );
    this.resultRetentionRate$.subscribe((result: RetentionRate | null) => {
      if (result != null) {
        this.retentionRateCachedAt = result.cachedAt;
        this.retentionRate.emit(result);
      }
    });
    this.resultRetainedConsumers$.subscribe((result: number) => {
      if (result) {
        this.resultRetainedConsumers = result;
      }
    });
    this.uniqueConsumers$.subscribe((result: number | null) => {
      if (result != null) {
        this.uniqueConsumers = result;
        this.consummers.value = result;
      }
    });
    this.resultRetentionTime$.subscribe((result) => {
      if (result) {
        const sum = result.reduce((acc, num) => acc + num, 0) / result.length;
        const days = Math.floor(sum);
        const remainingHoursDecimal = (sum - days) * 24;
        const hours = Math.floor(remainingHoursDecimal);
        const remainingMinutesDecimal = (remainingHoursDecimal - hours) * 60;
        const minutes = Math.round(remainingMinutesDecimal);
        this.Retention_time.value =
          days + " jours " + hours + " heures " + minutes + " minute ";
      }
    });

    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.LOCATION_PARTNER_STATISTICS
    );
  }

  getChartInDate() {
    this.dateFilter = {
      startDate: this.form.get("date")?.value[0],
      endDate: this.form.get("date")?.value[1],
    };
    this.fetchRetentionRate(false);
  }

  fetchRetentionRate(refreshCache: boolean) {
    this.isLoading = true;
    this.store.dispatch(
      invokeRetentionRateByWeeks({
        companyName: this.companyName ?? "",
        dateFilter: this.dateFilter,
        refreshCache: refreshCache,
      })
    );
  }

  fetchCardsStatistics(refreshCache: boolean) {
    this.store.dispatch(
      invokeRetentionTime({
        email: this.lpProfile.email,
        refreshCache: refreshCache,
      })
    );
    this.store.dispatch(
      invokeRetainedConsumers({
        email: this.lpProfile.email,
        refreshCache: refreshCache,
      })
    );
    this.store.dispatch(
      invokeUniqueConsumers({
        email: this.lpProfile.email,
        isRefreshCache: refreshCache,
      })
    );
    this.store.dispatch(
      invokeGlobalStatisticsForLP({
        companyName: this.companyName ?? "",
        refreshCache: refreshCache,
      })
    );
  }
}
