<div class="card-body">
  <div id="spline">
    <div class="media-space-between">
      <a *ngIf="click != 0 && length!=0" type="button" (click)="initialChart()">
        {{ "buttons.return" | translate }}
      </a>
      <div *ngIf="day">{{ day | localizedDate }}</div>
    </div>
    <apx-chart
      #chartApex
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [legend]="chartOptions.legend"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [title]="chartOptions.title"
      [subtitle]="chartOptions.subtitle"
      *ngIf="(!consumersLoading || !loading) && length > 0"
    ></apx-chart>
  </div>
  <div *ngIf="forbidden" class="d-flex justify-content-center m-t-20">
    {{ "response.error.FORBIDDEN_RESPONSE" | translate }}
  </div>
  <div
    class="text-center"
    *ngIf="(consumersLoading || !loading) && (!consumersLoading || loading) && !forbidden"
  >
    <div class="loader-box">
      <div class="loader-34"></div>
    </div>
    <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
  </div>
  <div *ngIf="length == 0 && (!consumersLoading || !loading)  && !forbidden" class="d-flex justify-content-center m-t-20">
    {{"noData" | translate}}
  </div>
</div>
