<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <div class="form-group">
    <h5 style="text-align: center">
      {{ "register.businessInformations.title" | translate }}
    </h5>
    <label>
      {{ "register.businessInformations.companyName" | translate }} *</label
    >
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/brandName.svg" alt="icone brand name" />
      </span>
      <input
        formControlName="companyName"
        class="form-control"
        type="text"
        [placeholder]="
          'register.businessInformations.placeholder.companyName' | translate
        "
        required="required"
        [ngClass]="{
          'is-invalid':
            !form.get('companyName')?.valid && form.get('companyName')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('companyName')?.valid && form.get('companyName')?.touched
        "
      >
        {{
          "register.businessInformations.errorMessage.companyName" | translate
        }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label
      >{{ "register.businessInformations.companyAddress" | translate }} *</label
    >
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/address.png" alt="icone address" />
      </span>
      <input
        formControlName="companyAddress"
        class="form-control"
        type="text"
        [placeholder]="
          'register.businessInformations.placeholder.companyAddress' | translate
        "
        required="required"
        [ngClass]="{
          'is-invalid':
            !form.get('companyAddress')?.valid &&
            form.get('companyAddress')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('companyAddress')?.valid &&
          form.get('companyAddress')?.touched
        "
      >
        {{
          "register.businessInformations.errorMessage.companyAddress"
            | translate
        }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>{{ "register.businessInformations.activity" | translate }} *</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/activity.png" alt="icone activity name" />
      </span>
      <select
        class="form-select form-control digits"
        formControlName="companyActivity"
        [ngClass]="{
          'is-invalid':
            !form.get('companyActivity')?.valid &&
            form.get('companyActivity')?.touched
        }"
      >
        <option selected="" disabled="" [value]="null">
          {{
            "register.businessInformations.placeholder.companyActivity"
              | translate
          }}
        </option>
        <option
          class="form-select"
          *ngFor="let companyActivity of CompanyActivity"
          [value]="companyActivity.hashed_id"
        >
          {{ companyActivity.title }}
        </option>
        <div
          class="invalid-feedback"
          *ngIf="
            !form.get('companyActivity')?.valid &&
            form.get('companyActivity')?.touched
          "
        >
          {{
            "register.businessInformations.errorMessage.companyActivity"
              | translate
          }}
        </div>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label
      >{{ "register.businessInformations.companyRole" | translate }} *</label
    >
    <div class="input-group">
      <span class="input-group-text">
        <i class="icofont icofont-user-alt-3"></i>
      </span>
      <input
        formControlName="companyRole"
        class="form-control"
        type="text"
        [placeholder]="
          'register.businessInformations.placeholder.companyRole' | translate
        "
        required="required"
        [ngClass]="{
          'is-invalid':
            !form.get('companyRole')?.valid && form.get('companyRole')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('companyRole')?.valid &&
          form.get('companyRole')?.touched
        "
      >
        {{
          "register.businessInformations.errorMessage.companyRole"
            | translate
        }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>{{ "register.businessInformations.webSite" | translate }} *</label>
    <div class="input-group">
      <span class="input-group-text">
        <a placement="bottom" triggers="manual">
          <img src="assets/identity/website.png" alt="icone website"
        /></a>
      </span>
      <input
        formControlName="website"
        class="form-control"
        type="text"
        [placeholder]="
          'register.businessInformations.placeholder.webSite' | translate
        "
        [ngClass]="{
          'is-invalid':
            !form.get('website')?.valid && form.get('website')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('website')?.valid && form.get('website')?.touched"
      >
        <ul style="padding-left: 10px">
          <li>
            {{
              "register.businessInformations.errorMessage.webSite" | translate
            }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="checkbox ms-1">
      <input
        #checked
        id="checkbox1"
        type="checkbox"
        formControlName="acceptTerms"
      />
      <label for="checkbox1">
        {{ "register.termsAndConditions.part1" | translate }}
        <a
          class="color-primary underline-link"
          target="_blank"
          [href]="termsOfUse"
          >{{ "register.termsAndConditions.part2" | translate }}</a
        >
        {{ "register.termsAndConditions.part3" | translate
        }}<a
          class="color-primary underline-link"
          target="_blank"
          href="https://relead.tn/politique-de-confidentialite/"
        >
          {{ "register.termsAndConditions.part4" | translate }}.</a
        >
      </label>
    </div>
  </div>
</form>
