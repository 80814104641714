import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { AdDetailsInterface } from "../../models/advertising-campaigns/ad-details.interface";
import { AppStateInterface } from "../../../store/appState.interface";
import { CalculateCost } from "../../models/calcul/calculateCost";
import { invokeCalculateTotalLPCostByCampanyNames } from "../../../store/locationPartner/location-partner.action";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "../../models/calcul/totalCost";
import { LocationAvailabilityEnum } from "../../enum/location-availability";

@Injectable({
  providedIn: "root",
})
export class NewCampaignCalculatorService {
  constructor(private store: Store<AppStateInterface>) {}

  onSelectCalculateCost(adDetails: AdDetailsInterface, locations: string[]) {
    if (
      adDetails.endDate != null &&
      adDetails.objectiveName != null &&
      adDetails.startDate != null
    ) {
      this.store.dispatch(
        invokeCalculateTotalLPCostByCampanyNames({
          calculateCost: {
            calculationMethod: adDetails.calculationMethod,
            objectiveName: adDetails.objectiveName,
            startDate: adDetails.startDate,
            endDate: adDetails.endDate,
            names: locations,
          },
        })
      );
    }
  }

  //calculating costs
  calculateCostPerDay(locations: TotalCostWithDiscount): number {
    var costPerDay: number = 0;
    locations.totalCostPerDay.forEach((e) => {
      if (e.costPerDay != "NaN" && e.marginPerDay != "NaN")
        if (
          e.availabilities.availabilityStatus !=
          LocationAvailabilityEnum.NOT_AVAILABLE
        ) {
          costPerDay += e.costPerDay + e.marginPerDay * locations.discount;
        }
    });
    return costPerDay;
  }
  calculateTotalCost(locations: TotalCostWithDiscount, days: number): number {
    var costPerDay: number = 0;
    locations.totalCostPerDay.forEach((e) => {
      if (e.costPerDay != "NaN" && e.marginPerDay != "NaN")
        switch (e.availabilities.availabilityStatus) {
          case LocationAvailabilityEnum.AVAILABLE:
            costPerDay +=
              (e.costPerDay + e.marginPerDay * locations.discount) * days;
            break;

          case LocationAvailabilityEnum.PARTIALLY:
            costPerDay +=
              (e.costPerDay + e.marginPerDay * locations.discount) *
              (days - e.availabilities.noneAvailableDates.length);
            break;

          default : break;
        }
    });
    return costPerDay;
  }

  //Calculating views
  calculateImpressionsPerDay(locations: TotalCostWithDiscount): number {
    var impressionsPerDay: number = 0;
    locations.totalCostPerDay.forEach((e) => {
      if (e.costPerDay != "NaN" && e.marginPerDay != "NaN")
        if (
          e.availabilities.availabilityStatus !=
          LocationAvailabilityEnum.NOT_AVAILABLE
        ) {
          impressionsPerDay += e.viewsPerDay;
        }
    });
    return impressionsPerDay;
  }

  calculateTotalImpressions(
    locations: TotalCostWithDiscount,
    days: number
  ): number {
    var impressionsPerDay: number = 0;
    locations.totalCostPerDay.forEach((e) => {
      if (e.costPerDay != "NaN" && e.marginPerDay != "NaN")
        switch (e.availabilities.availabilityStatus) {
          case LocationAvailabilityEnum.AVAILABLE:
            impressionsPerDay += e.viewsPerDay * days;
            break;

          case LocationAvailabilityEnum.PARTIALLY:
            impressionsPerDay +=
              e.viewsPerDay *
              (days - e.availabilities.noneAvailableDates.length);
            break;

          case LocationAvailabilityEnum.NOT_AVAILABLE:
            break;
        }
    });
    return impressionsPerDay;
  }

  calculateMarginPerDay(locations: TotalCostWithDiscount): number {
    var marginPerDay: number = 0;
    locations.totalCostPerDay.forEach((e) => {
      if (e.costPerDay != "NaN" && e.marginPerDay != "NaN")
        if (
          e.availabilities.availabilityStatus !=
          LocationAvailabilityEnum.NOT_AVAILABLE
        ) {
          marginPerDay += e.marginPerDay;
        }
    });
    return marginPerDay;
  }
  calculateAverageUniqueViewsPerDay(locations: TotalCostWithDiscount): number {
    var uniqueViewsPerDay: number = 0;
    locations.totalCostPerDay.forEach((e) => {
      if (e.costPerDay != "NaN" && e.marginPerDay != "NaN")
        if (
          e.availabilities.availabilityStatus !=
          LocationAvailabilityEnum.NOT_AVAILABLE
        ) {
          uniqueViewsPerDay += e.uniqueViewsPerDay;
        }
    });
    return uniqueViewsPerDay;
  }
  calculateDays(startDate : Date , endDate : Date): number {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    } else return 0;
  }
}
