import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  advertisingCampaignSelector,
  deleteAdvertisingCampaignSelector,
  loadingAdvertisingCampaignTableSelector,
  successUploadFileSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeAdvertisingCompaignByHashedId,
  invokeLatestAdvertisingCampaigns,
  resultUploadJustificationFile,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { setAdvertisingCampaign } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { OptionActionInterface } from "src/app/shared/models/option-action.interface";
import { TranslateService } from "@ngx-translate/core";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { deleteAdCampaign } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ToastrService } from "ngx-toastr";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import {
  ActionInterface,
  ActionReturned,
  advertiserCampaignAwaitingApprovalActions,
  advertiserCampaignAwaitingPaymentActions,
  advertiserCampaignCanceledActions,
  advertiserCampaignDraftActions,
  advertiserCampaignLastActions,
  OptionActionEnum,
} from "src/app/shared/data/actions-data";
import { DeleteComponent } from "src/app/shared/components/drob-zone-options/modal/delete/delete.component";
import { Data, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  invokeDownloadFile,
  resultDownloadFile,
} from "src/app/store/file/file.actions";
import { UploadFilesComponent } from "src/app/shared/components/upload-files/upload-files.component";
import { invokeDownloadEstimate, resultSynthesisEstimate } from "src/app/store/commande/commande.actions";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { environment } from "src/environments/environment";
import {
  resultDownloadEstimateSelector,
  resultSynthesisEstimateSelector,
} from "src/app/store/commande/commande.selectors";
import { downloadedFileSelector } from "src/app/store/file/file.selectors";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
import { CancelEstimate } from "src/app/shared/services/iberis/cancelEstimate";

@Component({
  selector: "app-table-latest-campaign",
  templateUrl: "./table-latest-campaign.component.html",
})
export class TableLatestCampaignComponent
  extends BaseComponent
  implements OnInit
{
  //@Input() isLoading: boolean | null;
  loading$: Observable<boolean | null>;
  @Output() isExist = new EventEmitter<boolean | null>();
  showOption: boolean[] = [false];
  status = CampaignStatusEnum;
  columns: IColumn[] = [
    {
      key: "nomCampagne",
      label: "campaignDetails.campaignName",
      sorter: true,
    },
    {
      key: "Impressions",
      label: "dashboard.vues",
      sorter: true,
    },
    {
      key: "location",
      label: "campaignDetails.location",
      sorter: false,
    },
    {
      key: "dateDébut",
      label: "campaignDetails.startDate",
      sorter: true,
    },
    {
      key: "dateFin",
      label: "campaignDetails.endDate",
      sorter: true,
    },
    {
      key: "Status",
      label: "dashboard.status",
      sorter: true,
    },
  ];
  advertisingCampaing$: Observable<AdvertisingCampaignInterface[] | null>;
  advertisingCampaing: AdvertisingCampaignInterface[] = [];
  options: OptionActionInterface = {
    icon: [],
    name: [],
    optionSelected: null,
    title: "",
  };
  latestAd: AdvertisingCampaignInterface[];
  optionSelected: string | null;
  path: string;
  count: number;
  deleteAd$: Observable<SuccessDto | null>;
  successDeleteAd: string;

  downloadFile$: Observable<any>;
  justificationFile: string | null;
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  successUploadJustificationFile$: Observable<SuccessDto | null>;

  resultSynthesisEstimate$: Observable<ResultSynthesisEstimate | null>;

  currentAd: AdvertisingCampaignInterface;
  currentAction: OptionActionEnum;

  deleteModal: NgbModalRef;
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private modalService: NgbModal,
    private extensionService: ExtensionService,
    private deleteEstimateService: CancelEstimate
  ) {
    super(store);
    this.advertisingCampaing$ = this.store
      .pipe(select(advertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.deleteAd$ = this.store
      .pipe(select(deleteAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignTableSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.downloadEstimate$ = this.store
      .pipe(select(resultDownloadEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.downloadFile$ = this.store
      .pipe(select(downloadedFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.successUploadJustificationFile$ = this.store
      .pipe(select(successUploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultSynthesisEstimate$ = this.store
      .pipe(select(resultSynthesisEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.loading$.subscribe((data) => {});
    this.store.dispatch(setAdvertisingCampaign({ advertisingCampaign: null }));
    this.deleteAd$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.successDeleteAd = result.message;
        this.store.dispatch(deleteAdCampaign({ successMessage: null }));
        if (this.successDeleteAd != "") {
          this.toastr.success(
            this.translate.instant("response.success." + this.successDeleteAd),
            this.translate.instant("response.successTitle")
          );
          this.store.dispatch(invokeLatestAdvertisingCampaigns());
        }
      }
    });
    this.advertisingCampaing$.subscribe(
      (result: AdvertisingCampaignInterface[] | null) => {
        if (result != null) {
          this.latestAd = result;
          this.count = result.length;

          if (result.length > 0) {
            this.isExist.emit(true);
          } else {
            this.isExist.emit(false);
          }
        } else {
          this.store.dispatch(invokeLatestAdvertisingCampaigns());
        }
      }
    );

    this.downloadFile$.subscribe((result) => {
      if (result) {
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${
          this.justificationFile
        }.${this.extensionService.getExtension(result.url)}`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
        this.justificationFile = null;
        this.store.dispatch(resultDownloadFile({ downloadedFile: null }));
      }
    });
    this.successUploadJustificationFile$.subscribe(
      (success: SuccessDto | null) => {
        if (success != null) {
          this.store.dispatch(
            resultUploadJustificationFile({ successUpload: null })
          );
          this.toastr.success(
            this.translate.instant("response.success." + success.message),
            this.translate.instant("response.successTitle")
          );
          this.store.dispatch(invokeLatestAdvertisingCampaigns());
        }
      }
    );

    this.resultSynthesisEstimate$.subscribe(
      (result: ResultSynthesisEstimate | null) => {
        if (result && result?.status.code == 200) {
          this.deleteEstimateService.cancelEstimate(
            result.data.estimate,
            this.currentAd,
            this.currentAction
          );
        } else if (result?.status.code == 404) {
          this.toastr.error(
            this.translate.instant("response.error.undefined"),
            this.translate.instant("response.errorTitle")
          );
          this.store.dispatch(resultSynthesisEstimate({resultSynthesisEstimate : null}))
          this.deleteModal.close()
        }
      }
    );
  }
  openOptions(j: number) {
    this.showOption[j] = !this.showOption[j];
  }
  public onOutsideClick(event: any, j: number): void {
    this.showOption[j] = false;
    this.cdr.detectChanges();
  }
  onSelect() {}
  saveAction(action: ActionReturned, ad: AdvertisingCampaignInterface) {
    this.currentAction = action.option.type;
    this.currentAd = ad;
    switch (action.option.type) {
      case OptionActionEnum.CAMPAIGN_DETAILS: {
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "insights",
          "statistics",
          ad.advertisingCampaignHashedId,
        ]);
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGN_PUBLISH: {
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "advertising-campaigns",
          "create-ad",
          "payment",
          ad.advertisingCampaignHashedId,
        ]);
        break;
      }
      case OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE: {
        const modal = this.modalService.open(UploadFilesComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.campaignHashedId =
          ad.advertisingCampaignHashedId;
        modal.componentInstance.modalTitle = action.option.name;
        modal.componentInstance.action =
          OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE;
        modal.componentInstance.savedFile = ad.justificationFile;
        modal.componentInstance.accept = "application/pdf, image/*";
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE: {
        this.justificationFile = ad.title;
        this.store.dispatch(
          invokeDownloadFile({ filename: ad.justificationFile })
        );
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE: {
        this.downloadEstimate(ad.title, ad.estimateId);
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_INVOICE: {
        this.router.navigate(["/", "dashboard", "advertiser", "payments"]);
        break;
      }
      case OptionActionEnum.CAMPAIGN_DELETE: {
        this.deleteModal = this.modalService.open(DeleteComponent, {
          centered: true,
        });
        this.deleteModal.componentInstance.name = "pop-up.advertisingCampaign.title";
        this.deleteModal.componentInstance.reference = ad.title;
        this.deleteModal.componentInstance.CampaignHashedId =
          ad.advertisingCampaignHashedId;
        this.deleteModal.componentInstance.estimateId = ad.estimateId;
        this.deleteModal.componentInstance.type = OptionActionEnum.CAMPAIGN_DELETE;
        this.deleteModal.componentInstance.title = action.option.name;
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGN_UPDATE: {
        this.store.dispatch(
          invokeAdvertisingCompaignByHashedId({
            campaignHashedId: ad.advertisingCampaignHashedId,
          })
        );
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "advertising-campaigns",
          "update-ad",
          ad.advertisingCampaignHashedId,
        ]);
      }
    }
  }

  downloadEstimate$: Observable<any>;
  blob: Blob;
  downloadEstimate(title: string, estimateId: string) {
    this.store.dispatch(
      invokeDownloadEstimate({ params: this.params, estimateId: estimateId })
    );
    this.downloadEstimate$.subscribe((result) => {
      if (result) {
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${title + "-devis"}.pdf`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
      }
    });
  }
  getOptions(advertising: AdvertisingCampaignInterface): ActionInterface[] {
    switch (advertising.status) {
      case CampaignStatusEnum.FINISHED:
      case CampaignStatusEnum.ONGOING:
      case CampaignStatusEnum.WAITING: {
        var actions: ActionInterface[] = advertiserCampaignLastActions;
        if (!advertising.justificationFile)
          actions = actions.filter(
            (value) =>
              value.type !=
              OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
          );

        return actions;
      }

      case CampaignStatusEnum.DRAFT:
        return advertiserCampaignDraftActions;

      case CampaignStatusEnum.AWAITING_PAYMENT:
        return advertiserCampaignAwaitingPaymentActions;
      case CampaignStatusEnum.AWAITING_APPROVAL: {
        var actions: ActionInterface[] =
          advertiserCampaignAwaitingApprovalActions;
        if (advertising.justificationFile == null) {
          actions = actions.filter(
            (value) =>
              value.type !=
              OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
          );
        }

        return actions;
      }
      case CampaignStatusEnum.CANCELED: {
        var actions: ActionInterface[] = advertiserCampaignCanceledActions;
        if (advertising.justificationFile == null) {
          actions = actions.filter(
            (value) =>
              value.type !=
              OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
          );
        }

        return actions;
      }
    }
  }
}
