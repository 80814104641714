<div class="d-flex justify-content-center align-items-center">
  <img src="assets/identity/get-started.png" alt="" />
</div>
<div
  *ngIf="
    paymentToken == undefined &&
    (justificationFile == '' || justificationFile == null)
  "
>
  <div class="d-flex justify-content-center align-items-center">
    <h3>
      {{ "newCampaign.payment.success.withoutJustification.part1" | translate }}
      <b class="font-primary">{{ adTitle }}</b>
      {{ "newCampaign.payment.success.withoutJustification.part2" | translate }}
    </h3>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <p>
      {{ "newCampaign.payment.success.withoutJustification.part3" | translate }}
    </p>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <button class="btn btn-primary" [routerLink]="['../../main']">
      {{ "newCampaign.payment.success.returnToDashboard" | translate }}
    </button>
  </div>
</div>
<div
  *ngIf="
    paymentToken != undefined ||
    (justificationFile != '' && justificationFile != null)
  "
>
  <div class="d-flex justify-content-center align-items-center">
    <h3>
      {{ "newCampaign.payment.success.part1" | translate }}
      <b class="font-primary">{{ adTitle }}</b>
      {{ "newCampaign.payment.success.part2" | translate }}
    </h3>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <p>
      {{ "newCampaign.payment.success.part3" | translate }}
    </p>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <button
      class="btn btn-primary"
      [routerLink]="['../../payments']"
      *ngIf="paymentToken != undefined; else toDashboard"
    >
      {{ "newCampaign.payment.success.consultInvoice" | translate }}
    </button>
    <ng-template #toDashboard>
      <button class="btn btn-primary" [routerLink]="['../../main']">
        {{ "newCampaign.payment.success.returnToDashboard" | translate }}
      </button>
    </ng-template>
  </div>
</div>
