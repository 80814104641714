import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";
import {
  CampaignStatusEnum,
  CampaignStatusEnumMapping,
  CampaignStatusInterface,
} from "src/app/shared/enum/campaign-status";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { FilterListAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { Data } from "src/app/shared/models/iberis/commande/data";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { updateAdCampaignDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { invokeChangeAdStatus } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeRejectEstimate,
  invokeSynthesisEstimate,
  invokeUpdateEstimate,
  resultUpdateEstimate,
} from "src/app/store/commande/commande.actions";
import { resultSynthesisEstimateSelector, resultUpdateEstimateSelector } from "src/app/store/commande/commande.selectors";
import {
  invokeCampaignStarts,
  invokeMarkAdAsFinished,
} from "src/app/store/mailing/mailing.actions";
import {
  catchForbiddenError,
  catchServerError,
} from "src/app/store/user/user.actions";
import {
  catchForbiddenErrorSelector,
  catchServerErrorSelector,
} from "src/app/store/user/user.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-update-campaign-status",
  templateUrl: "./update-campaign-status.component.html",
})
export class UpdateCampaignStatusComponent implements OnInit {
  @Input() detailsAd: AdvertisingCampaignInterface;
  form: FormGroup;
  advertisingCompaignFilter: FilterListAdvertisingCompaign = {
    ...initialState.filter,
  };
  campaignStatus: CampaignStatusInterface[];
  loading: boolean = false;
  updateStatus$: Observable<SuccessDto | null>;
  ngDestroyed$: Subject<void> = new Subject<void>();
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  catchServerError$: Observable<boolean | null>;
  catchForbiddenError$: Observable<boolean | null>;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();

  resultUpdateEstimate$: Observable<ResultCreateEstimate | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private saveHistory: SaveUserHistoryService,
    private datePipe: DatePipe
  ) {
    this.form = this.fb.group({
      status: [null],
    });
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerError$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.updateStatus$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultUpdateEstimate$ = this.store
      .pipe(select(resultUpdateEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
  ngOnInit(): void {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.closeModal();
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.catchServerError$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.form.patchValue({ status: this.detailsAd.status });
    this.campaignStatus = Object.keys(CampaignStatusEnum)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: CampaignStatusEnum[key as keyof typeof CampaignStatusEnum],
        value:
          CampaignStatusEnumMapping[key as keyof typeof CampaignStatusEnum],
      }));
    this.updateStatus$.subscribe((data) => {
      if (data) {
        this.loading = false;
        switch (this.form.get("status")?.value) {
          case CampaignStatusEnum.ONGOING: {
            this.store.dispatch(
              invokeCampaignStarts({ email: this.detailsAd.emailPublisher })
            );
            break;
          }
          case CampaignStatusEnum.FINISHED: {
            this.store.dispatch(
              invokeMarkAdAsFinished({ email: this.detailsAd.emailPublisher })
            );
          }
        }
      }
    });

    this.resultUpdateEstimate$.subscribe((result)=>{
      if(result) {
        this.invokeUpdateStatus(CampaignStatusEnum.CANCELED);
        this.store.dispatch(resultUpdateEstimate({resultUpdateEstimate : null}))
      }
    })
  }

  updateStatus() {
    this.confirmAction.emit(true);
    this.loading = true;
    const status = this.form.get("status")?.value;
    if (status != CampaignStatusEnum.CANCELED) {
      this.invokeUpdateStatus(status)
    } else {
      this.store.dispatch(
        invokeSynthesisEstimate({
          params: this.params,
          estimateId: this.detailsAd.estimateId,
        })
      );
    }
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.PATCH,
      UserHistoryServicesEnum.ADMIN_CAMPAIGN_UPDATE_STATUS
    );
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  invokeUpdateStatus(status : string){
    this.store.dispatch(
      invokeChangeAdStatus({
        campaignHashedId: this.detailsAd.advertisingCampaignHashedId,
        status: status,
      })
    );
  }

  cancelEstimate(estimateDetails: Data) {
    const estimate: createEstimate = {
      bank_id: environment.IBERIS.BANK_ID,
      choice: 5,
      estimate_number: estimateDetails.estimate_number
        .replace("DV-", "")
        .split("-").pop() || "",
      client_id: estimateDetails.contact.hashed_id,
      additional_contact_id: estimateDetails.hashed_contact_additional_id,
      conditions: estimateDetails.conditions,
      currency_rate: estimateDetails.currency_rate,
      date: this.datePipe.transform(estimateDetails.date, "yyyy-MM-dd"),
      due: this.datePipe.transform(estimateDetails.due, "yyyy-MM-dd"),
      language: estimateDetails.language,
      notes: estimateDetails.notes + "Ce devis a été automatiquement rejeté car l'annonce qui lui était associée a été supprimée",
      object: estimateDetails.object,
      reference: estimateDetails.reference_number,
      use_conditions: 0,
      show_stamp: 1,
      show_billing: 1,
      show_delivery: 0,
      show_bank: 1,
      show_conditions: 1,
      show_description: 1,
      show_pictures: 1,
      tax_type: 1,
      show_unity: 1,
      useInput: {
        input_hashed_id: environment.IBERIS.TIMBRE_FISCAL_HASHED_ID,
        value: 1,
      },
      discount: "",
      items: {
        description: estimateDetails.items[0].description,
        discountAmount: Number(estimateDetails.items[0].discount_rate),
        discountType: estimateDetails.items[0].discount_type,
        item: estimateDetails.items[0].item,
        price: Number(estimateDetails.items[0].rate),
        qte: estimateDetails.items[0].quantity,
        taxes: [{ tax_hashed_id: environment.IBERIS.TAX_HASHED_ID }],
      },
    };
    this.store.dispatch(
      invokeUpdateEstimate({
        params: this.params,
        estimateId: this.detailsAd.estimateId,
        createEstimate: estimate,
      })
    );
  }
}
