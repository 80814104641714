<div class="media profile-media">
  <i
    [ngClass]="'flag-icon flag-icon-' + actualLang.icon"
    [ngStyle]="isAuth ? { 'font-size': 'large' } : {}"
  >
  </i>
</div>
<ul class="profile-dropdown onhover-show-div dropdown-lang">
  <li
    *ngFor="let lang of languages"
    (click)="switchLang(lang)"
    class="dropdown-lang-options"
  >
    <a
      ><i class="flag-icon flag-icon-{{ lang.icon }}"></i
      ><span> {{ lang.title | translate }} </span></a
    >
  </li>
</ul>
