<div class="row">
  <div class="col-12">
    <div class="media">
      <div class="userpro-box p-0">
        <div class="img-wrraper">
          <div class="avatar" style="width: 200%; height: 200%">
            <img
              class="img-thumbnail rounded-circle me-3"
              *ngIf="uploadFile == null"
              alt=""
              [src]="previewImage"
            />
            <img
              *ngIf="uploadFile != null"
              class="img-thumbnail rounded-circle me-3"
              alt=""
              [src]="uploadFile"
            />
          </div>
          <label for="image-upload" class="icon-wrapper">
            <i class="icofont icofont-pencil-alt-5"></i>
          </label>
          <input
            id="image-upload"
            hidden
            (click)="showPictureUpdate()"
          />
        </div>
      </div>
      <div class="media-body align-self-center p-l-30">
        <a *ngIf="overview.firstName && overview.lastName">
          <h1 class="f-w-600">
            {{ overview.firstName.toUpperCase() }}
            {{ overview.lastName.toUpperCase() }}
          </h1></a
        >
        <h5 class="f-w-300" *ngIf="overview.companyName">
          {{ overview.companyRole }} {{"at" | translate}} {{ overview.companyName }}
        </h5>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-7 m-t-30 m-l-30 m-r-30">
    <div>
      <h4 class="f-w-600 m-b-30">
        {{ "profile.profileDetails.title" | translate }}
      </h4>
      <div *ngIf="overview" class="row">
        <div class="col-6">
          <ng-container *ngFor="let column of columns">
            <p class="f-w-600 m-b-10">{{ column.label | translate }} <br /></p>
          </ng-container>
          <div *ngIf="role == roleEnum.ROLE_ADVERTISER">
            <ng-container *ngFor="let column of columnsAdvertiser">
              <p class="f-w-600 m-b-10">{{ column.label | translate }} <br /></p>
            </ng-container>
          </div>
          <div *ngIf="role == roleEnum.ROLE_LOCATION">
            <p class="f-w-600 m-b-10">{{ "profile.profileDetails.premiumEndDate" | translate }} <br /></p>
          </div>
        </div>
        <div class="col-6">
          <p class="m-b-10">
            {{ overview.companyName ?? ("undefined" | translate) }}
          </p>
          <p class="m-b-10" *ngIf="overview.firstName || overview.lastName ; else none">{{ overview.firstName }} {{ overview.lastName }}</p>
          <ng-template #none>
            <p class="m-b-10">
              {{ "undefined" | translate }}
            </p>
          </ng-template>
          <p class="m-b-10">
            {{ overview.companyActivity ?? ("undefined" | translate) }}
          </p>
          <p class="m-b-10">
            {{ overview.companyAddress ?? ("undefined" | translate) }}
          </p>
          <p class="m-b-10">
            {{ overview.phone ?? ("undefined" | translate) }}
          </p>
          <p class="m-b-10">
            {{ overview.email ?? ("undefined" | translate) }}
          </p>
          <p class="m-b-10">
            {{ overview.companyRole ?? ("undefined" | translate) }}
          </p>
          <div *ngIf="role == roleEnum.ROLE_ADVERTISER">
            <p class="m-b-10">
              {{ (overview.birthday | localizedDate) ?? "--/-/---" }}
            </p>
            <p class="m-b-10" *ngIf="overview.website!=''">
              {{ overview.website ?? ("undefined" | translate) }}
            </p>
            <p class="m-b-10" *ngIf="overview.website==''">
              {{ "undefined" | translate }}
            </p>
          </div>
          <div *ngIf="role == roleEnum.ROLE_LOCATION">
            <p class="m-b-10">
              {{ (newOverview.premiumEndDate | localizedDate) ?? "--/-/---" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-4">
    <div class="widget-joins widget-arrow">
      <div class="widget-card rounded">
        <h4 class="f-w-300 m-b-20">
          {{ "profile.profilePower.title" | translate }} :
          <b>{{ profilePower | translate }}</b>
        </h4>
        <div class="progress-showcase m-10">
          <div class="progress sm-progress-bar mt-2">
            <div
              class="progress-bar custom-progress-width bg-primary"
              [ngStyle]="{ width: totalPercentage + '%' }"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <ul
          class="list-group p-10"
          *ngFor="let item of profileMissingFields"
        >
          <p
            class="list-group-item list-group p-20"
            style="background-color: #f8f8f8"
          >
            {{ "profile.profilePower.enterField" | translate }}
            {{ "profile.profileDetailsForLowarCase." + item | translate
            }}{{ "profile.profilePower.completeProfile" | translate }}.
            <a type="button" class="color-primary">
              {{ "profile.profilePower.linkCompleteProfile" | translate }}
            </a>
          </p>
        </ul>
        <ul class="list-group p-10" *ngFor="let item of billingMissingFields">
          <p
            class="list-group-item list-group p-20"
            style="background-color: #f8f8f8"
          >
            {{ "profile.profilePower.enterField" | translate }}
            {{ "profile.billingInformationForLowerCase." + item | translate
            }}{{ "profile.profilePower.completeProfile" | translate }}.
            <a type="button" class="color-primary">
              {{ "profile.profilePower.linkCompleteProfile" | translate }}
            </a>
          </p>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="!admin" class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8 col-xxl-6 m-l-30 m-t-20">
    <div class="m-t-20">
      <h4 class="f-w-600 m-b-20">
        {{ "profile.billingInformation.title" | translate }}
      </h4>
      <app-billing-info></app-billing-info>
    </div>
  </div>
</div>
