export enum Authorities {
  AD_READ = "AD-READ",
  AD_UPDATE = "AD-UPDATE",
  AD_GETLIST = "AD-GETLIST",
  FC_CREATE = "FC-CREATE",
  FC_GETALL = "FC-GETALL",
  GA_GETALL = "GA-GETALL",
  AD_GETALL = "AD-GETALL",
  LP_GETALL = "LP-GETALL",
  ROUTER_GETALL = "ROUTER-GETALL",
  ADC_GETALL = "ADC-GETALL",
  LP_CREATE = "LP-CREATE",
  AD_CREATE = "AD_CREATE",
  AD_DELETE = "AD-DELETE"
}
