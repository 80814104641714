<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="logo-relead"
              />
            </div>
          </div>
          <form
            class="theme-form login-form"
            novalidate
            [formGroup]="form"
            (ngSubmit)="login($event)"
          >
            <div class="d-flex justify-content-end px-2">
              <ul class="nav-menus">
                <li class="onhover-dropdown">
                  <app-language
                    [isAuth]="true"
                    [reloadScreen]="true"
                  ></app-language>
                </li>
              </ul>
            </div>
            <h4 class="greeting-auth">
              {{ "login.returnGreeting" | translate }}
            </h4>
            <h6 style="text-align: center; color: black">
              {{ "login.welcomeGreeting" | translate }}
            </h6>
            <div class="form-group">
              <label style="color: black"
                >{{ "authentication.email" | translate }} *</label
              >
              <div class="input-group">
                <span class="input-group-text">
                  <img src="assets/identity/email.png" alt="icone email" />
                </span>
                <input
                  formControlName="email"
                  class="form-control"
                  type="email"
                  required=""
                  [placeholder]="'authentication.placeholder.email' | translate"
                  [ngClass]="{
                    'is-invalid':
                      !form.get('email')?.valid && form.get('email')?.touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    !form.get('email')?.valid && form.get('email')?.touched
                  "
                >
                  {{ "authentication.errorMessage.emailInvalid" | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label style="color: black"
                >{{ "authentication.password" | translate }} *</label
              >
              <div class="input-group">
                <span class="input-group-text">
                  <img
                    src="assets/identity/password.png"
                    alt="icone password"
                  />
                </span>
                <input
                  formControlName="password"
                  class="form-control"
                  type="password"
                  name="login[password]"
                  [ngClass]="{ 'is-invalid': submitted && password?.errors }"
                  [type]="show ? 'text' : 'password'"
                  required=""
                  [placeholder]="
                    'authentication.placeholder.password' | translate
                  "
                  [ngClass]="{
                    'is-invalid':
                      !form.get('password')?.valid &&
                      form.get('password')?.touched
                  }"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span> {{ "password.show" | translate }} </span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span>{{ "password.cash" | translate }}</span>
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    !form.get('password')?.valid &&
                    form.get('password')?.touched
                  "
                >
                  {{
                    "authentication.errorMessage.passwordInvalid" | translate
                  }}
                  <ul style="padding-left: 10px">
                    <li>- {{ "password.pattern.validator1" | translate }}</li>
                    <li>- {{ "password.pattern.validator2" | translate }}</li>
                    <li>- {{ "password.pattern.validator3" | translate }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-group d-flex justify-content-center">
              <div id="recaptcha-container"></div>
            </div>
            <div
              class="form-group d-flex justify-content-between align-items-center"
            >
              <div class="checkbox ms-1">
                <input id="checkbox1" checked type="checkbox" />
                <label
                  style="font-size: small; font-weight: 500"
                  for="checkbox1"
                >
                  {{ "login.keepSessionActive" | translate }}
                </label>
              </div>
              <a
                class="link color-primary"
                style="font-size: smaller"
                routerLink="../forget-password"
                >{{ "login.forgotPassword" | translate }}</a
              >
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary"
                type="submit"
                [disabled]="form.invalid || !isValidateCaptcha"
              >
                <loading-button
                  [loading]="loading$ | async"
                  [value]="'login.signInButton' | translate"
                  [icon]="null"
                ></loading-button>
              </button>
            </div>
            <hr />
            <p>
              {{ "login.noAccount" | translate
              }}<a class="ms-2 color-primary" routerLink="../register">{{
                "login.signUp" | translate
              }}</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
