import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { UpdateComponent } from "src/app/shared/components/drob-zone-options/modal/update/update.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { routerSuccessMessageSelector } from "src/app/store/router/router.selectors";
import { RouterType } from "src/app/shared/enum/routerType";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { OptionActionEnum } from "src/app/shared/data/actions-data";
import { TypeCards } from "src/app/shared/enum/typeCards";
import { RouterToUpdateInterface } from "src/app/shared/models/router/router-update.interface";
import { catchForbiddenErrorSelector } from "src/app/store/user/user.selectors";
import { catchForbiddenError } from "src/app/store/user/user.actions";
@Component({
  selector: "app-update-router",
  templateUrl: "./update-router.component.html",
})
export class UpdateRouterComponent extends BaseComponent implements OnInit {
  updateRouter$: Observable<SuccessDto | null>;
  updateRouter: string | undefined;
  loading$: Observable<boolean | null>;
  SECTION = OptionActionEnum;
  routerType = RouterType;

  form: FormGroup<any>;
  accessInformationForm: FormGroup<any>;
  routerSpecificationForm: FormGroup<any>;
  locationInformationForm: FormGroup<any>;
  releadForm: FormGroup<any>;
  fsiRouterForm: FormGroup<any>;

  routerToEdit: RouterToUpdateInterface;

  @Input() selectedOption: OptionActionEnum;

  catchForbiddenError$: Observable<boolean | null>;
  isLoading: boolean | null = false;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router
  ) {
    super(store);
    this.catchForbiddenError$ = this.store
      .pipe(select(catchForbiddenErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.updateRouter$ = this.store
      .pipe(select(routerSuccessMessageSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.accessInformationForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.minLength(8), Validators.maxLength(32)]],
    });

    this.releadForm = this.fb.group({
      FASIP: [null, [Validators.required]], //relead
      reverseTunnel: [null, [Validators.required]],
      sessionTimeOut: [null, [Validators.required]],
      reference: [null, [Validators.required]],
    });

    this.locationInformationForm = this.fb.group({
      dateOfProduction: [null, [Validators.required]],
      locationPartner: [null, [Validators.required]],
    });
    this.routerSpecificationForm = this.fb.group({
      MACAddress: [null, [Validators.required]],
      acquisitionPrice: [null, [Validators.required]],
      serialNumber: [null, [Validators.required]],
      SSID: [null, [Validators.required]],
      dateOfPurchase: [null, [Validators.required]],
      status: [null, [Validators.required]],
    });
    this.fsiRouterForm = this.fb.group({
      supplier: [null],
    });
  }

  ngOnInit(): void {
    this.catchForbiddenError$.subscribe((result) => {
      if (result) {
        this.modalService.dismissAll();
        this.isLoading = false;
        this.store.dispatch(catchForbiddenError({ forbiddenError: null }));
      }
    });
    this.accessInformationForm.patchValue({
      userName: this.routerToEdit.router.userName,
      password: this.routerToEdit.router.password,
    });
    this.routerSpecificationForm.patchValue({
      MACAddress: this.routerToEdit.router.mac,
      acquisitionPrice: this.routerToEdit.router.acquisitionPrice,
      status: this.routerToEdit.router.status,
      serialNumber: this.routerToEdit.router.serialNumber,
      SSID: this.routerToEdit.router.ssid,
      dateOfPurchase: this.routerToEdit.router.dateOfPurchase,
    });

    this.locationInformationForm.patchValue({
      dateOfProduction: this.routerToEdit.router.productionDate,
      locationPartner: this.routerToEdit.router.locationPartner,
    });

    this.releadForm.patchValue({
      FASIP: this.routerToEdit.router.ip,
      reverseTunnel: this.routerToEdit.router.reverseTunnel,
      sessionTimeOut: this.routerToEdit.router.sessionTimeOut,
      reference: this.routerToEdit.router.reference,
    });
    this.fsiRouterForm.patchValue({
      supplier: this.routerToEdit.router.internetProvider,
    });
  }
  saveChanges() {
    this.confirmAction.emit(true)
    this.routerToEdit = {
      ...this.routerToEdit,
      router: {
        userName: this.accessInformationForm.get("userName")?.value,
        password: this.accessInformationForm.get("password")?.value,
        mac: this.routerSpecificationForm.get("MACAddress")?.value,
        acquisitionPrice:
          this.routerSpecificationForm.get("acquisitionPrice")?.value,
        reverseTunnel: this.releadForm.get("reverseTunnel")?.value,
        serialNumber: this.routerSpecificationForm.get("serialNumber")?.value,
        ssid: this.routerSpecificationForm.get("SSID")?.value,
        sessionTimeOut: this.releadForm.get("sessionTimeOut")?.value,
        reference: this.releadForm.get("reference")?.value,
        dateOfPurchase:
          this.routerSpecificationForm.get("dateOfPurchase")?.value,

        locationPartner:
          this.locationInformationForm.get("locationPartner")?.value,
        productionDate:
          this.locationInformationForm.get("dateOfProduction")?.value,
        ip: this.releadForm.get("FASIP")?.value,
        status: this.routerSpecificationForm.get("status")?.value,
        internetProvider: this.fsiRouterForm.get("supplier")?.value,
        type: this.routerToEdit.router.type,
      },
    };
    this.onClickRetour();
  }
  onClear(): void {
    this.form.reset();
  }
  onClickRetour() {
    const modal = this.modalService.dismissAll(UpdateRouterComponent);
    const modalRef = this.modalService.open(UpdateComponent, {
      centered: true,
      size: "xl",
    });
    modalRef.componentInstance.type = TypeCards.ADMIN_ROUTER_UPDATE;
    modalRef.componentInstance.isCol4 = true;
    modalRef.componentInstance.title = "actions.updateRouter";
    modalRef.componentInstance.routerToEdit = this.routerToEdit;
  }
  onClickCloseModal() {
    const modal = this.modalService.dismissAll(UpdateRouterComponent);
  }

  isDisableButton() {
    switch (this.selectedOption) {
      case OptionActionEnum.ADMIN_ROUTER_UPDATE_ACCESS_INFORMATION: {
        return !this.accessInformationForm.valid;
      }
      case OptionActionEnum.ADMIN_ROUTER_UPDATE_LOCATION_INFORMATION: {
        return !this.locationInformationForm.valid;
      }
      case OptionActionEnum.ADMIN_ROUTER_UPDATE_ROUTER_SPECIFICATION: {
        if (this.routerToEdit.router.type == RouterType.RELEAD_ROUTER) {
          return !this.routerSpecificationForm.valid || !this.releadForm.valid;
        } else {
          return (
            !this.routerSpecificationForm.valid || !this.fsiRouterForm.valid
          );
        }
      }
      default:
        return;
    }
  }
}
