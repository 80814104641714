import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { CommonParamsIberis } from "../models/iberis/paramsForGetAll";
import { CreateSupplier } from "../models/iberis/supplier/createSupplier";
import { ResultCreateSupplier } from "../models/iberis/supplier/resultCreateSupplier";

@Injectable({
  providedIn: "root",
})
export class SupplierService {
  constructor(private http: HttpClient) {}

  createSupplier(
    params: CommonParamsIberis,
    createSupplier: CreateSupplier
  ): Observable<ResultCreateSupplier | null> {
    let param = new HttpParams();
    param = param.append("first_name", createSupplier.first_name);
    param = param.append("last_name", createSupplier.last_name);
    param = param.append("company", createSupplier.company);
    param = param.append("display_name", createSupplier.display_name);
    param = param.append("email", createSupplier.email);
    param = param.append("phone", createSupplier.phone);
    param = param.append("activity_id", createSupplier.activity_id);
    param = param.append("currency_id", createSupplier.currency_id);
    param = param.append("title", createSupplier.title);
    param = param.append("price_list_id", createSupplier.price_list_id)
    param = param.append("deadline_id", createSupplier.deadline_id);
    return this.http.post<ResultCreateSupplier>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/provider/new?${param}`,
      null
    );
  }
}
