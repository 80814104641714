<div class="card mt-2">
  <div class="card-header p-b-0">
    <div class="media-space-between">
      <h4 class="m-t-15">
        {{ "inseights.details.technicalData.title" | translate }}
      </h4>
      <div>
        <button
          style="margin-right: 20px;"
          *ngIf="cachedAt"
          class="btn badge badge-light text-dark"
          type="button"
          (click)="getData(searchResult,true)"
        >
          <i class="icofont icofont-refresh"></i>
          <span>
            {{ "cachedAt" | translate }}
            {{ cachedAt | localizedDate : "short" }}
          </span>
        </button>
        <app-export
          [buttonName]="'export'"
          [data]="technicalSpecifications"
          [title]="title"
        ></app-export>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-xxl-4 col-xl-6 col-md-6 col-sm-6" id="userByBrowser">
        <div class="card" id="inseights.details.technicalData.navigator">
          <div class="card-body">
            <div class="media-space-between">
              <h6 class="m-r-10 f-w-600 color-darkGray" style="margin-top: 2px">
                {{ "inseights.details.technicalData.navigator" | translate }}
              </h6>
            </div>
            <hr />
            <app-progress-bar
              [resultbrowserFamily]="resultbrowserFamily"
              [isLoading]="isLoading"
            ></app-progress-bar>
          </div>
        </div>
      </div>
      <div
        class="col-xxl-4 col-xl-6 col-md-6 col-sm-6"
        id="inseights.details.technicalData.deviceCategory"
      >
        <div class="card">
          <div class="card-body">
            <div class="media-space-between">
              <h6 class="m-r-10 f-w-600 color-darkGray" style="margin-top: 2px">
                {{
                  "inseights.details.technicalData.deviceCategory" | translate
                }}
              </h6>
            </div>
            <hr />
            <app-donut
              class="col-lg-4"
              [title]="donutChart.title"
              [labels]="donutChart.labels"
              [legend]="donutChart.legend"
              [resultDeviceType]="resultDeviceType"
              [chartName]="chartName"
              [loading]="isLoading"
            ></app-donut>
          </div>
        </div>
      </div>
      <div
        class="col-xxl-4 col-xl-12 col-md-12 col-sm-6"
        id="inseights.details.technicalData.operatingSystem"
      >
        <div class="card">
          <div class="card-body">
            <div class="media-space-between">
              <h6 class="m-r-10 f-w-600 color-darkGray" style="margin-top: 2px">
                {{
                  "inseights.details.technicalData.operatingSystem" | translate
                }}
              </h6>
            </div>
            <hr />
            <app-bar-chart
              [xaxisTitle]="columnChart.xaxisTitle"
              [yaxisTitle]="columnChart.yaxisTitle"
              [xaxisTitle]="columnChart.xaxisTitle"
              [resultOsFamily]="resultOsFamily"
              [isLoading]="isLoading"
            ></app-bar-chart>
          </div>
        </div>
      </div>
    </div>
    <form
      class="table table-striped table-border-vertical"
      id="inseights.details.technicalData.totalContribution"
    >
      <div class="d-flex row m-auto">
        <div class="col-xl-6 col-sm-12 col-md-6 p-0">
          <table class="col-12">
            <thead>
              <ng-container *ngFor="let column of formDeviceBrand">
                <th>{{ column.label | translate }}</th>
              </ng-container>
            </thead>
            <tbody *ngIf="!isLoading">
              <tr *ngFor="let item of deviceBrand; let i = index">
                <td>{{ item.toUpperCase() | translate }}</td>
                <td>{{ valueDeviceBrand[i] }}</td>
                <td>{{ percentageDeviceBrand[i] | number : "1.0-2" }} %</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="isLoading">
            <ng-container style="float: right" class="flex">
              <div class="loader-box">
                <div class="loader-34"></div>
              </div>
              <h6
                class="col align-self-center f-w-600"
                style="text-align: center"
              >
                {{ "fetching" | translate }}
              </h6>
            </ng-container>
          </div>
          <div *ngIf="forbidden" class="d-flex justify-content-center m-t-20">
            {{ "response.error.FORBIDDEN_RESPONSE" | translate }}
          </div>
          <div
            *ngIf="deviceBrand.length == 0 && !isLoading && !forbidden"
            class="d-flex justify-content-center m-t-20"
          >
            {{ "noData" | translate }}
          </div>
        </div>
        
        <table class="col-xl-6 col-sm-12 col-md-4">
          <thead>
            <th>
              {{
                "inseights.details.technicalData.totalContribution" | translate
              }}
            </th>
          </thead>
          <tbody>
            <tr>
              <div class="d-flex justify-content-center align-items-center">
                <app-pie
                  [title]="pieChart.title"
                  [labels]="pieChart.labels"
                  [legend]="pieChart.legend"
                  [resultDeviceBrand]="resultDeviceBrand"
                  [isLoading]="isLoading"
                ></app-pie>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</div>
