<div class="d-flex justify-content-center align-items-center">
  <img src="assets/identity/oops.png" width="200px" alt="" />
</div>
<div class="d-flex justify-content-center align-items-center m-t-30">
  <h3>
    {{ "newCampaign.payment.cancel.part1" | translate }}
    <b class="font-primary"></b>
    {{ "newCampaign.payment.cancel.part2" | translate }}
  </h3>
</div>
<div class="d-flex justify-content-center align-items-center">
  <p>
    {{ "newCampaign.payment.cancel.part3" | translate }}
  </p>
</div>
<div class="d-flex justify-content-center align-items-center">
  <button class="btn btn-primary" [routerLink]="['../../main']">
    {{ "newCampaign.payment.success.returnToDashboard" | translate }}
  </button>
</div>
