import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";
import { OptionActionEnum } from "src/app/shared/data/actions-data";
import { bugReportStatus } from "src/app/shared/enum/bugReport-status";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { invokeDeleteCollaborator } from "src/app/store/admin/admin.actions";
import { approveAdvertiserByEmail } from "src/app/store/advertiser/advertiser.actions";
import { loadingApproveAdvertiserSelector } from "src/app/store/advertiser/advertiser.selectors";
import { invokeDeleteAdCampaign } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";

import {
  invokeAllCategories,
  invokeDeleteCategory,
} from "src/app/store/category/category.actions";
import {
  invokeSynthesisEstimate,
  invokeUpdateEstimate,
  resultUpdateEstimate,
} from "src/app/store/commande/commande.actions";
import { resultUpdateEstimateSelector } from "src/app/store/commande/commande.selectors";
import { invokeDeleteFixedCharge } from "src/app/store/fixed-charge/fixed-charge.actions";
import { invokeDeleteLocationPartner } from "src/app/store/locationPartner/location-partner.action";
import { invokeDeletePromotionCode } from "src/app/store/promotion-code/promotion-code.actions";
import { invokeDeleteRegion } from "src/app/store/region/region.actions";
import { invokeDeleteSubRole } from "src/app/store/role/role.actions";
import { invokeDeleteRouter } from "src/app/store/router/router.action";
import { invokeDeleteSubCategory } from "src/app/store/subCategory/subCategory.actions";
import { invokeDeleteTag } from "src/app/store/tag/tag.actions";
import { catchServerError } from "src/app/store/user/user.actions";
import { catchServerErrorSelector } from "src/app/store/user/user.selectors";
import { invokeDeleteZone } from "src/app/store/zone/zone.actions";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-delete",
  templateUrl: "./delete.component.html",
})
export class DeleteComponent implements OnInit, OnDestroy {
  @Input() typeDelete: string;
  @Input() toApprove: string;
  @Input() mac: string;

  @Input() name: string;
  @Input() title: string;
  @Input() reference: string;
  @Input() CampaignHashedId: string;
  @Input() estimateId: string;
  @Input() status: bugReportStatus;
  @Input() type: OptionActionEnum;
  @Input() emailCollaborator: string;
  @Input() currentAd: AdvertisingCampaignInterface;
  @Input() roleName: string;

  loading$: Observable<boolean | null>;
  loading: boolean;
  catchServerErreur$: Observable<boolean | null>;
  ngDestroyed$: Subject<void> = new Subject<void>();

  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();

  resultUpdateEstimate$: Observable<ResultCreateEstimate | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private saveHistory: SaveUserHistoryService
  ) {
    this.loading$ = this.store
      .pipe(select(loadingApproveAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerErreur$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultUpdateEstimate$ = this.store
      .pipe(select(resultUpdateEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
  ngOnInit(): void {
    this.catchServerErreur$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.store.dispatch(invokeAllCategories());
        this.store.dispatch(approveAdvertiserByEmail({ successMessage: null }));
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.loading$.subscribe((data) => {
      if (data) {
        this.loading = data;
      }
    });

    this.resultUpdateEstimate$.subscribe((result) => {
      if (result) {
        this.store.dispatch(
          invokeDeleteAdCampaign({ campaignHashedId: this.CampaignHashedId })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_CAMPAIGN
        );
        this.store.dispatch(
          resultUpdateEstimate({ resultUpdateEstimate: null })
        );
        this.loading = false;
        this.modalService.dismissAll(DeleteComponent);
      }
    });
  }
  onClickDelete() {
    this.loading = true;
    this.confirmAction.emit(true);
    switch (this.type) {
      case OptionActionEnum.ADMIN_ROUTER_DELETE: {
        this.store.dispatch(invokeDeleteRouter({ MAC: this.reference }));
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_ROUTER
        );
        this.modalService.dismissAll(DeleteComponent);
        break;
      }
      case OptionActionEnum.ADMIN_LOCATION_DELETE: {
        this.store.dispatch(
          invokeDeleteLocationPartner({ email: this.reference })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_LOCATION
        );
        break;
      }
      case OptionActionEnum.ADMIN_COLLABORATOR_DELETE: {
        this.store.dispatch(
          invokeDeleteCollaborator({ email: this.emailCollaborator })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_COLLABORATOR
        );
        break;
      }
      case OptionActionEnum.ADMIN_REGION_DELETE: {
        this.store.dispatch(invokeDeleteRegion({ regionName: this.reference }));
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_REGION
        );
        break;
      }
      case OptionActionEnum.ADMIN_DELETE_ZONE: {
        this.store.dispatch(invokeDeleteZone({ zoneName: this.reference }));
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_ZONE
        );
        break;
      }
      case OptionActionEnum.ADMIN_CATEGORY_DELETE: {
        this.store.dispatch(
          invokeDeleteCategory({ categoryName: this.reference })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_CATEGORY
        );
        this.modalService.dismissAll(DeleteComponent);
        break;
      }
      case OptionActionEnum.ADMIN_TAG_DELETE: {
        this.store.dispatch(invokeDeleteTag({ tagName: this.reference }));
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_TAG
        );
        break;
      }
      case OptionActionEnum.ADMIN_SETTINGS_PROMOTION_DELETE: {
        this.store.dispatch(
          invokeDeletePromotionCode({ code: this.reference })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_PROMOTION_CODE
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DELETE: {
        this.store.dispatch(
          invokeSynthesisEstimate({
            params: this.params,
            estimateId: this.estimateId,
          })
        );
        break;
      }
      case OptionActionEnum.ADMIN_SUBCATEGORY_DELETE: {
        this.store.dispatch(
          invokeDeleteSubCategory({ subCategoryName: this.reference })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_SUB_CATEGORY
        );
        this.modalService.dismissAll(DeleteComponent);
        break;
      }
      case OptionActionEnum.ADMIN_SUBROLE_DELETE: {
        this.store.dispatch(
          invokeDeleteSubRole({
            roleName: this.roleName,
            subRoleName: this.reference,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_SUB_ROLE
        );
        break;
      }
      case OptionActionEnum.ADMIN_FIXED_CHARGES_DELETE: {
        this.store.dispatch(
          invokeDeleteFixedCharge({ fixedChargeName: this.reference })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.DELETE,
          UserHistoryServicesEnum.DELETE_FIXED_CHARGE
        );
        break;
      }
    }
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
