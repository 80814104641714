import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { createEstimate } from "../models/iberis/commande/createEstimate";
import { ResultCreateEstimate } from "../models/iberis/commande/resultCreateEstimate";
import { ResultListOrdersFromIberis } from "../models/iberis/commande/resultListOrdersFromIberis";
import { ResultSynthesisEstimate } from "../models/iberis/commande/resultSynthesisEstimate";
import { MarkEstimate } from "../models/iberis/commande/validEstimate";
import { CommonParamsIberis } from "../models/iberis/paramsForGetAll";
import { ResultInvoiceEstimate } from "../models/iberis/resultInvoiceEstimate";

@Injectable({
  providedIn: "root",
})
export class CommandeService {
  constructor(private http: HttpClient) {}

  filterListOrdersFromIberis(
    params: CommonParamsIberis,
    start: number, 
    length: number , 
    draw: number
  ): Observable<ResultListOrdersFromIberis> {
    let param = new HttpParams();
    param = param.append("start", start);
    param = param.append("length", length);
    param = param.append("draw", draw);
    return this.http.get<ResultListOrdersFromIberis>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimates?${param}`
    );
  }
  filterListOrdersFromIberisByStatus(
    params: CommonParamsIberis,
    status: number,
    start: number, length: number , draw: number
  ): Observable<ResultListOrdersFromIberis> {
    let param = new HttpParams();
    param = param.append("status", status);
    param = param.append("start", start);
    param = param.append("length", length);
    param = param.append("draw", draw);
    return this.http.get<ResultListOrdersFromIberis>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimates?${param}`
    );
  }
  downloadEstimate(params: CommonParamsIberis, estimateId: string) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/pdf",
        Accept: "application/pdf",
      }),
    };
    return this.http.get(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/${estimateId}/download`,
      { observe: "response", responseType: "blob" }
    );
  }
  filterListOrdersFromIberisForSpecificAdvertiser(
    params: CommonParamsIberis,
    contactHashedId: string, start: number, length: number , draw: number
  ): Observable<ResultListOrdersFromIberis> {
    let param = new HttpParams();
    param = param.append("contactHashedId", contactHashedId);
    param = param.append("start", start);
    param = param.append("length", length);
    param = param.append("draw", draw);
    return this.http.get<ResultListOrdersFromIberis>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimates?${param}`
    );
  }

  filterListOrdersFromIberisByStatusForSpecificAdvertiser(
    params: CommonParamsIberis,
    contactHashedId: string,
    status: number,
    start: number, length: number , draw: number
  ): Observable<ResultListOrdersFromIberis> {
    let param = new HttpParams();
    param = param.append("contactHashedId", contactHashedId);
    param = param.append("status", status);
    param = param.append("start", start);
    param = param.append("length", length);
    param = param.append("draw", draw);
    return this.http.get<ResultListOrdersFromIberis>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimates?${param}`
    );
  }
  createEstimate(
    params: CommonParamsIberis,
    createEstimate: createEstimate
  ): Observable<ResultCreateEstimate | null> {
    let param = new HttpParams();
    const itemsJson = JSON.stringify(createEstimate.items, null, 2);
    const useInput = JSON.stringify(createEstimate.useInput , null ,2)
    param = param.append("date", createEstimate.date);
    param = param.append("due", createEstimate.due);
    param = param.append("estimate_number", createEstimate.estimate_number);
    param = param.append("object", createEstimate.object);
    param = param.append("notes", createEstimate.notes);
    param = param.append("tax_type", createEstimate.tax_type);
    param = param.append("client_id", createEstimate.client_id);
    param = param.append("conditions", createEstimate.conditions);
    param = param.append("use_conditions", createEstimate.use_conditions);
    param = param.append("show_stamp", createEstimate.show_stamp);
    param = param.append("show_billing", createEstimate.show_billing);
    param = param.append("show_delivery", createEstimate.show_delivery);
    param = param.append("show_bank", createEstimate.show_bank);
    param = param.append("bank_id", createEstimate.bank_id);
    param = param.append("show_conditions", createEstimate.show_conditions);
    param = param.append("show_description", createEstimate.show_description);
    param = param.append("show_pictures", createEstimate.show_pictures);
    param = param.append("choice", createEstimate.choice);
    param = param.append("currency_rate", createEstimate.currency_rate);
    param = param.append("language", createEstimate.language);
    param = param.append("items[]", itemsJson);
    param = param.append("use_input[]", useInput)
    return this.http.post<ResultCreateEstimate>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/new?${param}`,
      null
    );
  }
  invoiceEstimate(
    params: CommonParamsIberis,
    estimateId: string | null
  ): Observable<ResultInvoiceEstimate> {
    return this.http.get<ResultInvoiceEstimate>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/${estimateId}/invoice
      `
    );
  }

  updateEstimate(
    params: CommonParamsIberis,
    createEstimate: createEstimate,
    estimateId : string
  ): Observable<ResultCreateEstimate | null> {
    let param = new HttpParams();
    const itemsJson = JSON.stringify(createEstimate.items, null, 2);
    const useInput = JSON.stringify(createEstimate.useInput , null ,2)
    param = param.append("date", createEstimate.date);
    param = param.append("due", createEstimate.due);
    param = param.append("estimate_number", createEstimate.estimate_number);
    param = param.append("object", createEstimate.object);
    param = param.append("notes", createEstimate.notes);
    param = param.append("tax_type", createEstimate.tax_type);
    param = param.append("client_id", createEstimate.client_id);
    param = param.append("use_conditions", createEstimate.use_conditions);
    param = param.append("show_stamp", createEstimate.show_stamp);
    param = param.append("show_billing", createEstimate.show_billing);
    param = param.append("show_delivery", createEstimate.show_delivery);
    param = param.append("show_bank", createEstimate.show_bank);
    param = param.append("bank_id", createEstimate.bank_id);
    param = param.append("show_conditions", createEstimate.show_conditions);
    param = param.append("show_description", createEstimate.show_description);
    param = param.append("show_pictures", createEstimate.show_pictures);
    param = param.append("choice", createEstimate.choice);
    param = param.append("currency_rate", createEstimate.currency_rate);
    param = param.append("language", createEstimate.language);
    param = param.append("items[]", itemsJson);
    param = param.append("conditions", createEstimate.conditions);
    param = param.append("use_input[]", useInput)
    return this.http.post<ResultCreateEstimate>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/${estimateId}/edit?${param}`,
      null
    );
  }

  markEstimate(
    params: CommonParamsIberis,
    estimateId : string
  ): Observable<MarkEstimate | null> {
    return this.http.get<MarkEstimate | null>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/${estimateId}/mark`
    );
  }

  synthesisEstimate(
    params: CommonParamsIberis,
    estimateId: string | null
  ): Observable<ResultSynthesisEstimate> {
    return this.http.get<ResultSynthesisEstimate>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/${estimateId}/synthesis
      `
    );
  }

  rejectEstimate(
    params: CommonParamsIberis,
    estimateId : string
  ): Observable<MarkEstimate | null> {
    return this.http.get<MarkEstimate | null>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/${estimateId}/reject`
    );
  }

  deleteEstimate(
    params: CommonParamsIberis,
    estimateId : string
  ): Observable<MarkEstimate | null> {
    return this.http.get<MarkEstimate | null>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/estimate/${estimateId}/delete`
    );
  }
}
