import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateView } from '@danielmoncada/angular-datetime-picker';
import { LocationPartnerStatus, LocationPartnerStatusEnumMapping, LocationPartnerStatusInterface } from 'src/app/shared/enum/locationPartnerStatus';
import {
  PartnerStatus,
  PartnerStatusToShow,
  PartnerStatusInterface,
} from "src/app/shared/enum/partner-status";
import { PaymentFrequencyInterface, PaymentFrequency, PaymentFrequencyEnumMapping } from 'src/app/shared/enum/payment-frequency';
import { PricingMethod, PricingMethodEnumMapping, PricingMethodInterface } from 'src/app/shared/enum/pricing-method';
@Component({
  selector: 'app-location-status',
  templateUrl: './location-status.component.html',
  styleUrls: ['./location-status.component.scss']
})
export class LocationStatusComponent implements OnInit {
  @Input("form") form: FormGroup;
  @Input("isUpdate") isUpdate : boolean
  partnerStatus: PartnerStatusInterface[];
  partnerStatusEnum = PartnerStatus
  paymentFrequency: PaymentFrequencyInterface[];
  pricingMethod : PricingMethodInterface[]
  date = [null, null];
  isInternerProvider: boolean
  public years = DateView.MULTI_YEARS;
  showingAdmin : boolean
  showingAdvertiser : boolean
  locationPartnerStatus: LocationPartnerStatusInterface[]
  constructor() { }

  ngOnInit(): void {
    console.log("form", this.form.value)
    this.showingAdmin = this.form.get("showingAdmin")?.value
    this.showingAdvertiser = this.form.get("showingAdvertiser")?.value
    this.partnerStatus = Object.keys(PartnerStatus)
    .filter((value) => isNaN(Number(value)) === true)
    .map((key: string | any) => ({
      id: PartnerStatus[key as keyof typeof PartnerStatus],
      value: PartnerStatusToShow[key as keyof typeof PartnerStatus],
    }));
    this.paymentFrequency = Object.keys(PaymentFrequency)
    .filter((value) => isNaN(Number(value)) === true)
    .map((key: string | any) => ({
      id: PaymentFrequency[key as keyof typeof PaymentFrequency],
      value: PaymentFrequencyEnumMapping[key as keyof typeof PaymentFrequency],
    }));
    this.pricingMethod = Object.keys(PricingMethod)
    .filter((value) => isNaN(Number(value)) === true)
    .map((key: string | any) => ({
      id: PricingMethod[key as keyof typeof PricingMethod],
      value: PricingMethodEnumMapping[key as keyof typeof PricingMethod],
    }));

    this.locationPartnerStatus = Object.keys(LocationPartnerStatus)
    .filter((value) => isNaN(Number(value)) === true)
    .map((key: string | any) => ({
      id: LocationPartnerStatus[key as keyof typeof LocationPartnerStatus],
      value: LocationPartnerStatusEnumMapping[key as keyof typeof LocationPartnerStatus],
    }));
  }

  get getPartnerStatus(){
    return this.form.get("partnerStatus")?.value
  }

}
