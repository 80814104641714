import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const advertisingCampaignState = (state: AppStateInterface) =>
  state.advertisingCampaignState;

export const advertisingCampaignSelector = createSelector(
  advertisingCampaignState,
  (state) => state.advertisingCampaign
);

export const loadingAdvertisingCampaignSelector = createSelector(
  advertisingCampaignState,
  (state) => state.loading
);

export const addCampaignSelector = createSelector(
  advertisingCampaignState,
  (state) => state.adCreated
);
export const adDetailsSelector = createSelector(
  advertisingCampaignState,
  (state) => state.adDetails
);
export const filterAdvertisingCompaignSelector = createSelector(
  advertisingCampaignState,
  (state) => state.filter
)
export const resultFilterAdvertisingCompaignSelector = createSelector(
  advertisingCampaignState,
  (state) => state.listAdvertisingCompaign
)
export const listAdvertisingCampaignsViewsSelector = createSelector(
  advertisingCampaignState,
  (state) => state.listAdvertisingCompaignViews
)

export const adCompaignByRoleAndStatusSelector = createSelector(
  advertisingCampaignState,
  (state) => state.advertisingCampaign
)
export const getAdCompaignByHashedIdSelector = createSelector(
  advertisingCampaignState,
  (state) => state.resultCampaignByHashedId
)
export const adCampaignSelector = createSelector(
  advertisingCampaignState,
  (state) => state.successMessage
)
export const bestAdvertisingCampaignSelector = createSelector(
  advertisingCampaignState,
  (state)=> state.bestAdCompaign
)
export const deleteAdvertisingCampaignSelector = createSelector(
  advertisingCampaignState,
  (state) => state.successMessage
)
export const updateAdCampaignDetailsSelector = createSelector(
  advertisingCampaignState,
  (state)=> state.successMessage
)
export const resultAdvertisingCampaignForLpSelector = createSelector(
  advertisingCampaignState,
  (state) => state.advertisingCampaign
)
export const resultAdsByStatusSelector = createSelector(
  advertisingCampaignState,
  (state) => state.advertisingCampaignDto
)
export const resultAdsByPublisherSelector = createSelector(
  advertisingCampaignState,
  (state) => state.advertisingCampaign
)
export const resultAllAdsByStatusAndPublisherSelector = createSelector(
  advertisingCampaignState,
  (state) => state.advertisingCampaignDto
)
export const resultAddSynthesisReportSelector = createSelector(
  advertisingCampaignState,
  (state) => state.successMessage
)
export const resultStatusesByListAdsSelector = createSelector(
  advertisingCampaignState,
  (state) => state.publisherAndStatusDetails
)
export const resultCheckAdvertisingCampaignExistByTitleSelector = createSelector(
  advertisingCampaignState,
  (state) => state.isExist
) 
export const loadingAdvertisingCampaignTableSelector = createSelector(
  advertisingCampaignState,
  (state) => state.loadingAds
)
export const successUploadFileSelector = createSelector(
  advertisingCampaignState,
  (state) => state.successUpload
)


export const advertiserAdvetisingCampaignsSelector = createSelector(
  advertisingCampaignState,
  (state) => state.advertiserAdvertisingCampaigns
)

export const UpdateTotalHtSelector = createSelector(
  advertisingCampaignState,
  (state) => state.successMessage
)