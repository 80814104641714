import { createReducer, on } from "@ngrx/store";
import { AdvertisingCampaignsStateInterface } from "./advertiserCampaignState.interface";
import {
  invokeAdDetails,
  deleteAdCampaign,
  advertisingCompaignResult,
  getAdvertisingCampaignByHashedId,
  invokeLatestAdvertisingCampaigns,
  invokeAdvertisingCompaignFilter,
  SetAddAdvertisingCampaign,
  setAdvertisingCampaign,
  upadateAdCampaignStatus,
  updateAdCampaignDetails,
  bestAdvertisingCampaign,
  invokeAdvertisingCompaignByHashedId,
  resultAdsByPublisher,
  resultAllAdsByStatusAndPublisher,
  resultAddSynthesisReport,
  resultSuccessAd,
  resultStatusesByListAds,
  invokeAllAdsByStatusAndPublisher,
  resultCheckAdvertisingCampaignExistByTitle,
  invokeBestAdvertisingCampaign,
  invokeAddAdvertisingCampaign,
  resultUploadJustificationFile,
  invokeUploadJustificationFile,
  resultAdvertiserAdvetisingCampaigns,
  invokeDeleteAdCampaign,
  UpdateTotalHt,
} from "./advertisingCampaign.actions";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { AdvertisingPaymentMethodEnum } from "src/app/shared/enum/paymentMethod";
import { RoleEnum } from "src/app/shared/models/user/role.interface";

export const initialState: Readonly<AdvertisingCampaignsStateInterface> = {
  bestAdCompaign: null,
  loading: false,
  loadingLatestAds: false,
  loadingAds: false,
  advertisingCampaign: null,
  addCampaign: null,
  adDetails: {
    calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
    title: "",
    locations: [],
    zones: [],
    startDate: null,
    endDate: null,
    totalCost: 0,
    totalCostPerDay: 0,
    totalImpressions: 0,
    totalClicks: 0,
    totalLead: 0,
    impressionsPerDay: 0,
    clicksPerDay: 0,
    leadPerDay: 0,
    objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
    validators: {
      conditions: false,
      dates: false,
      locations: false,
      title: false,
      zones: false,
    },
    paymentMethod: AdvertisingPaymentMethodEnum.NONE,
    estimateId: "",
    estimateNumber: "",
    promotion: null,
    campaignHashedID: "",
    isMuted: true,
  },
  listAdvertisingCompaign: null,
  listAdvertisingCompaignViews: [],
  filter: {
    endingDate: null,
    startingDate: null,
    isAscending: true,
    pager: {
      pageNumber: 1,
      pageSize: 10,
    },
    sortName: null,
    status: [],
    title: "",
    isPaid: null,
    email: null,
    objectiveName: [],
    roleName: null,
    locationsPartners: [],
  },
  adCompaign: null,
  successMessage: null,
  advertisingCampaignDto: null,
  publisherAndStatusDetails: null,
  filterCalendar: {
    title: "",
    status: [],
    roleName: RoleEnum.ROLE_ADVERTISER,
    startingDate: null,
    endingDate: null,
    email: [],
    locationsPartners: [],
  },
  isExist: null,
  successUpload: null,
  advertiserAdvertisingCampaigns: null,
  adCreated: null,
  resultCampaignByHashedId: null,
};
export const AdvertisingCampaignReducer = createReducer(
  initialState,
  on(setAdvertisingCampaign, (state, { advertisingCampaign }) => ({
    ...state,
    advertisingCampaign: advertisingCampaign,
    loadingAds: false,
  })),
  on(invokeLatestAdvertisingCampaigns, (state) => ({
    ...state,
    loadingAds: true,
  })),
  on(invokeAddAdvertisingCampaign, (state, { addCampaign }) => ({
    ...state,
    loading: true,
  })),
  on(SetAddAdvertisingCampaign, (state, { adCreated }) => ({
    ...state,
    loading: false,
    adCreated: adCreated,
  })),
  on(invokeAdDetails, (state, { invokeAdDetails }) => ({
    ...state,
    adDetails: invokeAdDetails,
  })),
  on(invokeAdvertisingCompaignFilter, (state, { filter }) => ({
    ...state,
    listAdvertisingCompaignViews: [],
    filter: filter,
    loadingAds: true,
  })),
  on(advertisingCompaignResult, (state, { listAdvertisingCompaign }) => ({
    ...state,
    listAdvertisingCompaign: listAdvertisingCompaign,
    loadingAds: false,
  })),

  on(
    getAdvertisingCampaignByHashedId,
    (state, { resultCampaignByHashedId }) => ({
      ...state,
      resultCampaignByHashedId: resultCampaignByHashedId,
      loading: false,
    })
  ),
  on(updateAdCampaignDetails, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(upadateAdCampaignStatus, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(invokeDeleteAdCampaign, (state, { campaignHashedId }) => ({
    ...state,
    loading: true,
  })),
  on(deleteAdCampaign, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
    loading: false,
  })),
  on(invokeBestAdvertisingCampaign, (state, { isRefreshCache }) => ({
    ...state,
    loading: true,
  })),
  on(bestAdvertisingCampaign, (state, { adCompaign }) => ({
    ...state,
    bestAdCompaign: adCompaign,
    loading: false,
  })),
  on(invokeAdvertisingCompaignByHashedId, (state) => ({
    ...state,
    loading: true,
  })),
  on(resultAdsByPublisher, (state, { advertisingCampaign }) => ({
    ...state,
    advertisingCampaign: advertisingCampaign,
    loading: false,
  })),
  on(invokeAllAdsByStatusAndPublisher, (state, { filterCalendar }) => ({
    ...state,
    filterCalendar: filterCalendar,
    loading: true,
  })),
  on(resultAllAdsByStatusAndPublisher, (state, { advertisingCampaignDto }) => ({
    ...state,
    advertisingCampaignDto: advertisingCampaignDto,
    loading: false,
  })),
  on(resultAddSynthesisReport, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
    loading: false,
  })),
  on(resultSuccessAd, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(resultStatusesByListAds, (state, { publisherAndStatusDetails }) => ({
    ...state,
    publisherAndStatusDetails: publisherAndStatusDetails,
  })),
  on(resultCheckAdvertisingCampaignExistByTitle, (state, { isExist }) => ({
    ...state,
    isExist: isExist,
  })),

  on(
    invokeUploadJustificationFile,
    (state, { campaignHashedId, justificationFile }) => ({
      ...state,
      loading: true,
    })
  ),
  on(resultUploadJustificationFile, (state, { successUpload }) => ({
    ...state,
    successUpload: successUpload,
    loading: false,
  })),
  on(
    resultAdvertiserAdvetisingCampaigns,
    (state, { advertisingCamapigns }) => ({
      ...state,
      advertiserAdvertisingCampaigns: advertisingCamapigns,
    })
  ),
  on(UpdateTotalHt, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  }))
);
